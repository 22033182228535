import styled from "styled-components"
import { palette } from "view/themes/white/palette"

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

export const Card = styled.div`
  min-width: 15%;
  margin-right: 1rem;
  padding: 1rem;
  background-color: ${palette.white};
  border-radius: 1rem;
`

export const Title = styled.div`
  color: ${palette.gray};
  margin-bottom: 1rem;
  font-size: 0.8rem;
`

export const Value = styled.div`
  text-align: center;
  font-size: 1.8rem;
`

export const Buttons = styled.div`
  button {
    margin-right: 1rem;
  }
`

export const NextBill = styled.div`
  color: ${palette.gray};
  margin-bottom: 2rem;
`

export const Name = styled.div``

export const Button = styled.div`
  flex-grow: 1;
  text-align: right;
`

export const Limits = styled.div`
  & > div {
    & > span {
      font-size: 1rem;
      color: ${palette.gray};
    }
  }
`
