import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

import { organizationSelectors } from "data/entities/organization/selectors"
import { entityName } from "data/entities/consts"

export const transactionFetchList = createAsyncThunk(
  `${entityName.transaction}/fetchList`,
  async (props, { getState, rejectWithValue, signal }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const source = apiService.getSource()
      signal.addEventListener("abort", source.cancel)

      const result = await apiService.get(
        `/transactions?orgId=${organization._id}`,
        {
          cancelToken: source.token,
        }
      )

      signal.removeEventListener("abort", source.cancel)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
