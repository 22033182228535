import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"
import {
  projectCreate,
  projectFetchList,
  projectFetchOne,
  projectRemove,
  projectUpdate,
} from "data/entities/project/thunks"
import { adapter } from "data/entities/project/selectors"
import {
  projectCurrentChanged,
  projectCurrentClear,
} from "data/entities/project/actions"
import { getCountKeys } from "data/entities/translation/thunks"
import { entityName } from "data/entities/consts"
import { languageCreate, languageRemove } from "data/entities/language/thunks"
import { switchOrganization } from "data/entities/organization/actions"

const initialState = adapter.getInitialState({
  loadingList: false,
  current: null,
  countKeys: {
    total: 0,
    untranslated: [],
  },
})

export const projectSlice = createSlice({
  name: entityName.project,
  initialState,
  reducers: {
    [projectCurrentClear]: (state) => {
      state.current = null
      state.countKeys = initialState.countKeys
    },
    projectListCleared: () => initialState,
  },
  extraReducers: {
    [switchOrganization]: (state) => {
      state.current = initialState.current
    },
    [projectFetchList.pending]: (state) => {
      state.loadingList = true
      // adapter.setAll(state, action)
    },
    [projectFetchList.fulfilled]: (state, action) => {
      adapter.setAll(state, action)
      state.loadingList = false
    },
    [projectFetchList.rejected]: (state, action) => {
      // adapter.setAll(state, action)
      state.loadingList = false
    },
    [projectFetchOne.fulfilled]: (state, action) => {
      state.current = action.payload.id
      adapter.updateOne(state, {
        id: state.current,
        changes: {
          countKeys: action.payload.stat,
        },
      })
    },
    [projectCreate.fulfilled]: (state, action) => {
      adapter.addOne(state, action)
    },
    [projectUpdate.fulfilled]: (state, action) => {
      const { id, ...rest } = action.payload
      adapter.updateOne(state, { id, changes: rest })
    },
    [projectRemove.fulfilled]: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      state.current = null
      state.countKeys = initialState.countKeys
    },
    [projectCurrentChanged]: (state, action) => {
      state.current = action.payload
    },
    [getCountKeys.fulfilled]: (state, action) => {
      state.countKeys = action.payload
    },

    [languageCreate.fulfilled]: (state, action) => {
      const { langs } = state.entities[state.current]
      adapter.updateOne(state, {
        id: state.current,
        changes: {
          langs: [...langs, action.payload.id],
        },
      })
    },
    [languageRemove.fulfilled]: (state, action) => {
      const { langs } = state.entities[state.current]
      adapter.updateOne(state, {
        id: state.current,
        changes: {
          langs: langs.filter((l) => l !== action.payload.id),
        },
      })
    },

    [getCountKeys.rejected]: (state, action) => {},
    [logout.fulfilled]: () => initialState,
  },
})
