import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"

import { fbApp } from "infrastructure/firebase"

import { validate } from "view/forms/identity/LoginForm/validate"

const initialValues = {}

export const Container = ({ component: Component, componentProps }) => {
  const handlerSubmit = useCallback(async (values) => {
    try {
      await fbApp
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
    } catch (e) {
      const errors = {}

      switch (e.code) {
        case "auth/account-exists-with-different-credential":
          errors.email =
            "The email address is already in use with different credential"
          break
        case "auth/user-not-found":
          errors.email = "This email isn't registered."
          break
        case "auth/email-already-in-use":
          errors.email =
            "The email address is already in use by another account"
          break
        case "auth/argument-error":
        case "auth/wrong-password":
          errors[FORM_ERROR] = "Email or password invalid"
          break
        default:
          errors[FORM_ERROR] = "Internal error"
      }

      return errors
    }
    return {}
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  )
}
