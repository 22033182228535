import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"

import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined"
import Slide from "@material-ui/core/Slide"
import Button from "@material-ui/core/Button"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { appHistory } from "infrastructure/application/history"

import { ModalEnum } from "data/entities/modal"
import { projectCurrentChanged } from "data/entities/project/actions"
import { projectFetchList } from "data/entities/project/thunks"
import { projectSelectors } from "data/entities/project/selectors"

import { useModal } from "view/modals/useModal"
import * as S from "./styled"

export const ProjectSelector = ({ openable }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const project = useSelector(projectSelectors.current)
  const projects = useSelector(projectSelectors.selectAll)

  const { open, anchorEl, onOpen, onClose } = openable

  const { onOpen: openProjectCreate } = useModal(ModalEnum.ProjectCreate)

  useEffect(() => {
    dispatch(projectFetchList())
  }, [])

  const changeProject = useCallback(
    (p) => {
      appHistory.push(location.pathname.replace(project.slug, p.slug))

      dispatch(projectCurrentChanged(p.id))
    },
    [location, project]
  )

  const disabled = !project?.id

  return (
    <>
      <S.Wrapper onClick={open ? onClose : onOpen} disabled={disabled}>
        <S.Title>{project?.name || "Select project"}</S.Title>
        {open ? (
          <KeyboardArrowUpOutlinedIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )}

        <S.Popover
          id="project-selector"
          open={open}
          anchorEl={anchorEl}
          onClose={onClose}
          elevation={2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Slide}
          TransitionProps={{}}
        >
          <S.ButtonWrapper>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={openProjectCreate}
              startIcon={<AddOutlinedIcon />}
            >
              New project
            </Button>
          </S.ButtonWrapper>

          {projects.map((p) => {
            return (
              <S.MenuItem
                key={p.id}
                onClick={() => changeProject(p)}
                selected={p.id === project?.id}
              >
                {p.name}
              </S.MenuItem>
            )
          })}
        </S.Popover>
      </S.Wrapper>
    </>
  )
}
