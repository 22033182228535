import styled from "styled-components"
import {
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
} from "@material-ui/core"
import { palette } from "view/themes/white/palette"

export const Input = styled(MuiInput)`
  background: #fff;

  svg {
    fill: ${palette.gray};
  }

  &:after,
  &:before,
  &:hover {
    border-bottom: none !important;
  }
`

export const InputAdornment = styled(MuiInputAdornment)`
  padding-left: 0.5rem;
`
