import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"
import { languageInit } from "data/entities/language/thunks"
import { languageSetFilter } from "data/entities/language/actions"
import { entityName } from "data/entities/consts"
import { switchOrganization } from "data/entities/organization/actions"
import { projectCurrentChanged } from "data/entities/project/actions"

const initialState = {
  init: false,
  filter: [],
}

export const languageSlice = createSlice({
  name: entityName.language,
  initialState,
  reducers: {
    languageListCleared: () => {
      return initialState
    },
  },
  extraReducers: {
    [languageSetFilter]: (state, action) => {
      if (JSON.stringify(state.filter) !== JSON.stringify(action.payload))
        state.filter = action.payload
    },
    [languageInit.fulfilled]: (state, action) => {
      state.init = true
      if (
        action.payload &&
        JSON.stringify(state.filter) !== JSON.stringify(action.payload)
      )
        state.filter = action.payload
    },
    [projectCurrentChanged]: (state) => {
      if (state.filter.length) state.filter = []
    },
    [switchOrganization]: (state) => {
      if (state.filter.length) state.filter = []
    },
    [logout.fulfilled]: () => initialState,
  },
})
