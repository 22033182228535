import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { matchPath, useLocation } from "react-router"

import Popover from "@material-ui/core/Popover"
import { Divider } from "@material-ui/core"
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined"
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined"

import { appHistory } from "infrastructure/application/history"

import { logout } from "data/entities/identity/thunks"
import { identitySelectors } from "data/entities/identity/selectors"
import { organizationSelectors } from "data/entities/organization/selectors"
import { switchOrganization } from "data/entities/organization/actions"

import { paths } from "view/router/paths"
import { useOpenable } from "view/hooks/application/useOpenable"
import { Avatar } from "view/uikit/common/Avatar"

import { usePermission } from "view/hooks/rbac/usePermission"
import * as S from "./styled"

export const UserInfo = () => {
  const dispatch = useDispatch()

  const location = useLocation()

  const identity = useSelector(identitySelectors.one)
  const role = useSelector(identitySelectors.role)
  const organization = useSelector(organizationSelectors.current)
  const organizationList = useSelector(organizationSelectors.selectAll)

  const permission = usePermission(role)

  const { anchorEl, open, onOpen, onClose } = useOpenable()
  const {
    anchorEl: orgAnchorEl,
    open: orgOpen,
    onOpen: orgOnOpen,
    onClose: orgOnClose,
  } = useOpenable()

  const userName = useMemo(() => {
    if (identity.name) return identity.name
    if (identity.email) return identity.email

    return "Anonymous"
  }, [identity])

  const handlerSwitchOrganization = useCallback(
    (e, org) => {
      dispatch(switchOrganization(org._id))
      orgOnClose()
      onClose()

      const projectItemPage = matchPath(location.pathname, {
        path: paths.projectItem(":id"),
        strict: false,
      })

      if (projectItemPage) appHistory.push(paths.projects())
    },
    [orgOnClose, onClose, location]
  )

  return (
    <>
      <S.Wrapper onClick={onOpen}>
        <S.TopProfile>
          <Avatar name={identity.name} email={identity.email} />

          <S.Name>
            <S.UserName>{userName}</S.UserName>
            <S.OrgName>{organization?.name}</S.OrgName>
          </S.Name>

          <S.IconButton>
            {open ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
          </S.IconButton>
        </S.TopProfile>
      </S.Wrapper>

      <Popover
        id="user-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <S.SubTitle>
          <Avatar name={organization?.name} variant="rounded" />
          <S.Text>{organization?.name}</S.Text>
        </S.SubTitle>

        <S.GroupLink>
          {permission.organizationSettings.canRead && (
            <S.MenuItem
              component={Link}
              to={paths.orgSettings(organization?._id)}
              onClick={onClose}
            >
              Organization settings
            </S.MenuItem>
          )}

          {permission.billing.canRead && (
            <S.MenuItem component={Link} to={paths.billing()} onClick={onClose}>
              Billing
            </S.MenuItem>
          )}

          <S.MenuItem component={Link} to={paths.users()} onClick={onClose}>
            Users
          </S.MenuItem>

          {organizationList.length > 1 && (
            <S.OrgSwitcher onClick={orgOnOpen}>
              <span>Switch organization</span>
              {orgOpen ? (
                <ChevronLeftOutlinedIcon />
              ) : (
                <ChevronRightOutlinedIcon />
              )}
            </S.OrgSwitcher>
          )}
        </S.GroupLink>

        <Divider light />

        <S.SubTitle>
          <Avatar name={identity.name} email={identity.email} />
          <S.Text>{userName}</S.Text>
        </S.SubTitle>

        <S.GroupLink>
          <S.MenuItem component={Link} to={paths.profile()} onClick={onClose}>
            Profile
          </S.MenuItem>
          <S.MenuItem
            component={React.forwardRef(({ children, ...props }, ref) => {
              return (
                <a {...props} ref={ref}>
                  {children}
                </a>
              )
            })}
            target="_blank"
            href="https://docs.localeum.com"
            onClick={onClose}
          >
            Docs & Guides
          </S.MenuItem>
          <S.MenuItem onClick={() => dispatch(logout())}>Logout</S.MenuItem>
        </S.GroupLink>
      </Popover>

      {organizationList.length > 1 && (
        <Popover
          id="organization-menu"
          open={orgOpen}
          anchorEl={orgAnchorEl}
          onClose={orgOnClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {organizationList.map((org) => (
            <S.MenuItem
              key={org._id}
              onClick={(e) => handlerSwitchOrganization(e, org)}
              $active={org._id === organization?._id}
            >
              {org.name}
            </S.MenuItem>
          ))}
        </Popover>
      )}
    </>
  )
}
