import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { transactionFetchList } from "data/entities/transaction/thunks"
import { transactionSelectors } from "data/entities/transaction/selectors"

import * as S from "view/pages/billing/BillingPage/TransactionList/styled"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"

export const TransactionList = React.memo(() => {
  const dispatch = useDispatch()

  const transactions = useSelector(transactionSelectors.selectAll)

  useEffect(() => {
    const promise = dispatch(transactionFetchList())

    return () => {
      promise.abort()
    }
  }, [])

  const openReceipt = useCallback((url) => {
    window.open(url, "_blank")
  }, [])

  return (
    <Table>
      <S.TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Subscription plan</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="right" />
        </TableRow>
      </S.TableHead>
      <TableBody>
        {transactions.map((t) => (
          <TableRow key={t._id}>
            <TableCell component="th" scope="row">
              {t._id}
            </TableCell>
            <TableCell>{t.planName}</TableCell>
            <TableCell align="right">
              {t.amount} {t.currency}
            </TableCell>
            <TableCell>{t.eventTime} UTC</TableCell>
            <TableCell align="right">
              <Button
                size="small"
                variant="outlined"
                onClick={() => openReceipt(t.receiptUrl)}
              >
                View receipt
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})
