import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"

import { transactionFetchList } from "data/entities/transaction/thunks"
import { adapter } from "data/entities/transaction/selectors"
import { entityName } from "data/entities/consts"
import { switchOrganization } from "data/entities/organization/actions"

const initialState = adapter.getInitialState()

export const transactionSlice = createSlice({
  name: entityName.transaction,
  initialState,
  reducers: {},
  extraReducers: {
    [transactionFetchList.fulfilled]: (state, action) => {
      adapter.setAll(state, action)
    },

    [switchOrganization]: () => initialState,
    [logout.fulfilled]: () => initialState,
  },
})
