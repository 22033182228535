import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Switch, useParams } from "react-router-dom"

import { TranslationRefetchContainer } from "domain/translation/TranslationRefetchContainer"

import { projectFetchOne } from "data/entities/project/thunks"

import { Route } from "view/router/Route"
import { paths } from "view/router/paths"
import { UploadPage } from "view/pages/project/UploadPage"
import { DownloadPage } from "view/pages/project/DownloadPage"
import { ProjectItemPage } from "view/pages/project/ProjectItemPage"
import { LanguagesPage } from "view/pages/project/LanguagesPage"
import { SettingsPage } from "view/pages/project/SettingsPage"
import { organizationSelectors } from "data/entities/organization/selectors"

export const ProjectRoute = () => {
  const { slug } = useParams()

  const dispatch = useDispatch()

  const organization = useSelector(organizationSelectors.current)

  useEffect(() => {
    dispatch(projectFetchOne(slug))
  }, [organization])

  return (
    <>
      <Switch>
        <Route
          isPrivate
          path={paths.languages(":slug")}
          component={LanguagesPage}
        />

        <Route
          isPrivate
          path={paths.settings(":slug")}
          component={SettingsPage}
        />

        <Route isPrivate path={paths.upload(":slug")} component={UploadPage} />

        <Route
          isPrivate
          path={paths.download(":slug")}
          component={DownloadPage}
        />

        <Route
          isPrivate
          path={paths.projectItem(":slug")}
          component={ProjectItemPage}
        />
      </Switch>
    </>
  )
}
