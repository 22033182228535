import styled from "styled-components"
import { IconButton } from "@material-ui/core"

import { whiteTheme } from "view/themes/white"

export const VerifyBtn = styled(IconButton)`
  display: none;
  position: absolute;
  top: 0.1rem;
  right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;

  svg {
    fill: ${whiteTheme.palette.success.main};
    width: 1.2rem;
    height: 1.2rem;
  }
`

export const ViewMode = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;

  border-radius: 0.5rem;
  padding: 0.5rem;
  line-height: 1.4rem;
  min-height: 2rem;
  font-size: 0.9rem;
  background: #f9f9f9;
  cursor: text;

  &:hover {
    background: #f7f7fb;

    ${VerifyBtn} {
      display: block;
    }
  }

  mark-search {
    background-color: #7b69ee;
    color: white;
    font-style: normal;
  }

  mark-var {
    color: #ff8f00;
    font-style: normal;
  }
`

export const EditMode = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`

export const Input = styled.div`
  & > div > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 0;
  }

  textarea {
    font-size: 0.9rem;
    padding: 0.5rem;
    line-height: 1.4rem;
    height: auto;
    background: #f7f7fb;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
  }
`

export const Buttons = styled.div`
  position: absolute;
  bottom: -1.7rem;
  padding: 0.1rem 0.3rem;
  background: #f7f7fb;
  border-radius: 0 0 0.5rem 0.5rem;
  z-index: 10;
`

export const SaveBtn = styled(IconButton)`
  padding: 0;
  margin-right: 0.5rem;
  svg {
    fill: ${whiteTheme.palette.success.main};
  }
`

export const CancelBtn = styled(IconButton)`
  padding: 0;
`
