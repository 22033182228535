import React from "react"
import { Field } from "react-final-form"

import FormControl from "@material-ui/core/FormControl"

import { InputField } from "view/uikit/formFields/InputField"
import { FormButtons } from "view/uikit/common/FormButtons"
import { FormError } from "view/uikit/common/FormError"
import { Container } from "view/forms/translation/TranslationAddForm/container"
import { langNameByCode } from "view/helpers/langNameByCode"

const Component = ({
  handleSubmit,
  submitError,
  submitting,
  cbCancel,
  defaultLang,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <Field component={InputField} label="Key" name="key" autoFocus />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={InputField}
          label="Translation"
          name="translation"
          helperText={`Translation for default languages ([${defaultLang}] ${langNameByCode(
            defaultLang
          )})`}
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={cbCancel}
        submitBtn={{
          disabled: submitting,
          title: "Add",
        }}
      />
    </form>
  )
}

export const TranslationAddForm = ({ cbCancel }) => (
  <Container component={Component} componentProps={{ cbCancel }} />
)
