import { useDispatch } from "react-redux"
import React, { useCallback } from "react"
import {
  signUpWithFacebook,
  signUpWithGitHub,
  signUpWithGoogle,
  signUpWithYahoo,
} from "data/entities/identity/thunks"

import { Button, Grid } from "@material-ui/core"
import { ReactComponent as GoogleSvg } from "view/components/identity/SocialButtons/icons/google.svg"
import { ReactComponent as GitHubSvg } from "view/components/identity/SocialButtons/icons/github.svg"
import { ReactComponent as FacebookSvg } from "view/components/identity/SocialButtons/icons/facebook.svg"
import { ReactComponent as YahooSvg } from "view/components/identity/SocialButtons/icons/yahoo.svg"

export const SocialButtons = () => {
  const dispatch = useDispatch()

  const handlerWithGoogle = useCallback(() => {
    window.ym(75137779, "reachGoal", "sb_google")
    dispatch(signUpWithGoogle())
  }, [dispatch])

  const handlerWithGitHub = useCallback(() => {
    window.ym(75137779, "reachGoal", "sb_github")
    dispatch(signUpWithGitHub())
  }, [dispatch])

  const handlerWithYahoo = useCallback(() => {
    window.ym(75137779, "reachGoal", "sb_yahoo")
    dispatch(signUpWithYahoo())
  }, [dispatch])

  const handlerWithFacebook = useCallback(() => {
    window.ym(75137779, "reachGoal", "sb_facebook")
    dispatch(signUpWithFacebook())
  }, [dispatch])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<GoogleSvg />}
          onClick={handlerWithGoogle}
        >
          Google
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<GitHubSvg />}
          onClick={handlerWithGitHub}
        >
          GitHub
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<FacebookSvg />}
          onClick={handlerWithFacebook}
        >
          Facebook
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          variant="contained"
          startIcon={<YahooSvg />}
          onClick={handlerWithYahoo}
        >
          Yahoo
        </Button>
      </Grid>
    </Grid>
  )
}
