import { createSlice } from "@reduxjs/toolkit"
import { languageCreate } from "data/entities/language/thunks"
import { projectCreate } from "data/entities/project/thunks"
import { translationCreate } from "data/entities/translation/thunks"
import { entityName } from "data/entities/consts"

export const ModalEnum = {
  Confirm: "Confirm",
  ProjectCreate: "ProjectCreate",
  TranslationAdd: "TranslationAdd",
  LanguageAdd: "LanguageAdd",
  InviteSend: "InviteSend",
  Upgrade: "Upgrade",
  KeyEdit: "KeyEdit",
}

const initialState = {
  name: null,
  props: null,
}

export const modalSlice = createSlice({
  name: entityName.modal,
  initialState,
  reducers: {
    openModal: {
      reducer: (state, action) => {
        return {
          ...state,
          ...action.payload,
        }
      },
      prepare: (name, props = null) => {
        return { payload: { name, props } }
      },
    },
    closeModal: () => initialState,
  },
  extraReducers: {
    [projectCreate.fulfilled]: () => {
      return initialState
    },
    [translationCreate.fulfilled]: () => {
      return initialState
    },
    [languageCreate.fulfilled]: () => {
      return initialState
    },
  },
})

export const { openModal, closeModal } = modalSlice.actions
