import React, { useCallback, useState } from "react"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"

import { fbApp } from "infrastructure/firebase"

import { validate } from "./validate"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const [success, setSuccess] = useState(false)

  const handlerSubmit = useCallback(async (values) => {
    try {
      await fbApp.auth().sendPasswordResetEmail(values.email)
      setSuccess(true)
    } catch (e) {
      let error

      switch (e.code) {
        case "auth/user-not-found":
          error = e.message
          break
        default:
          error = "Internal error"
      }

      return {
        [FORM_ERROR]: error,
      }
    }
    return {}
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} success={success} />}
    />
  )
}
