import React from "react"
import { Field } from "react-final-form"
import FormControl from "@material-ui/core/FormControl"

import { InputField } from "view/uikit/formFields/InputField"
import { AutocompleteField } from "view/uikit/formFields/AutocompleteField"
import { FormButtons } from "view/uikit/common/FormButtons"
import { FormError } from "view/uikit/common/FormError"

import { Container } from "./container"
import * as S from "./styled"

const Component = ({ handleSubmit, submitError, submitting, langOptions }) => {
  return (
    <S.Form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <Field
          component={InputField}
          name="name"
          label="Project name"
          helperText="Example: Mobile App"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={AutocompleteField}
          name="defaultLang"
          label="Default language"
          options={langOptions}
          disabled={submitting}
          disableClearable
          helperText="Base language for your project"
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        submitBtn={{
          disabled: submitting,
          title: "Update",
        }}
      />
    </S.Form>
  )
}

export const SettingForm = () => <Container component={Component} />
