import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  width: 500px;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  padding-top: 2rem;
`
