import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"
import { useDispatch, useSelector } from "react-redux"

import { apiService } from "infrastructure/services/ApiService"
import { appHistory } from "infrastructure/application/history"
import { gaService } from "infrastructure/services/GAService"

import { identitySelectors } from "data/entities/identity/selectors"
import { userGetByUid } from "data/entities/user/thunks"

import { paths } from "view/router/paths"
import { validate } from "./validate"

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const identity = useSelector(identitySelectors.current)

  const initialValues = {
    name: identity.name,
    email: identity.email,
  }

  const handlerSubmit = useCallback(
    async (values) => {
      try {
        const result = await apiService.post("/signup-complete", values)

        if (result.data === "OK") {
          dispatch(userGetByUid(identity.fbUid))

          gaService.sendEvent("event", "conversion", {
            send_to: "AW-389992691/8OPICP7gspMCEPOh-7kB",
          })

          window.ym(75137779, "reachGoal", "signup_2")

          appHistory.push(paths.projects())
        }
      } catch (e) {
        const errors = {}

        errors[FORM_ERROR] = "Internal error"

        return errors
      }
      return {}
    },
    [identity]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
