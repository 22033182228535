import { useDispatch, useSelector } from "react-redux"

import { translationFetchList } from "data/entities/translation/thunks"
import { useEffect } from "react"
import { translationSelectors } from "data/entities/translation/selectors"
import { projectSelectors } from "data/entities/project/selectors"
import { languageSelectors } from "data/entities/language/selectors"
import { usePrev } from "infrastructure/_hooks/usePrev"

export const TranslationRefetchContainer = () => {
  const dispatch = useDispatch()

  const project = useSelector(projectSelectors.current)
  const search = useSelector(translationSelectors.search)
  const status = useSelector(translationSelectors.status)
  const pagging = useSelector(translationSelectors.pagging)
  const langFilter = useSelector(languageSelectors.filter)

  // console.log("RENDER TranslationRefetchContainer")
  // console.log("******************************")
  // console.log("langFilter", langFilter, langFilter === usePrev(langFilter))
  // console.log(
  //   "pagging?.limit",
  //   pagging?.limit,
  //   pagging?.limit === usePrev(pagging?.limit)
  // )
  // console.log(
  //   "pagging?.page",
  //   pagging?.page,
  //   pagging?.page === usePrev(pagging?.page)
  // )
  // console.log("project?.id", project?.id, project?.id === usePrev(project?.id))
  // console.log("search", search, search === usePrev(search))
  // console.log("status", status, status === usePrev(status))

  useEffect(() => {
    if (!project?.id) return
    if (!status) return

    dispatch(
      translationFetchList({
        projectId: project.id,
        page: pagging?.page,
        limit: pagging?.limit,
        search,
        status,
        langs: langFilter.join(","),
      })
    )
  }, [langFilter, pagging?.limit, pagging?.page, project?.id, search, status])

  return null
}
