import React from "react"

import * as S from "./styled"

export const Box = ({ title, color, children }) => {
  return (
    <S.Wrapper color={color}>
      {title && <S.Title color={color}>{title}</S.Title>}
      {children}
    </S.Wrapper>
  )
}
