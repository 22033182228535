import React from "react"
import { Field } from "react-final-form"

import {
  Button,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"

import { Container } from "view/forms/identity/SignupForm/container"
import { InputField } from "view/uikit/formFields/InputField"
import { paths } from "view/router/paths"
import { SocialButtons } from "view/components/identity/SocialButtons"
import { Link } from "view/uikit/base/Link"

import { FormError } from "view/uikit/common/FormError"
import * as S from "view/forms/identity/SignupForm/styled"

const Component = ({ handleSubmit, submitError, submitting }) => {
  return (
    <S.Wrapper>
      <S.Leftside>
        <Typography variant="h4" component="h3" align="center">
          Sign up
        </Typography>

        <SocialButtons />

        <S.SubTitle>You can also sign up with email</S.SubTitle>

        <S.Form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <Field component={InputField} name="email" label="Email" />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Field
              component={InputField}
              name="password"
              label="Password"
              type="password"
            />
          </FormControl>

          {submitError && (
            <FormControl fullWidth margin="normal">
              <FormError>{submitError}</FormError>
            </FormControl>
          )}

          <Button
            color="primary"
            variant="contained"
            disabled={submitting}
            type="submit"
          >
            Sign up
          </Button>
        </S.Form>

        <S.Text>
          Already have an account? <Link to={paths.login()}>Login.</Link>
        </S.Text>
      </S.Leftside>

      <S.Rightside>
        <S.BenefitsTitle>Create a free account</S.BenefitsTitle>
        <S.BenefitsSubtitle>
          Use free forever. No credit card required.
        </S.BenefitsSubtitle>

        <List>
          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Unlimited team members" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Unlimited languages" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="User-friendly interface" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="CLI tool for integration into your CI processes" />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CheckOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Import and export your files in any format" />
          </ListItem>
        </List>
      </S.Rightside>
    </S.Wrapper>
  )
}

export const SignupForm = () => {
  return <Container component={Component} />
}
