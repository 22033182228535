import React, { memo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useSnackbar } from "notistack"

import { Button, Grid, IconButton } from "@material-ui/core"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"

import { appHistory } from "infrastructure/application/history"

import { projectRemove } from "data/entities/project/thunks"
import { ModalEnum } from "data/entities/modal"
import { projectSelectors } from "data/entities/project/selectors"
import { identitySelectors } from "data/entities/identity/selectors"

import { paths } from "view/router/paths"
import { PageHeader } from "view/uikit/common/PageHeader"
import { Box } from "view/uikit/base/Box"
import { ConfirmModal } from "view/modals/application/ConfirmModal"
import { useModal } from "view/modals/useModal"
import { Tooltip } from "view/uikit/base/Tooltip"
import { SettingForm } from "view/forms/project/SettingForm"
import { usePermission } from "view/hooks/rbac/usePermission"

import * as S from "./styled"

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const project = useSelector(projectSelectors.current)

  const removeAccepted = useCallback(() => {
    dispatch(projectRemove(project?.id))
    appHistory.push(paths.projects(project?.id))
  }, [dispatch, project])

  if (!permission.organizationSettings.canRead) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader title="Settings" />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box title="General">
            <SettingForm />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box title="Integration">
            {project?.apiKey && (
              <S.PropRow>
                <S.PropName>API key:</S.PropName>
                <S.PropValue>{project?.apiKey}</S.PropValue>
                <CopyToClipboard
                  text={project?.apiKey}
                  onCopy={() => {
                    enqueueSnackbar("Copied!", {
                      variant: "success",
                    })
                  }}
                >
                  <Tooltip title="Copy to clipboard">
                    <IconButton size="small">
                      <FileCopyOutlinedIcon size="small" />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </S.PropRow>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box title="Danger zone" color="secondary">
            <p>Once deleted, it will be gone forever. Please be certain.</p>
            <Button onClick={openConfirm} color="secondary" variant="outlined">
              Delete project
            </Button>
          </Box>
        </Grid>
      </Grid>

      <ConfirmModal
        title="Are you ABSOLUTELY sure?"
        text={
          <>
            This action <b>CANNOT</b> be undone. This will permanently delete
            the <b>{project?.name}</b> project including all keys, translations
            and all other assets.
          </>
        }
        onAccept={removeAccepted}
        labelAccept="Delete project"
      />
    </>
  )
}

export const SettingsPage = memo(Page)
