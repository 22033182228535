import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"

import { emptyOption } from "data/mocks/emptyOption"
import { languageCreate } from "data/entities/language/thunks"
import { languageSelectors } from "data/entities/language/selectors"

import { validate } from "./validate"

const initialValues = {
  lang: emptyOption,
}

export const Container = ({ component: Component, componentProps }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const langs = useSelector(languageSelectors.selectAll)

  const handlerSubmit = useCallback(
    async ({ lang }) => {
      const result = await dispatch(languageCreate(lang.value))

      if (result.type === languageCreate.fulfilled().type) {
        enqueueSnackbar("Language has been added!", {
          variant: "success",
        })
      }

      return {}
    },
    [dispatch]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => (
        <Component
          {...props}
          {...componentProps}
          excludeLangs={langs.map((l) => l.id)}
        />
      )}
    />
  )
}
