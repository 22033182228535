import styled from "styled-components"

import Toolbar from "@material-ui/core/Toolbar"

export const MuiToolbar = styled(Toolbar)`
  display: flex;
  align-items: stretch;
`

export const Search = styled.div`
  display: flex;
  align-items: center;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-grow: 1;
`
