import React, { useCallback, useState } from "react"
import { Field } from "react-final-form"
import escape from "escape-html"

import { ClickAwayListener } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"

import { InputField } from "view/uikit/formFields/InputField"

import { Container } from "view/forms/translation/TranslationEditForm/container"

import * as S from "view/forms/translation/TranslationEditForm/styled"

const Component = ({
  form,
  search,
  handleSubmit,
  submitError,
  submitting,
  values: { translation },
}) => {
  const [show, setShow] = useState(false)

  const parseTranslation = useCallback(() => {
    if (!translation) return ""

    let result = translation

    if (typeof result === "object") return "[Incorrect data]"

    result = result
      .replaceAll(/<[\d]>[A-z ]*<\/[\d]>/g, (match) => `[MV]${match}[/MV]`)
      .replaceAll(/{{[A-z\d]*}}/g, (match) => `[MV]${match}[/MV]`)

    if (search) {
      result = result.replaceAll(search, `[MS]${search}[/MS]`)
    }

    result = escape(result)

    return result
      .replaceAll("[MS]", "<mark-search>")
      .replaceAll("[/MS]", "</mark-search>")
      .replaceAll("[MV]", "<mark-var>")
      .replaceAll("[/MV]", "</mark-var>")
      .replace(/\n/g, "<br />")
  }, [search, translation])

  if (!show)
    return (
      <S.ViewMode
        onClick={() => {
          setShow(true)
        }}
        dangerouslySetInnerHTML={{
          __html: parseTranslation(),
        }}
      >
        {/* {markedTranslation()} */}
        {/* <Tooltip title="Verify translation"> */}
        {/*  /!* <S.VerifyBtn size="small" onClick={handlerReview}> *!/ */}
        {/*  /!*  Verify *!/ */}
        {/*  /!* </S.VerifyBtn> *!/ */}
        {/*  /!* <S.VerifyBtn size="small" onClick={handlerReview}> *!/ */}
        {/*  /!*  <SpellcheckOutlinedIcon /> *!/ */}
        {/*  /!* </S.VerifyBtn> *!/ */}
        {/* </Tooltip> */}
      </S.ViewMode>
    )

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <S.EditMode>
        <form onSubmit={(e) => handleSubmit(e, form, setShow(false))}>
          <S.Input>
            <Field
              component={InputField}
              name="translation"
              multiline
              autoFocus
            />
          </S.Input>

          {submitError && <div>{submitError}</div>}

          <S.Buttons>
            <S.SaveBtn size="small" disabled={submitting} type="submit">
              <CheckIcon fontSize="small" />
            </S.SaveBtn>
            <S.CancelBtn
              size="small"
              disabled={submitting}
              onClick={() => {
                form.reset()
                setShow(false)
              }}
            >
              <CloseOutlinedIcon />
            </S.CancelBtn>
          </S.Buttons>
        </form>
      </S.EditMode>
    </ClickAwayListener>
  )
}

export const TranslationEditForm = (props) => (
  <Container component={Component} {...props} />
)
