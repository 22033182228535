import React, { useCallback } from "react"
import { FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core"

export const SelectField = ({
  input: { onChange, ...input },
  options,
  meta,
  label,
  helperText,
  variant = "filled",
  ...rest
}) => {
  const errorMessage = meta.error || meta.submitError
  const validateError = errorMessage && meta.touched ? errorMessage : undefined

  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <>
      <InputLabel id={`select-${input.name}-label`} variant={variant}>
        {label}
      </InputLabel>
      <Select
        labelId={`select-${input.name}-label`}
        id={`select-${input.name}`}
        value={input.value}
        variant={variant}
        onChange={handleChange}
        error={!!validateError}
        {...rest}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!validateError}>
        {validateError || helperText}
      </FormHelperText>
    </>
  )
}
