import React from "react"
import { Field } from "react-final-form"

import FormControl from "@material-ui/core/FormControl"

import { InputField } from "view/uikit/formFields/InputField"
import { FormButtons } from "view/uikit/common/FormButtons"
import { FormError } from "view/uikit/common/FormError"

import { Container } from "./container"

const Component = ({ handleSubmit, submitError, submitting, cbCancel }) => {
  return (
    <form onSubmit={handleSubmit} style={{ width: "500px" }}>
      <FormControl fullWidth margin="normal">
        <Field component={InputField} label="Key" name="key" autoFocus />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={cbCancel}
        submitBtn={{
          disabled: submitting,
          title: "Update",
        }}
      />
    </form>
  )
}

export const KeyEditForm = ({ cbCancel, id }) => (
  <Container component={Component} id={id} componentProps={{ cbCancel }} />
)
