import React from "react"

import { SetCurrentProjectContainer } from "domain/project/SetCurerntProjectContainer"
import { TranslationRefetchContainer } from "domain/translation/TranslationRefetchContainer"

export const ContainersAfterInit = () => (
  <>
    <SetCurrentProjectContainer />
    <TranslationRefetchContainer />
  </>
)
