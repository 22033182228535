import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"

import { adapter } from "data/entities/billingPlans/selectors"
import { billingPlanFetchList } from "data/entities/billingPlans/thunks"

import { entityName } from "data/entities/consts"

const initialState = adapter.getInitialState()

export const billingPlansSlice = createSlice({
  name: entityName.billingPlans,
  initialState,
  reducers: {},
  extraReducers: {
    [billingPlanFetchList.fulfilled]: (state, action) => {
      adapter.setAll(state, action)
    },
    [logout.fulfilled]: () => initialState,
  },
})
