import React from "react"
import { Field } from "react-final-form"
import FormControl from "@material-ui/core/FormControl"

import { langOptions } from "data/mocks/langOptions"

import { InputField } from "view/uikit/formFields/InputField"
import { Container } from "view/forms/project/ProjectForm/container"
import { AutocompleteField } from "view/uikit/formFields/AutocompleteField"
import { FormButtons } from "view/uikit/common/FormButtons"

import * as S from "view/forms/project/ProjectForm/styled"
import { FormError } from "view/uikit/common/FormError"

const Component = ({ handleSubmit, submitError, submitting, cbCancel }) => {
  return (
    <S.Form onSubmit={handleSubmit}>
      <div>
        Projects allow you to scope translation strings to a specific
        application in your organization.
      </div>
      <br />
      <FormControl fullWidth margin="normal">
        <Field
          component={InputField}
          name="name"
          label="Name"
          autoFocus
          helperText="Example: Mobile App"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={AutocompleteField}
          name="defaultLang"
          label="Default language"
          options={langOptions}
          helperText="Base language for your project"
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={cbCancel}
        submitBtn={{
          disabled: submitting,
          title: "Create",
        }}
      />
    </S.Form>
  )
}

export const ProjectForm = ({ cbCancel }) => (
  <Container component={Component} componentProps={{ cbCancel }} />
)
