export const nameToKey = (name) => {
  return name.toLowerCase().replaceAll(" ", "-").replaceAll(".", "-")
}

export const enumToOptions = (obj) => {
  return Object.keys(obj).map((key) => {
    return {
      value: obj[key],
      label: key,
    }
  })
}

export const mark = (key, search) => {
  return key.replace(search, `<i>${search}</i>`)
}

export const uniq = (array) => {
  return [...new Set([...array])]
}
