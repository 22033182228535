import styled, { keyframes } from "styled-components"

const SIZE = 1
const SPEED = 1.8

const load = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 ${SIZE}rem 0 -1.3rem;
  }
  40% {
    box-shadow: 0 ${SIZE}rem 0 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const Loader = styled.div`
  color: ${({ color }) => color};
  //font-size: 10px;
  //margin: auto;
  position: relative;
  text-indent: -9999rem;
  transform: translateZ(0);

  border-radius: 50%;
  width: ${SIZE}rem;
  height: ${SIZE}rem;
  animation-fill-mode: both;
  animation: ${load} ${SPEED}s infinite ease-in-out;
  animation-delay: -0.16s;

  &:before,
  &:after {
    border-radius: 50%;
    width: ${SIZE}rem;
    height: ${SIZE}rem;
    animation-fill-mode: both;
    animation: ${load} ${SPEED}s infinite ease-in-out;

    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    left: -${SIZE * 1.25}rem;
    animation-delay: -0.32s;
  }

  &:after {
    left: ${SIZE * 1.25}rem;
    //animation-delay: 0.32s;
  }
`

//
// @-webkit-keyframes ${load}} {
//   0%,
//   80%,
//   100% {
//     box-shadow: 0 ${SIZE}} 0 -1.3em;
// }
//   40% {
//     box-shadow: 0 2.5em 0 0;
// }
// }
// @keyframes ${load}} {
//   0%,
//   80%,
//   100% {
//     box-shadow: 0 2.5em 0 -1.3em;
// }
//   40% {
//     box-shadow: 0 2.5em 0 0;
// }
// }
