import React, { useEffect, useRef } from "react"
import TextField from "@material-ui/core/TextField"

export const InputField = ({
  input: { onChange, ...input },
  meta,
  variant = "filled",
  helperText,
  disabled,
  ref,
  autoFocus,
  InputProps,
  ...rest
}) => {
  const errorMessage = meta.error || meta.submitError
  const validateError = errorMessage && meta.touched ? errorMessage : undefined

  const inputRef = useRef()

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.selectionStart = input.value.length
    }
  }, [])

  return (
    <TextField
      disabled={disabled || meta.submitting}
      variant={variant}
      error={!!validateError}
      helperText={validateError || helperText}
      onChange={onChange}
      fullWidth
      inputRef={inputRef}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
      }}
      autoFocus={autoFocus}
      {...rest}
      {...input}
    />
  )
}
