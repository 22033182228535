import React from "react"
import { useSelector } from "react-redux"

import { LeftSidebar } from "view/components/application/LeftSidebar"
import { Toolbar } from "view/components/application/Toolbar"

import { applicationSelectors } from "data/entities/application/selectors"
import * as S from "view/components/application/__layouts/MainLayout/styled"

export const MainLayout = ({ children, withoutAppBar }) => {
  const { leftSidebarOpen } = useSelector(applicationSelectors.clientState)

  return (
    <S.Wrapper>
      {!withoutAppBar && (
        <S.AppBar position="fixed" color="default" open={leftSidebarOpen}>
          <Toolbar />
        </S.AppBar>
      )}

      <LeftSidebar open={leftSidebarOpen} />

      <S.Main open={leftSidebarOpen} withoutAppBar={withoutAppBar}>
        {children}
      </S.Main>
    </S.Wrapper>
  )
}
