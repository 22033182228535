import React, { useCallback, useMemo } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { FORM_ERROR } from "final-form"
import { useSnackbar } from "notistack"

import { appHistory } from "infrastructure/application/history"
import { nameToKey } from "infrastructure/utils"

import { projectUpdate } from "data/entities/project/thunks"
import { organizationSelectors } from "data/entities/organization/selectors"

import { paths } from "view/router/paths"
import { organizationUpdate } from "data/entities/organization/thunks"
import { validate } from "./validate"

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const organization = useSelector(organizationSelectors.current)

  const initialValues = useMemo(
    () => ({
      name: organization?.name || "",
    }),
    [organization]
  )

  const handlerSubmit = useCallback(
    async ({ name }, { reset }) => {
      try {
        if (!organization) {
          return {
            [FORM_ERROR]: "Server error",
          }
        }

        const result = await dispatch(
          organizationUpdate({
            orgId: organization._id,
            name: name.trim(),
          })
        )

        if (result.type === organizationUpdate.fulfilled().type) {
          setTimeout(reset, 0)
          enqueueSnackbar("Organization has been updated!", {
            variant: "success",
          })
          return {}
        }

        if (result.type === organizationUpdate.rejected().type) {
          return {
            [FORM_ERROR]: "Server error",
          }
        }

        return {}
      } catch (e) {
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [organization]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
