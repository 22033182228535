import React, { memo, useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { Button, Tabs, Tab } from "@material-ui/core"

import { appHistory } from "infrastructure/application/history"

import { identitySelectors } from "data/entities/identity/selectors"
import { SubscribeButton } from "view/pages/billing/PlansPagePerUser/SubscribeButton"

import { PageHeader } from "view/uikit/common/PageHeader"
import { TransactionList } from "view/pages/billing/BillingPage/TransactionList"
import { CurrentSubscription } from "view/pages/billing/BillingPage/CurrentSubscription"
import { paths } from "view/router/paths"

import { usePermission } from "view/hooks/rbac/usePermission"
import * as S from "./styled"

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <S.TabPanel p={3}>{children}</S.TabPanel>}
    </div>
  )
}

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // const redirectToPlans = useCallback(() => {
  //   appHistory.push(paths.plans())
  // }, [])

  if (!permission.billing.canRead) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader
        title="Billing"
        button={
          // <Button
          //   size="small"
          //   variant="contained"
          //   color="primary"
          //   onClick={redirectToPlans}
          // >
          //   Upgrade plan
          // </Button>
          <SubscribeButton />
        }
      />

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Overview" />
        <Tab label="Transactions" />
        {/* <Tab label="Billing info" /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <CurrentSubscription />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TransactionList />
      </TabPanel>
      {/* <TabPanel value={value} index={2}> */}
      {/*  <BillingInfo /> */}
      {/* </TabPanel> */}
    </>
  )
}

export const BillingPage = memo(Page)
