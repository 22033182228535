import React from "react"
import * as S from "view/uikit/base/Loader/styled"

export const Loader = ({ color = "white", size = "small" }) => {
  return (
    <S.Wrapper>
      <S.Loader color={color} />
    </S.Wrapper>
  )
}
