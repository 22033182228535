import { createAction } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const projectCurrentClear = createAction(
  `${entityName.project}/currentClear`
)

export const projectCurrentChanged = createAction(
  `${entityName.project}/currentChanged`
)
