import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import { base } from "view/themes/base"

export const whiteTheme = createMuiTheme(
  {
    palette: {
      background: {
        default: "#f9f9f9", // TODO
      },
      primary: {
        main: "#523ddb", // 523ddb //6252D0
      },
    },
    overrides: {
      MuiButton: {
        root: {
          padding: "0.5rem 1rem",
        },
        contained: {
          boxShadow: "none",
          backgroundColor: "#ececec",
        },
        containedPrimary: {
          backgroundImage:
            "linear-gradient(140deg, rgba(45,129,185,1) 0%, rgba(116,101,217,1) 72%, rgba(82,61,219,1) 100%)",
          "&.Mui-disabled": {
            color: "#ececec",
            backgroundImage: "none",
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "40px",
        },
      },
      MuiTextField: {
        root: {},
      },
      MuiDialogContent: {
        root: {},
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "#f8f8f8",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
          "&$focused": {
            backgroundColor: "#f2f2f2",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.04);",
          },
        },
      },
    },
  },
  base
)
