import styled from "styled-components"
import { palette } from "view/themes/white/palette"

export const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
`

export const ProjectWrapper = styled.div`
  width: 33.33%;
  padding: 0 1rem 2rem;

  //&:nth-child(3) > div {
  //  margin-right: 0;
  //}
`

export const Project = styled.div`
  //display: inline-block;
  //width: 300px; // TODO flex cards
  min-width: 200px;
  flex-shrink: 0;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  //margin-right: 2rem;
  //margin-bottom: 2rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const ButtonSettingWrapper = styled.div`
  text-align: right;

  & > button {
    padding: 0;
  }

  & svg {
    fill: #a0a0a0;

    &:hover {
      fill: ${palette.gray};
    }
  }
`

export const Progress = styled.div`
  flex-grow: 1;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
`

export const Percent = styled.div`
  flex-shrink: 0;
  text-align: right;
  margin-left: 0.5rem;
  padding-top: 0.5rem;
`
export const Languages = styled.div`
  color: ${palette.gray};
`

export const Untranslated = styled.div`
  text-align: center;
  color: ${palette.gray};
`

export const Keys = styled.div`
  text-align: right;
  color: ${palette.gray};
`

export const ButtonWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`
