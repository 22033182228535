import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { Transition } from "view/modals/Transition"
import { KeyEditForm } from "view/forms/translation/KeyEditForm"

export const KeyEditModal = () => {
  const {
    open,
    onClose,
    props: { id },
  } = useModal(ModalEnum.KeyEdit)

  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Edit key</DialogTitle>
      <DialogContent>
        <KeyEditForm cbCancel={onClose} id={id} />
      </DialogContent>
    </Dialog>
  )
}
