import { emptyOption } from "data/mocks/emptyOption"

// https://www.localeplanet.com/icu/iso3166.html#AF

export const langOptions = [
  emptyOption,
  {
    value: "en",
    label: "English",
    flag: "gb",
  },
  {
    value: "es",
    label: "Spanish",
    flag: "es",
  },
  {
    value: "de",
    label: "German",
  },
  {
    value: "fr",
    label: "French",
  },
  {
    value: "pt",
    label: "Portuguese",
  },
  {
    value: "ru",
    label: "Russian",
  },
  {
    value: "zh",
    label: "Chinese",
  },
  {
    value: "ab",
    label: "Abkhazian",
  },
  {
    value: "ace",
    label: "Achinese",
  },
  {
    value: "ach",
    label: "Acoli",
  },
  {
    value: "ada",
    label: "Adangme",
  },
  {
    value: "ady",
    label: "Adyghe",
  },
  {
    value: "aa",
    label: "Afar",
  },
  {
    value: "aa-DJ",
    label: "Afar, Djibouti",
  },
  {
    value: "aa-ER",
    label: "Afar, Eritrea",
  },
  {
    value: "aa-ET",
    label: "Afar, Ethiopia",
  },
  {
    value: "aa-Ethi",
    label: "Afar(Ethiopic)",
  },
  {
    value: "afh",
    label: "Afrihili",
  },
  {
    value: "af",
    label: "Afrikaans",
  },
  {
    value: "af-NA",
    label: "Afrikaans, Namibia",
  },
  {
    value: "af-ZA",
    label: "Afrikaans, South Africa",
  },
  {
    value: "afa",
    label: "Afro-Asiatic Language",
  },
  {
    value: "ain",
    label: "Ainu",
  },
  {
    value: "ain-Latn",
    label: "Ainu(Latin)",
  },
  {
    value: "ak",
    label: "Akan",
  },
  {
    value: "ak-GH",
    label: "Akan, Ghana",
  },
  {
    value: "akk",
    label: "Akkadian",
  },
  {
    value: "sq",
    label: "Albanian",
  },
  {
    value: "sq-AL",
    label: "Albanian, Albania",
  },
  {
    value: "ale",
    label: "Aleut",
  },
  {
    value: "alg",
    label: "Algonquian Language",
  },
  {
    value: "tut",
    label: "Altaic Language",
  },
  {
    value: "am",
    label: "Amharic",
  },
  {
    value: "am-ET",
    label: "Amharic, Ethiopia",
  },
  {
    value: "anp",
    label: "Angika",
  },
  {
    value: "apa",
    label: "Apache Language",
  },
  {
    value: "ar",
    label: "Arabic",
  },
  {
    value: "ar-DZ",
    label: "Arabic, Algeria",
  },
  {
    value: "ar-Arab",
    label: "Arabic(Perso-Arabic)",
  },
  {
    value: "ar-BH",
    label: "Arabic, Bahrain",
  },
  {
    value: "ar-EG",
    label: "Arabic, Egypt",
  },
  {
    value: "ar-IQ",
    label: "Arabic, Iraq",
  },
  {
    value: "ar-JO",
    label: "Arabic, Jordan",
  },
  {
    value: "ar-KW",
    label: "Arabic, Kuwait",
  },
  {
    value: "ar-LB",
    label: "Arabic, Lebanon",
  },
  {
    value: "ar-LY",
    label: "Arabic, Libya",
  },
  {
    value: "ar-MA",
    label: "Arabic, Morocco",
  },
  {
    value: "ar-OM",
    label: "Arabic, Oman",
  },
  {
    value: "ar-QA",
    label: "Arabic, Qatar",
  },
  {
    value: "ar-SA",
    label: "Arabic, Saudi Arabia",
  },
  {
    value: "ar-SD",
    label: "Arabic, Sudan",
  },
  {
    value: "ar-SY",
    label: "Arabic, Syria",
  },
  {
    value: "ar-TN",
    label: "Arabic, Tunisia",
  },
  {
    value: "ar-AE",
    label: "Arabic, United Arab Emirates",
  },
  {
    value: "ar-YE",
    label: "Arabic, Yemen",
  },
  {
    value: "an",
    label: "Aragonese",
  },
  {
    value: "arp",
    label: "Arapaho",
  },
  {
    value: "arn",
    label: "Araucanian",
  },
  {
    value: "arw",
    label: "Arawak",
  },
  {
    value: "hy",
    label: "Armenian",
  },
  {
    value: "hy-AM",
    label: "Armenian, Armenia",
  },
  {
    value: "rup",
    label: "Aromanian",
  },
  {
    value: "rup-Grek",
    label: "Aromanian(Greek)",
  },
  {
    value: "rup-Latn",
    label: "Aromanian(Latin)",
  },
  {
    value: "art",
    label: "Artificial Language",
  },
  {
    value: "as",
    label: "Assamese",
  },
  {
    value: "as-IN",
    label: "Assamese, India",
  },
  {
    value: "ast",
    label: "Asturian",
  },
  {
    value: "ath",
    label: "Athapascan Language",
  },
  {
    value: "cch",
    label: "Atsam",
  },
  {
    value: "cch-NG",
    label: "Atsam, Nigeria",
  },
  {
    value: "aus",
    label: "Australian Language",
  },
  {
    value: "map",
    label: "Austronesian Language",
  },
  {
    value: "av",
    label: "Avaric",
  },
  {
    value: "ae",
    label: "Avestan",
  },
  {
    value: "awa",
    label: "Awadhi",
  },
  {
    value: "ay",
    label: "Aymara",
  },
  {
    value: "az",
    label: "Azeri",
  },
  {
    value: "az-Arab",
    label: "Azeri(Perso-Arabic)",
  },
  {
    value: "az-AZ",
    label: "Azeri, Azerbaijan",
  },
  {
    value: "az-AZ-Cyrl",
    label: "Azeri, Azerbaijan(Cyrillic)-Cyrl",
  },
  {
    value: "az-AZ-Latn",
    label: "Azeri, Azerbaijan(Latin)-Latn",
  },
  {
    value: "az-Cyrl",
    label: "Azeri(Cyrillic)",
  },
  {
    value: "az-Latn",
    label: "Azeri(Latin)",
  },
  {
    value: "az-IR",
    label: "Azeri, Iran",
  },
  {
    value: "az-IR-Arab",
    label: "Azeri, Iran(Perso-Arabic)-Arab",
  },
  {
    value: "ban",
    label: "Balinese",
  },
  {
    value: "bat",
    label: "Baltic Language",
  },
  {
    value: "bal",
    label: "Baluchi",
  },
  {
    value: "bal-Arab",
    label: "Baluchi(Perso-Arabic)",
  },
  {
    value: "bm",
    label: "Bambara",
  },
  {
    value: "bai",
    label: "Bamileke Language",
  },
  {
    value: "bad",
    label: "Banda",
  },
  {
    value: "bnt",
    label: "Bantu",
  },
  {
    value: "bas",
    label: "Basa",
  },
  {
    value: "ba",
    label: "Bashkir",
  },
  {
    value: "eu",
    label: "Basque",
  },
  {
    value: "eu-FR",
    label: "Basque, France",
  },
  {
    value: "eu-ES",
    label: "Basque, Spain",
  },
  {
    value: "btk",
    label: "Batak",
  },
  {
    value: "bej",
    label: "Beja",
  },
  {
    value: "be",
    label: "Belarusian",
  },
  {
    value: "be-BY",
    label: "Belarusian, Belarus",
  },
  {
    value: "be-Cyrl",
    label: "Belarusian(Cyrillic)",
  },
  {
    value: "be-Latn",
    label: "Belarusian(Latin)",
  },
  {
    value: "bem",
    label: "Bemba",
  },
  {
    value: "bn",
    label: "bengali",
  },
  {
    value: "bn-bd",
    label: "bengali, bangladesh",
  },
  {
    value: "bn-IN",
    label: "Bengali, India",
  },
  {
    value: "ber",
    label: "Berber",
  },
  {
    value: "bho",
    label: "Bhojpuri",
  },
  {
    value: "bh",
    label: "Bihari",
  },
  {
    value: "bik",
    label: "Bikol",
  },
  {
    value: "bin",
    label: "Bini",
  },
  {
    value: "bi",
    label: "Bislama",
  },
  {
    value: "byn",
    label: "Blin",
  },
  {
    value: "byn-ER",
    label: "Blin, Eritrea",
  },
  {
    value: "zbl",
    label: "Blissymbols",
  },
  {
    value: "bs",
    label: "Bosnian",
  },
  {
    value: "bs-BA",
    label: "Bosnian, Bosnia and Herzegovina",
  },
  {
    value: "bra",
    label: "Braj",
  },
  {
    value: "br",
    label: "Breton",
  },
  {
    value: "bug",
    label: "Buginese",
  },
  {
    value: "bg",
    label: "Bulgarian",
  },
  {
    value: "bg-BG",
    label: "Bulgarian, Bulgaria",
  },
  {
    value: "bua",
    label: "Buriat",
  },
  {
    value: "my",
    label: "Burmese",
  },
  {
    value: "my-MM",
    label: "Burmese, Myanmar [Burma]",
  },
  {
    value: "cad",
    label: "Caddo",
  },
  {
    value: "car",
    label: "Carib",
  },
  {
    value: "ca",
    label: "Catalan",
  },
  {
    value: "ca-ES",
    label: "Catalan, Spain",
  },
  {
    value: "cau",
    label: "Caucasian Language",
  },
  {
    value: "ceb",
    label: "Cebuano",
  },
  {
    value: "cel",
    label: "Celtic Language",
  },
  {
    value: "cai",
    label: "Central American Indian Language",
  },
  {
    value: "km",
    label: "Khmer",
  },
  {
    value: "km-KH",
    label: "Khmer, Cambodia",
  },
  {
    value: "cmc",
    label: "Chamic Language",
  },
  {
    value: "cmc-Arab",
    label: "Chamic Language(Perso-Arabic)",
  },
  {
    value: "ch",
    label: "Chamorro",
  },
  {
    value: "ce",
    label: "Chechen",
  },
  {
    value: "chr",
    label: "Cherokee",
  },
  {
    value: "chy",
    label: "Cheyenne",
  },
  {
    value: "cnr",
    label: "Montenegrin",
  },
  {
    value: "cnr-ME",
    label: "Montenegrin, Montenegro",
  },
  {
    value: "ny",
    label: "Nyanja",
  },
  {
    value: "ny-MW",
    label: "Nyanja, Malawi",
  },
  {
    value: "zh-CN",
    label: "Chinese, China",
  },
  {
    value: "zh-CN-Hans",
    label: "Chinese, China(Simplified Han)-Hans",
  },
  {
    value: "zh-HK",
    label: "Chinese, Hong Kong",
  },
  {
    value: "zh-HK-Hans",
    label: "Chinese, Hong Kong(Simplified Han)-Hans",
  },
  {
    value: "zh-HK-Hant",
    label: "Chinese, Hong Kong(Traditional Han)-Hant",
  },
  {
    value: "zh-MO",
    label: "Chinese, Macau",
  },
  {
    value: "zh-MO-Hans",
    label: "Chinese, Macau(Simplified Han)-Hans",
  },
  {
    value: "zh-MO-Hant",
    label: "Chinese, Macau(Traditional Han)-Hant",
  },
  {
    value: "zh-Hans",
    label: "Chinese(Simplified Han)",
  },
  {
    value: "zh-SG",
    label: "Chinese, Singapore",
  },
  {
    value: "zh-SG-Hans",
    label: "Chinese, Singapore(Simplified Han)-Hans",
  },
  {
    value: "zh-TW",
    label: "Chinese, Taiwan",
  },
  {
    value: "zh-TW-Hant",
    label: "Chinese, Taiwan(Traditional Han)-Hant",
  },
  {
    value: "zh-Hant",
    label: "Chinese(Traditional Han)",
  },
  {
    value: "chn",
    label: "Chinook Jargon",
  },
  {
    value: "chp",
    label: "Chipewyan",
  },
  {
    value: "cho",
    label: "Choctaw",
  },
  {
    value: "cu",
    label: "Church Slavic",
  },
  {
    value: "chk",
    label: "Chuukese",
  },
  {
    value: "cv",
    label: "Chuvash",
  },
  {
    value: "kw",
    label: "Cornish",
  },
  {
    value: "kw-GB",
    label: "Cornish, United Kingdom",
  },
  {
    value: "co",
    label: "Corsican",
  },
  {
    value: "cr",
    label: "Cree",
  },
  {
    value: "mus",
    label: "Creek",
  },
  {
    value: "crp",
    label: "Creole or Pidgin",
  },
  {
    value: "crh",
    label: "Crimean Turkish",
  },
  {
    value: "crh-Cyrl",
    label: "Crimean Turkish(Cyrillic)",
  },
  {
    value: "crh-Latn",
    label: "Crimean Turkish(Latin)",
  },
  {
    value: "hr",
    label: "Croatian",
  },
  {
    value: "hr-HR",
    label: "Croatian, Croatia",
  },
  {
    value: "cus",
    label: "Cushitic Language",
  },
  {
    value: "cs",
    label: "Czech",
  },
  {
    value: "cs-CZ",
    label: "Czech, Czech Republic",
  },
  {
    value: "dak",
    label: "Dakota",
  },
  {
    value: "da",
    label: "Danish",
  },
  {
    value: "da-DK",
    label: "Danish, Denmark",
  },
  {
    value: "dar",
    label: "Dargwa",
  },
  {
    value: "day",
    label: "Dayak",
  },
  {
    value: "del",
    label: "Delaware",
  },
  {
    value: "din",
    label: "Dinka",
  },
  {
    value: "dv",
    label: "Divehi",
  },
  {
    value: "dv-MV",
    label: "Divehi, Maldives",
  },
  {
    value: "dv-Thaa",
    label: "Divehi(Thaana)",
  },
  {
    value: "doi",
    label: "Dogri",
  },
  {
    value: "dgr",
    label: "Dogrib",
  },
  {
    value: "dra",
    label: "Dravidian Language",
  },
  {
    value: "dua",
    label: "Duala",
  },
  {
    value: "nl",
    label: "Dutch",
  },
  {
    value: "nl-BE",
    label: "Dutch, Belgium",
  },
  {
    value: "nl-NL",
    label: "Dutch, Netherlands",
  },
  {
    value: "dyu",
    label: "Dyula",
  },
  {
    value: "dz",
    label: "Dzongkha",
  },
  {
    value: "dz-BT",
    label: "Dzongkha, Bhutan",
  },
  {
    value: "frs",
    label: "Eastern Frisian",
  },
  {
    value: "efi",
    label: "Efik",
  },
  {
    value: "eka",
    label: "Ekajuk",
  },
  {
    value: "en-AS",
    label: "English, American Samoa",
  },
  {
    value: "en-AE",
    label: "English, Arabic Emirates",
  },
  {
    value: "en-AU",
    label: "English, Australia",
  },
  {
    value: "cpe",
    label: "English-based Creole or Pidgin",
  },
  {
    value: "en-BE",
    label: "English, Belgium",
  },
  {
    value: "en-BZ",
    label: "English, Belize",
  },
  {
    value: "en-BW",
    label: "English, Botswana",
  },
  {
    value: "en-CA",
    label: "English, Canada",
  },
  {
    value: "en-CH",
    label: "English, Switzerland",
  },
  {
    value: "en-Dsrt",
    label: "English(Deseret)",
  },
  {
    value: "en-GU",
    label: "English, Guam",
  },
  {
    value: "en-HK",
    label: "English, Hong Kong",
  },
  {
    value: "en-ID",
    label: "English, Indonesia",
  },
  {
    value: "en-IN",
    label: "English, India",
  },
  {
    value: "en-IE",
    label: "English, Ireland",
  },
  {
    value: "en-IL",
    label: "English, Israel",
  },
  {
    value: "en-JM",
    label: "English, Jamaica",
  },
  {
    value: "en-JP",
    label: "English, Japanese",
  },
  {
    value: "en-KE",
    label: "English, Kenia",
  },
  {
    value: "en-MT",
    label: "English, Malta",
  },
  {
    value: "en-MH",
    label: "English, Marshall Islands",
  },
  {
    value: "en-NA",
    label: "English, Namibia",
  },
  {
    value: "en-NG",
    label: "English, Nigeria",
  },
  {
    value: "en-NZ",
    label: "English, New Zealand",
  },
  {
    value: "en-MP",
    label: "English, Northern Mariana Islands",
  },
  {
    value: "en-MY",
    label: "English, Malaysia",
  },
  {
    value: "en-PK",
    label: "English, Pakistan",
  },
  {
    value: "en-PH",
    label: "English, Philippines",
  },
  {
    value: "en-Shaw",
    label: "English(Shavian)",
  },
  {
    value: "en-SG",
    label: "English, Singapore",
  },
  {
    value: "en-ZA",
    label: "English, South Africa",
  },
  {
    value: "en-TH",
    label: "English, Thailand",
  },
  {
    value: "en-TT",
    label: "English, Trinidad and Tobago",
  },
  {
    value: "en-GB",
    label: "English, United Kingdom",
  },
  {
    value: "en-US",
    label: "English, United States",
  },
  {
    value: "en-US-Dsrt",
    label: "English, United States(Deseret)-Dsrt",
  },
  {
    value: "en-UM",
    label: "English, U.S. Minor Outlying Islands",
  },
  {
    value: "en-USVI",
    label: "English, U.S. Virgin Islands",
  },
  {
    value: "en-ZW",
    label: "English, Zimbabwe",
  },
  {
    value: "en-145",
    label: "English, Western Asia",
  },
  {
    value: "en-CY",
    label: "English, Cypress",
  },
  {
    value: "myv",
    label: "Erzya",
  },
  {
    value: "eo",
    label: "Esperanto",
  },
  {
    value: "et",
    label: "Estonian",
  },
  {
    value: "et-EE",
    label: "Estonian, Estonia",
  },
  {
    value: "ee",
    label: "Ewe",
  },
  {
    value: "ee-GH",
    label: "Ewe, Ghana",
  },
  {
    value: "ee-TG",
    label: "Ewe, Togo",
  },
  {
    value: "ewo",
    label: "Ewondo",
  },
  {
    value: "fan",
    label: "Fang",
  },
  {
    value: "fat",
    label: "Fanti",
  },
  {
    value: "fo",
    label: "Faroese",
  },
  {
    value: "fo-FO",
    label: "Faroese, Faroe Islands",
  },
  {
    value: "fj",
    label: "Fijian",
  },
  {
    value: "fil",
    label: "Filipino",
  },
  {
    value: "fil-PH",
    label: "Filipino, Philippines",
  },
  {
    value: "fi",
    label: "Finnish",
  },
  {
    value: "fi-FI",
    label: "Finnish, Finland",
  },
  {
    value: "fiu",
    label: "Finno-Ugrian Language",
  },
  {
    value: "fon",
    label: "Fon",
  },
  {
    value: "cpf",
    label: "French-based Creole or Pidgin",
  },
  {
    value: "fr-BE",
    label: "French, Belgium",
  },
  {
    value: "fr-CA",
    label: "French, Canada",
  },
  {
    value: "fr-FR",
    label: "French, France",
  },
  {
    value: "fr-LU",
    label: "French, Luxembourg",
  },
  {
    value: "fr-MC",
    label: "French, Monaco",
  },
  {
    value: "fr-SN",
    label: "French, Senegal",
  },
  {
    value: "fr-CH",
    label: "French, Switzerland",
  },
  {
    value: "fr-MA",
    label: "French, Morocco",
  },
  {
    value: "fr-DZ",
    label: "French, Algiers",
  },
  {
    value: "fr-GP",
    label: "French, Guadeloupe",
  },
  {
    value: "fr-MQ",
    label: "French, Martinique",
  },
  {
    value: "fr-GF",
    label: "French, Guiana",
  },
  {
    value: "fr-RE",
    label: "French, Réunion",
  },
  {
    value: "fur",
    label: "Friulian",
  },
  {
    value: "fur-IT",
    label: "Friulian, Italy",
  },
  {
    value: "ff",
    label: "Fulah",
  },
  {
    value: "ff-Arab",
    label: "Fulah(Perso-Arabic)",
  },
  {
    value: "ff-Latn",
    label: "Fulah(Latin)",
  },
  {
    value: "gaa",
    label: "Ga",
  },
  {
    value: "gd",
    label: "Scottish Gaelic",
  },
  {
    value: "gaa-GH",
    label: "Ga, Ghana",
  },
  {
    value: "gl",
    label: "Galician",
  },
  {
    value: "gl-ES",
    label: "Galician, Spain",
  },
  {
    value: "lg",
    label: "Ganda",
  },
  {
    value: "gay",
    label: "Gayo",
  },
  {
    value: "gba",
    label: "Gbaya",
  },
  {
    value: "gez",
    label: "Geez",
  },
  {
    value: "gez-ER",
    label: "Geez, Eritrea",
  },
  {
    value: "gez-ET",
    label: "Geez, Ethiopia",
  },
  {
    value: "ka",
    label: "Georgian",
  },
  {
    value: "ka-GE",
    label: "Georgian, Georgia",
  },
  {
    value: "de-AT",
    label: "German, Austria",
  },
  {
    value: "de-BE",
    label: "German, Belgium",
  },
  {
    value: "de-DE",
    label: "German, Germany",
  },
  {
    value: "gem",
    label: "Germanic Language",
  },
  {
    value: "de-LI",
    label: "German, Liechtenstein",
  },
  {
    value: "de-LU",
    label: "German, Luxembourg",
  },
  {
    value: "de-CH",
    label: "German, Switzerland",
  },
  {
    value: "gil",
    label: "Gilbertese",
  },
  {
    value: "gon",
    label: "Gondi",
  },
  {
    value: "gor",
    label: "Gorontalo",
  },
  {
    value: "grb",
    label: "Grebo",
  },
  {
    value: "el",
    label: "Greek",
  },
  {
    value: "el-CY",
    label: "Greek, Cyprus",
  },
  {
    value: "el-GR",
    label: "Greek, Greece",
  },
  {
    value: "gn",
    label: "Guarani",
  },
  {
    value: "gu",
    label: "Gujarati",
  },
  {
    value: "gu-IN",
    label: "Gujarati, India",
  },
  {
    value: "gwi",
    label: "Gwichʼin",
  },
  {
    value: "hai",
    label: "Haida",
  },
  {
    value: "ht",
    label: "Haitian",
  },
  {
    value: "ht-HT",
    label: "Haitian Creole",
  },
  {
    value: "ha",
    label: "Hausa",
  },
  {
    value: "ha-Arab",
    label: "Hausa(Perso-Arabic)",
  },
  {
    value: "ha-GH",
    label: "Hausa, Ghana",
  },
  {
    value: "ha-GH-Latn",
    label: "Hausa, Ghana(Latin)-Latn",
  },
  {
    value: "ha-Latn",
    label: "Hausa(Latin)",
  },
  {
    value: "ha-NE",
    label: "Hausa, Niger",
  },
  {
    value: "ha-NG",
    label: "Hausa, Nigeria",
  },
  {
    value: "ha-NG-Arab",
    label: "Hausa, Nigeria(Perso-Arabic)-Arab",
  },
  {
    value: "ha-NG-Latn",
    label: "Hausa, Nigeria(Latin)-Latn",
  },
  {
    value: "ha-NE-Latn",
    label: "Hausa, Niger(Latin)-Latn",
  },
  {
    value: "ha-SD",
    label: "Hausa, Sudan",
  },
  {
    value: "ha-SD-Arab",
    label: "Hausa, Sudan(Perso-Arabic)-Arab",
  },
  {
    value: "haw",
    label: "Hawaiian",
  },
  {
    value: "haw-US",
    label: "Hawaiian, United States",
  },
  {
    value: "he",
    label: "Hebrew",
  },
  {
    value: "he-Hebr",
    label: "Hebrew(Hebrew)",
  },
  {
    value: "he-IL",
    label: "Hebrew, Israel",
  },
  {
    value: "hz",
    label: "Herero",
  },
  {
    value: "hil",
    label: "Hiligaynon",
  },
  {
    value: "him",
    label: "Himachali",
  },
  {
    value: "hi",
    label: "Hindi",
  },
  {
    value: "hi-IN",
    label: "Hindi, India",
  },
  {
    value: "ho",
    label: "Hiri Motu",
  },
  {
    value: "hit",
    label: "Hittite",
  },
  {
    value: "hmn",
    label: "Hmong",
  },
  {
    value: "hu",
    label: "Hungarian",
  },
  {
    value: "hu-HU",
    label: "Hungarian, Hungary",
  },
  {
    value: "hup",
    label: "Hupa",
  },
  {
    value: "iba",
    label: "Iban",
  },
  {
    value: "is",
    label: "Icelandic",
  },
  {
    value: "is-IS",
    label: "Icelandic, Iceland",
  },
  {
    value: "io",
    label: "Ido",
  },
  {
    value: "ig",
    label: "Igbo",
  },
  {
    value: "ig-NG",
    label: "Igbo, Nigeria",
  },
  {
    value: "ijo",
    label: "Ijo",
  },
  {
    value: "ilo",
    label: "Iloko",
  },
  {
    value: "smn",
    label: "Inari Sami",
  },
  {
    value: "inc",
    label: "Indic Language",
  },
  {
    value: "ine",
    label: "Indo-European Language",
  },
  {
    value: "id",
    label: "Indonesian",
  },
  {
    value: "id-Arab",
    label: "Indonesian(Perso-Arabic)",
  },
  {
    value: "id-ID",
    label: "Indonesian, Indonesia",
  },
  {
    value: "id-ID-Arab",
    label: "Indonesian, Indonesia(Perso-Arabic)-Arab",
  },
  {
    value: "inh",
    label: "Ingush",
  },
  {
    value: "ia",
    label: "Interlingua",
  },
  {
    value: "ie",
    label: "Interlingue",
  },
  {
    value: "iu",
    label: "Inuktitut",
  },
  {
    value: "iu-CA",
    label: "Inuktitut, Canada",
  },
  {
    value: "ik",
    label: "Inupiaq",
  },
  {
    value: "ira",
    label: "Iranian Language",
  },
  {
    value: "ga",
    label: "Irish",
  },
  {
    value: "ga-IE",
    label: "Irish, Ireland",
  },
  {
    value: "iro",
    label: "Iroquoian Language",
  },
  {
    value: "it",
    label: "Italian",
  },
  {
    value: "it-IT",
    label: "Italian, Italy",
  },
  {
    value: "it-CH",
    label: "Italian, Switzerland",
  },
  {
    value: "ja",
    label: "Japanese",
  },
  {
    value: "ja-JP",
    label: "Japanese, Japan",
  },
  {
    value: "jv",
    label: "Javanese",
  },
  {
    value: "jv-Java",
    label: "Javanese(Javanese)",
  },
  {
    value: "jv-Latn",
    label: "Javanese(Latin)",
  },
  {
    value: "jrb",
    label: "Judeo-Arabic",
  },
  {
    value: "jpr",
    label: "Judeo-Persian",
  },
  {
    value: "kbd",
    label: "Kabardian",
  },
  {
    value: "kab",
    label: "Kabyle",
  },
  {
    value: "kac",
    label: "Kachin",
  },
  {
    value: "kl",
    label: "Kalaallisut",
  },
  {
    value: "kl-GL",
    label: "Kalaallisut, Greenland",
  },
  {
    value: "xal",
    label: "Kalmyk",
  },
  {
    value: "xal-Cyrl",
    label: "Kalmyk(Cyrillic)",
  },
  {
    value: "xal-Mong",
    label: "Kalmyk(Mongolian)",
  },
  {
    value: "kam",
    label: "Kamba",
  },
  {
    value: "kam-KE",
    label: "Kamba, Kenya",
  },
  {
    value: "kn",
    label: "Kannada",
  },
  {
    value: "kn-IN",
    label: "Kannada, India",
  },
  {
    value: "kr",
    label: "Kanuri",
  },
  {
    value: "krc",
    label: "Karachay-Balkar",
  },
  {
    value: "kaa",
    label: "Kara-Kalpak",
  },
  {
    value: "krl",
    label: "Karelian",
  },
  {
    value: "kar",
    label: "Karen",
  },
  {
    value: "ks",
    label: "Kashmiri",
  },
  {
    value: "ks-Arab",
    label: "Kashmiri(Perso-Arabic)",
  },
  {
    value: "ks-Deva",
    label: "Kashmiri(Devanagari)",
  },
  {
    value: "ks-Latn",
    label: "Kashmiri(Latin)",
  },
  {
    value: "csb",
    label: "Kashubian",
  },
  {
    value: "kaw",
    label: "Kawi",
  },
  {
    value: "kk",
    label: "Kazakh",
  },
  {
    value: "kk-Arab",
    label: "Kazakh(Perso-Arabic)",
  },
  {
    value: "kk-Cyrl",
    label: "Kazakh(Cyrillic)",
  },
  {
    value: "kk-KZ",
    label: "Kazakh, Kazakhstan",
  },
  {
    value: "kk-KZ-Arab",
    label: "Kazakh, Kazakhstan(Perso-Arabic)-Arab",
  },
  {
    value: "kk-KZ-Cyrl",
    label: "Kazakh, Kazakhstan(Cyrillic)-Cyrl",
  },
  {
    value: "kk-KZ-Latn",
    label: "Kazakh, Kazakhstan(Latin)-Latn",
  },
  {
    value: "kk-Latn",
    label: "Kazakh(Latin)",
  },
  {
    value: "kha",
    label: "Khasi",
  },
  {
    value: "khi",
    label: "Khoisan Language",
  },
  {
    value: "kho",
    label: "Khotanese",
  },
  {
    value: "ki",
    label: "Kikuyu",
  },
  {
    value: "kmb",
    label: "Kimbundu",
  },
  {
    value: "rw",
    label: "Kinyarwanda",
  },
  {
    value: "rw-RW",
    label: "Kinyarwanda, Rwanda",
  },
  {
    value: "ky-Cyrl",
    label: "Kirghiz(Cyrillic)",
  },
  {
    value: "ky",
    label: "Kirghiz",
  },
  {
    value: "ky-Arab",
    label: "Kirghiz(Perso-Arabic)",
  },
  {
    value: "ky-KG",
    label: "Kirghiz, Kyrgyzstan",
  },
  {
    value: "ky-Latn",
    label: "Kirghiz(Latin)",
  },
  {
    value: "tlh",
    label: "Klingon",
  },
  {
    value: "kv",
    label: "Komi",
  },
  {
    value: "kg",
    label: "Kongo",
  },
  {
    value: "kok",
    label: "Konkani",
  },
  {
    value: "kok-IN",
    label: "Konkani, India",
  },
  {
    value: "kok-IN-Knda",
    label: "Konkani, India(Kannada)-Knda",
  },
  {
    value: "kok-IN-Latn",
    label: "Konkani, India(Latin)-Latn",
  },
  {
    value: "kok-IN-Mlym",
    label: "Konkani, India(Malayalam)-Mlym",
  },
  {
    value: "kok-Knda",
    label: "Konkani(Kannada)",
  },
  {
    value: "kok-Latn",
    label: "Konkani(Latin)",
  },
  {
    value: "kok-Mlym",
    label: "Konkani(Malayalam)",
  },
  {
    value: "ko",
    label: "Korean",
  },
  {
    value: "ko-KR",
    label: "Korean, South Korea",
  },
  {
    value: "kfo",
    label: "Koro",
  },
  {
    value: "kfo-CI",
    label: "Koro, Ivory Coast",
  },
  {
    value: "kos",
    label: "Kosraean",
  },
  {
    value: "kpe",
    label: "Kpelle",
  },
  {
    value: "kpe-GN",
    label: "Kpelle, Guinea",
  },
  {
    value: "kpe-LR",
    label: "Kpelle, Liberia",
  },
  {
    value: "kro",
    label: "Kru",
  },
  {
    value: "kj",
    label: "Kuanyama",
  },
  {
    value: "kum",
    label: "Kumyk",
  },
  {
    value: "ku",
    label: "Kurdish",
  },
  {
    value: "ku-Arab",
    label: "Kurdish(Perso-Arabic)",
  },
  {
    value: "ku-IR",
    label: "Kurdish, Iran",
  },
  {
    value: "ku-IR-Arab",
    label: "Kurdish, Iran(Perso-Arabic)-Arab",
  },
  {
    value: "ku-IQ",
    label: "Kurdish, Iraq",
  },
  {
    value: "ku-IQ-Arab",
    label: "Kurdish, Iraq(Perso-Arabic)-Arab",
  },
  {
    value: "ku-Latn",
    label: "Kurdish(Latin)",
  },
  {
    value: "ku-SY",
    label: "Kurdish, Syria",
  },
  {
    value: "ku-SY-Arab",
    label: "Kurdish, Syria(Perso-Arabic)-Arab",
  },
  {
    value: "ku-TR",
    label: "Kurdish, Turkey",
  },
  {
    value: "ku-TR-Latn",
    label: "Kurdish, Turkey(Latin)-Latn",
  },
  {
    value: "kru",
    label: "Kurukh",
  },
  {
    value: "kut",
    label: "Kutenai",
  },
  {
    value: "lad",
    label: "Ladino",
  },
  {
    value: "lad-Hebr",
    label: "Ladino(Hebrew)",
  },
  {
    value: "lad-Latn",
    label: "Ladino(Latin)",
  },
  {
    value: "lah",
    label: "Lahnda",
  },
  {
    value: "lam",
    label: "Lamba",
  },
  {
    value: "lo",
    label: "Lao",
  },
  {
    value: "lo-LA",
    label: "Lao, Laos",
  },
  {
    value: "la",
    label: "Latin",
  },
  {
    value: "lv",
    label: "Latvian",
  },
  {
    value: "lv-LV",
    label: "Latvian, Latvia",
  },
  {
    value: "lez",
    label: "Lezghian",
  },
  {
    value: "li",
    label: "Limburgish",
  },
  {
    value: "ln",
    label: "Lingala",
  },
  {
    value: "ln-CG",
    label: "Lingala, Congo [Republic]",
  },
  {
    value: "ln-CD",
    label: "Lingala, Congo [DRC]",
  },
  {
    value: "lt",
    label: "Lithuanian",
  },
  {
    value: "lt-LT",
    label: "Lithuanian, Lithuania",
  },
  {
    value: "jbo",
    label: "Lojban",
  },
  {
    value: "dsb",
    label: "Lower Sorbian",
  },
  {
    value: "nds",
    label: "Low German",
  },
  {
    value: "nds-DE",
    label: "Low German, Germany",
  },
  {
    value: "loz",
    label: "Lozi",
  },
  {
    value: "lu",
    label: "Luba-Katanga",
  },
  {
    value: "lua",
    label: "Luba-Lulua",
  },
  {
    value: "lui",
    label: "Luiseno",
  },
  {
    value: "smj",
    label: "Lule Sami",
  },
  {
    value: "lun",
    label: "Lunda",
  },
  {
    value: "luo",
    label: "Luo",
  },
  {
    value: "lus",
    label: "Lushai",
  },
  {
    value: "lb",
    label: "Luxembourgish",
  },
  {
    value: "lb-LU",
    label: "Luxembourgish, Luxemburg",
  },
  {
    value: "mk",
    label: "Macedonian",
  },
  {
    value: "mk-MK",
    label: "Macedonian, Macedonia [FYROM]",
  },
  {
    value: "mad",
    label: "Madurese",
  },
  {
    value: "mag",
    label: "Magahi",
  },
  {
    value: "mai",
    label: "Maithili",
  },
  {
    value: "mak",
    label: "Makasar",
  },
  {
    value: "mak-Bugi",
    label: "Makasar(Buginese)",
  },
  {
    value: "mak-Latn",
    label: "Makasar(Latin)",
  },
  {
    value: "mg",
    label: "Malagasy",
  },
  {
    value: "ms",
    label: "Malay",
  },
  {
    value: "ml",
    label: "Malayalam",
  },
  {
    value: "ml-Arab",
    label: "Malayalam(Perso-Arabic)",
  },
  {
    value: "ml-IN",
    label: "Malayalam, India",
  },
  {
    value: "ml-IN-Arab",
    label: "Malayalam, India(Perso-Arabic)-Arab",
  },
  {
    value: "ml-IN-Mlym",
    label: "Malayalam, India(Malayalam)-Mlym",
  },
  {
    value: "ml-Mlym",
    label: "Malayalam(Malayalam)",
  },
  {
    value: "ms-Arab",
    label: "Malay(Perso-Arabic)",
  },
  {
    value: "ms-BN",
    label: "Malay, Brunei",
  },
  {
    value: "ms-BN-Latn",
    label: "Malay, Brunei(Latin)-Latn",
  },
  {
    value: "ms-Latn",
    label: "Malay(Latin)",
  },
  {
    value: "ms-MY",
    label: "Malay, Malaysia",
  },
  {
    value: "ms-MY-Latn",
    label: "Malay, Malaysia(Latin)-Latn",
  },
  {
    value: "mt",
    label: "Maltese",
  },
  {
    value: "mt-MT",
    label: "Maltese, Malta",
  },
  {
    value: "mnc",
    label: "Manchu",
  },
  {
    value: "mdr",
    label: "Mandar",
  },
  {
    value: "man",
    label: "Mandingo",
  },
  {
    value: "mni",
    label: "Manipuri",
  },
  {
    value: "mno",
    label: "Manobo Language",
  },
  {
    value: "gv",
    label: "Manx",
  },
  {
    value: "gv-GB",
    label: "Manx, United Kingdom",
  },
  {
    value: "mi",
    label: "Maori",
  },
  {
    value: "mr",
    label: "Marathi",
  },
  {
    value: "mr-IN",
    label: "Marathi, India",
  },
  {
    value: "chm",
    label: "Mari",
  },
  {
    value: "mh",
    label: "Marshallese",
  },
  {
    value: "mwr",
    label: "Marwari",
  },
  {
    value: "mas",
    label: "Masai",
  },
  {
    value: "myn",
    label: "Mayan Language",
  },
  {
    value: "men",
    label: "Mende",
  },
  {
    value: "mic",
    label: "Micmac",
  },
  {
    value: "min",
    label: "Minangkabau",
  },
  {
    value: "mwl",
    label: "Mirandese",
  },
  {
    value: "moh",
    label: "Mohawk",
  },
  {
    value: "mdf",
    label: "Moksha",
  },
  {
    value: "mo",
    label: "Moldavian",
  },
  {
    value: "mo-MD",
    label: "Moldavian, Moldova",
  },
  {
    value: "lol",
    label: "Mongo",
  },
  {
    value: "mn",
    label: "Mongolian",
  },
  {
    value: "mn-CN",
    label: "Mongolian, China",
  },
  {
    value: "mn-CN-Mong",
    label: "Mongolian, China(Mongolian)-Mong",
  },
  {
    value: "mn-Cyrl",
    label: "Mongolian(Cyrillic)",
  },
  {
    value: "mn-MN",
    label: "Mongolian, Mongolia",
  },
  {
    value: "mn-MN-Cyrl",
    label: "Mongolian, Mongolia(Cyrillic)-Cyrl",
  },
  {
    value: "mn-Mong",
    label: "Mongolian(Mongolian)",
  },
  {
    value: "mkh",
    label: "Mon-Khmer Language",
  },
  {
    value: "mos",
    label: "Mossi",
  },
  {
    value: "mul",
    label: "Multiple Languages",
  },
  {
    value: "mun",
    label: "Munda Language",
  },
  {
    value: "nah",
    label: "Nahuatl",
  },
  {
    value: "na",
    label: "Nauru",
  },
  {
    value: "nv",
    label: "Navajo",
  },
  {
    value: "nd",
    label: "North Ndebele",
  },
  {
    value: "nr",
    label: "South Ndebele",
  },
  {
    value: "nr-ZA",
    label: "South Ndebele, South Africa",
  },
  {
    value: "ng",
    label: "Ndonga",
  },
  {
    value: "nap",
    label: "Neapolitan",
  },
  {
    value: "new",
    label: "Newari",
  },
  {
    value: "ne",
    label: "Nepali",
  },
  {
    value: "ne-IN",
    label: "Nepali, India",
  },
  {
    value: "ne-NP",
    label: "Nepali, Nepal",
  },
  {
    value: "nia",
    label: "Nias",
  },
  {
    value: "nic",
    label: "Niger-Kordofanian Language",
  },
  {
    value: "ssa",
    label: "Nilo-Saharan Language",
  },
  {
    value: "niu",
    label: "Niuean",
  },
  {
    value: "nqo",
    label: "N’Ko",
  },
  {
    value: "nog",
    label: "Nogai",
  },
  {
    value: "zxx",
    label: "No linguistic content",
  },
  {
    value: "nai",
    label: "North American Indian Language",
  },
  {
    value: "frr",
    label: "Northern Frisian",
  },
  {
    value: "se",
    label: "Northern Sami",
  },
  {
    value: "se-FI",
    label: "Northern Sami, Finland",
  },
  {
    value: "se-NO",
    label: "Northern Sami, Norway",
  },
  {
    value: "no",
    label: "Norwegian Bokmål",
  },
  {
    value: "nb",
    label: "Norwegian Bokmål",
  },
  {
    value: "nb-NO",
    label: "Norwegian Bokmål, Norway",
  },
  {
    value: "nn",
    label: "Norwegian Nynorsk",
  },
  {
    value: "nn-NO",
    label: "Norwegian Nynorsk, Norway",
  },
  {
    value: "nub",
    label: "Nubian Language",
  },
  {
    value: "nym",
    label: "Nyamwezi",
  },
  {
    value: "nyn",
    label: "Nyankole",
  },
  {
    value: "nyo",
    label: "Nyoro",
  },
  {
    value: "nzi",
    label: "Nzima",
  },
  {
    value: "oc",
    label: "Occitan",
  },
  {
    value: "oc-FR",
    label: "Occitan, France",
  },
  {
    value: "oj",
    label: "Ojibwa",
  },
  {
    value: "or",
    label: "Oriya",
  },
  {
    value: "or-IN",
    label: "Oriya, India",
  },
  {
    value: "om",
    label: "Oromo",
  },
  {
    value: "om-ET",
    label: "Oromo, Ethiopia",
  },
  {
    value: "om-KE",
    label: "Oromo, Kenya",
  },
  {
    value: "osa",
    label: "Osage",
  },
  {
    value: "os",
    label: "Ossetic",
  },
  {
    value: "os-Cyrl",
    label: "Ossetic(Cyrillic)",
  },
  {
    value: "os-Latn",
    label: "Ossetic(Latin)",
  },
  {
    value: "oto",
    label: "Otomian Language",
  },
  {
    value: "pal",
    label: "Pahlavi",
  },
  {
    value: "pau",
    label: "Palauan",
  },
  {
    value: "pi",
    label: "Pali",
  },
  {
    value: "pi-Deva",
    label: "Pali(Devanagari)",
  },
  {
    value: "pi-Sinh",
    label: "Pali(Sinhala)",
  },
  {
    value: "pi-Thai",
    label: "Pali(Thai)",
  },
  {
    value: "pam",
    label: "Pampanga",
  },
  {
    value: "pam-IN",
    label: "Pampanga, India",
  },
  {
    value: "pag",
    label: "Pangasinan",
  },
  {
    value: "pa-Arab",
    label: "Punjabi(Perso-Arabic)",
  },
  {
    value: "pa-Deva",
    label: "Punjabi(Devanagari)",
  },
  {
    value: "pa-Guru",
    label: "Punjabi(Gurmukhi)",
  },
  {
    value: "pa-IN-Deva",
    label: "Punjabi, India(Devanagari)-Deva",
  },
  {
    value: "pa-IN-Guru",
    label: "Punjabi, India(Gurmukhi)-Guru",
  },
  {
    value: "pa-PK-Arab",
    label: "Punjabi, Pakistan(Perso-Arabic)-Arab",
  },
  {
    value: "pa-PK-Deva",
    label: "Punjabi, Pakistan(Devanagari)-Deva",
  },
  {
    value: "pap",
    label: "Papiamento",
  },
  {
    value: "paa",
    label: "Papuan Language",
  },
  {
    value: "nso",
    label: "Northern Sotho",
  },
  {
    value: "nso-ZA",
    label: "Northern Sotho, South Africa",
  },
  {
    value: "fa",
    label: "Persian",
  },
  {
    value: "fa-AF",
    label: "Persian, Afghanistan",
  },
  {
    value: "fa-Arab",
    label: "Persian(Perso-Arabic)",
  },
  {
    value: "fa-Cyrl",
    label: "Persian(Cyrillic)",
  },
  {
    value: "fa-IR",
    label: "Persian, Iran",
  },
  {
    value: "phi",
    label: "Philippine Language",
  },
  {
    value: "pon",
    label: "Pohnpeian",
  },
  {
    value: "pl",
    label: "Polish",
  },
  {
    value: "pl-PL",
    label: "Polish, Poland",
  },
  {
    value: "cpp",
    label: "Portuguese-based Creole or Pidgin",
  },
  {
    value: "pt-BR",
    label: "Portuguese, Brazil",
  },
  {
    value: "pt-PT",
    label: "Portuguese, Portugal",
  },
  {
    value: "pra",
    label: "Prakrit Language",
  },
  {
    value: "pa",
    label: "Punjabi",
  },
  {
    value: "pa-PK",
    label: "Punjabi, Pakistan",
  },
  {
    value: "ps",
    label: "Pushto",
  },
  {
    value: "ps-AF",
    label: "Pushto, Afghanistan",
  },
  {
    value: "ps-Arab",
    label: "Pushto(Perso-Arabic)",
  },
  {
    value: "qu",
    label: "Quechua",
  },
  {
    value: "qu-PE",
    label: "Quechua, Peru",
  },
  {
    value: "raj",
    label: "Rajasthani",
  },
  {
    value: "raj-Arab",
    label: "Rajasthani(Perso-Arabic)",
  },
  {
    value: "raj-Deva",
    label: "Rajasthani(Devanagari)",
  },
  {
    value: "rap",
    label: "Rapanui",
  },
  {
    value: "rar",
    label: "Rarotongan",
  },
  {
    value: "roa",
    label: "Romance Language",
  },
  {
    value: "ro",
    label: "Romanian",
  },
  {
    value: "ro-MD",
    label: "Romanian, Moldova",
  },
  {
    value: "ro-RO",
    label: "Romanian, Romania",
  },
  {
    value: "rm",
    label: "Romansh",
  },
  {
    value: "rom",
    label: "Romany",
  },
  {
    value: "rn",
    label: "Rundi",
  },
  {
    value: "ru-RU",
    label: "Russian, Russia",
  },
  {
    value: "ru-UA",
    label: "Russian, Ukraine",
  },
  {
    value: "ru-KZ",
    label: "Russian, Kazakhstan",
  },
  {
    value: "sal",
    label: "Salishan Language",
  },
  {
    value: "sam",
    label: "Samaritan Aramaic",
  },
  {
    value: "sam-Syrc",
    label: "Samaritan Aramaic(Syriac)",
  },
  {
    value: "smi",
    label: "Sami Language",
  },
  {
    value: "sm",
    label: "Samoan",
  },
  {
    value: "sad",
    label: "Sandawe",
  },
  {
    value: "sg",
    label: "Sango",
  },
  {
    value: "sa",
    label: "Sanskrit",
  },
  {
    value: "sa-IN",
    label: "Sanskrit, India",
  },
  {
    value: "sat",
    label: "Santali",
  },
  {
    value: "sat-Beng",
    label: "Santali(Bengali)",
  },
  {
    value: "sat-Deva",
    label: "Santali(Devanagari)",
  },
  {
    value: "sat-Latn",
    label: "Santali(Latin)",
  },
  {
    value: "sat-Orya",
    label: "Santali(Oriya)",
  },
  {
    value: "sc",
    label: "Sardinian",
  },
  {
    value: "sas",
    label: "Sasak",
  },
  {
    value: "sco",
    label: "Scots",
  },
  {
    value: "sel",
    label: "Selkup",
  },
  {
    value: "sem",
    label: "Semitic Language",
  },
  {
    value: "sr",
    label: "Serbian",
  },
  {
    value: "sr-BA",
    label: "Serbian, Bosnia and Herzegovina",
  },
  {
    value: "sr-BA-Cyrl",
    label: "Serbian, Bosnia and Herzegovina(Cyrillic)-Cyrl",
  },
  {
    value: "sr-BA-Latn",
    label: "Serbian, Bosnia and Herzegovina(Latin)-Latn",
  },
  {
    value: "sr-Cyrl",
    label: "Serbian(Cyrillic)",
  },
  {
    value: "sr-Latn",
    label: "Serbian(Latin)",
  },
  {
    value: "sr-ME",
    label: "Serbian, Montenegro",
  },
  {
    value: "sr-ME-Cyrl",
    label: "Serbian, Montenegro(Cyrillic)-Cyrl",
  },
  {
    value: "sr-ME-Latn",
    label: "Serbian, Montenegro(Latin)-Latn",
  },
  {
    value: "sr-RS",
    label: "Serbian, Serbia",
  },
  {
    value: "sr-CS",
    label: "Serbian, Serbia and Montenegro",
  },
  {
    value: "sr-CS-Cyrl",
    label: "Serbian, Serbia and Montenegro(Cyrillic)-Cyrl",
  },
  {
    value: "sr-CS-Latn",
    label: "Serbian, Serbia and Montenegro(Latin)-Latn",
  },
  {
    value: "sr-RS-Cyrl",
    label: "Serbian, Serbia(Cyrillic)-Cyrl",
  },
  {
    value: "sr-RS-Latn",
    label: "Serbian, Serbia(Latin)-Latn",
  },
  {
    value: "sh",
    label: "Serbo-Croatian",
  },
  {
    value: "sh-BA",
    label: "Serbo-Croatian, Bosnia and Herzegovina",
  },
  {
    value: "sh-ME",
    label: "Serbo-Croatian, Montenegro",
  },
  {
    value: "sh-CS",
    label: "Serbo-Croatian, Serbia and Montenegro",
  },
  {
    value: "srr",
    label: "Serer",
  },
  {
    value: "srr-Arab",
    label: "Serer(Perso-Arabic)",
  },
  {
    value: "srr-Latn",
    label: "Serer(Latin)",
  },
  {
    value: "shn",
    label: "Shan",
  },
  {
    value: "sn",
    label: "Shona",
  },
  {
    value: "ii",
    label: "Sichuan Yi",
  },
  {
    value: "ii-CN",
    label: "Sichuan Yi, China",
  },
  {
    value: "ii-CN-Yiii",
    label: "Sichuan Yi, China(Yi)-Yiii",
  },
  {
    value: "ii-Yiii",
    label: "Sichuan Yi(Yi)",
  },
  {
    value: "scn",
    label: "Sicilian",
  },
  {
    value: "sid",
    label: "Sidamo",
  },
  {
    value: "sid-ET",
    label: "Sidamo, Ethiopia",
  },
  {
    value: "sid-Ethi",
    label: "Sidamo(Ethiopic)",
  },
  {
    value: "sid-Latn",
    label: "Sidamo(Latin)",
  },
  {
    value: "sgn",
    label: "Sign Language",
  },
  {
    value: "bla",
    label: "Siksika",
  },
  {
    value: "sd",
    label: "Sindhi",
  },
  {
    value: "sd-Arab",
    label: "Sindhi(Perso-Arabic)",
  },
  {
    value: "sd-Deva",
    label: "Sindhi(Devanagari)",
  },
  {
    value: "sd-Guru",
    label: "Sindhi(Gurmukhi)",
  },
  {
    value: "si",
    label: "Sinhala",
  },
  {
    value: "si-LK",
    label: "Sinhala, Sri Lanka",
  },
  {
    value: "sit",
    label: "Sino-Tibetan Language",
  },
  {
    value: "sio",
    label: "Siouan Language",
  },
  {
    value: "sms",
    label: "Skolt Sami",
  },
  {
    value: "den",
    label: "Slave",
  },
  {
    value: "sla",
    label: "Slavic Language",
  },
  {
    value: "sk",
    label: "Slovak",
  },
  {
    value: "sk-SK",
    label: "Slovak, Slovakia",
  },
  {
    value: "sl",
    label: "Slovenian",
  },
  {
    value: "sl-SI",
    label: "Slovenian, Slovenia",
  },
  {
    value: "sog",
    label: "Sogdien",
  },
  {
    value: "so",
    label: "Somali",
  },
  {
    value: "so-Arab",
    label: "Somali(Perso-Arabic)",
  },
  {
    value: "so-DJ",
    label: "Somali, Djibouti",
  },
  {
    value: "so-ET",
    label: "Somali, Ethiopia",
  },
  {
    value: "so-KE",
    label: "Somali, Kenya",
  },
  {
    value: "so-SO",
    label: "Somali, Somalia",
  },
  {
    value: "son",
    label: "Songhai",
  },
  {
    value: "snk",
    label: "Soninke",
  },
  {
    value: "snk-Arab",
    label: "Soninke(Perso-Arabic)",
  },
  {
    value: "snk-Latn",
    label: "Soninke(Latin)",
  },
  {
    value: "wen",
    label: "Sorbian Language",
  },
  {
    value: "st",
    label: "Southern Sotho",
  },
  {
    value: "st-LS",
    label: "Southern Sotho, Lesotho",
  },
  {
    value: "st-ZA",
    label: "Southern Sotho, South Africa",
  },
  {
    value: "sai",
    label: "South American Indian Language",
  },
  {
    value: "alt",
    label: "Southern Altai",
  },
  {
    value: "sma",
    label: "Southern Sami",
  },
  {
    value: "es-419",
    label: "Spanish, Latin America",
  },
  {
    value: "es-AR",
    label: "Spanish, Argentina",
  },
  {
    value: "es-BO",
    label: "Spanish, Bolivia",
  },
  {
    value: "es-CL",
    label: "Spanish, Chile",
  },
  {
    value: "es-CO",
    label: "Spanish, Colombia",
  },
  {
    value: "es-CR",
    label: "Spanish, Costa Rica",
  },
  {
    value: "es-DO",
    label: "Spanish, Dominican Republic",
  },
  {
    value: "es-EC",
    label: "Spanish, Ecuador",
  },
  {
    value: "es-ES",
    label: "Spanish, Spain",
  },
  {
    value: "es-GT",
    label: "Spanish, Guatemala",
  },
  {
    value: "es-HN",
    label: "Spanish, Honduras",
  },
  {
    value: "es-IC",
    label: "Spanish, Canary Islands",
  },
  {
    value: "es-MX",
    label: "Spanish, Mexico",
  },
  {
    value: "es-NI",
    label: "Spanish, Nicaragua",
  },
  {
    value: "es-PA",
    label: "Spanish, Panama",
  },
  {
    value: "es-PE",
    label: "Spanish, Peru",
  },
  {
    value: "es-PR",
    label: "Spanish, Puerto Rico",
  },
  {
    value: "es-PY",
    label: "Spanish, Paraguay",
  },
  {
    value: "es-SV",
    label: "Spanish, El Salvador",
  },
  {
    value: "es-US",
    label: "Spanish, United States",
  },
  {
    value: "es-UY",
    label: "Spanish, Uruguay",
  },
  {
    value: "es-VE",
    label: "Spanish, Venezuela",
  },
  {
    value: "srn",
    label: "Sranan Tongo",
  },
  {
    value: "suk",
    label: "Sukuma",
  },
  {
    value: "sux",
    label: "Sumerian",
  },
  {
    value: "su",
    label: "Sundanese",
  },
  {
    value: "su-Arab",
    label: "Sundanese(Perso-Arabic)",
  },
  {
    value: "su-Java",
    label: "Sundanese(Javanese)",
  },
  {
    value: "su-Latn",
    label: "Sundanese(Latin)",
  },
  {
    value: "sus",
    label: "Susu",
  },
  {
    value: "sus-Arab",
    label: "Susu(Perso-Arabic)",
  },
  {
    value: "sus-Latn",
    label: "Susu(Latin)",
  },
  {
    value: "sw",
    label: "Swahili",
  },
  {
    value: "sw-KE",
    label: "Swahili, Kenya",
  },
  {
    value: "sw-TZ",
    label: "Swahili, Tanzania",
  },
  {
    value: "ss",
    label: "Swati",
  },
  {
    value: "ss-ZA",
    label: "Swati, South Africa",
  },
  {
    value: "ss-SZ",
    label: "Swati, Swaziland",
  },
  {
    value: "sv",
    label: "Swedish",
  },
  {
    value: "sv-FI",
    label: "Swedish, Finland",
  },
  {
    value: "sv-SE",
    label: "Swedish, Sweden",
  },
  {
    value: "gsw",
    label: "Swiss German",
  },
  {
    value: "gsw-CH",
    label: "Swiss German, Switzerland",
  },
  {
    value: "syr",
    label: "Syriac",
  },
  {
    value: "syr-Cyrl",
    label: "Syriac(Cyrillic)",
  },
  {
    value: "syr-SY",
    label: "Syriac, Syria",
  },
  {
    value: "syr-Syrc",
    label: "Syriac(Syriac)",
  },
  {
    value: "syr-SY-Cyrl",
    label: "Syriac, Syria(Cyrillic)-Cyrl",
  },
  {
    value: "tl",
    label: "Tagalog",
  },
  {
    value: "ty",
    label: "Tahitian",
  },
  {
    value: "tai",
    label: "Tai Language",
  },
  {
    value: "tg",
    label: "Tajik",
  },
  {
    value: "tg-Arab",
    label: "Tajik(Perso-Arabic)",
  },
  {
    value: "tg-Cyrl",
    label: "Tajik(Cyrillic)",
  },
  {
    value: "tg-Latn",
    label: "Tajik(Latin)",
  },
  {
    value: "tg-TJ",
    label: "Tajik, Tajikistan",
  },
  {
    value: "tg-TJ-Arab",
    label: "Tajik, Tajikistan(Perso-Arabic)-Arab",
  },
  {
    value: "tg-TJ-Cyrl",
    label: "Tajik, Tajikistan(Cyrillic)-Cyrl",
  },
  {
    value: "tg-TJ-Latn",
    label: "Tajik, Tajikistan(Latin)-Latn",
  },
  {
    value: "tmh",
    label: "Tamashek",
  },
  {
    value: "tmh-Arab",
    label: "Tamashek(Perso-Arabic)",
  },
  {
    value: "tmh-Latn",
    label: "Tamashek(Latin)",
  },
  {
    value: "tmh-Tfng",
    label: "Tamashek(Tifinagh)",
  },
  {
    value: "ta",
    label: "Tamil",
  },
  {
    value: "ta-IN",
    label: "Tamil, India",
  },
  {
    value: "tt",
    label: "Tatar",
  },
  {
    value: "tt-Cyrl",
    label: "Tatar(Cyrillic)",
  },
  {
    value: "tt-Latn",
    label: "Tatar(Latin)",
  },
  {
    value: "tt-RU",
    label: "Tatar, Russia",
  },
  {
    value: "tt-RU-Cyrl",
    label: "Tatar, Russia(Cyrillic)-Cyrl",
  },
  {
    value: "tt-RU-Latn",
    label: "Tatar, Russia(Latin)-Latn",
  },
  {
    value: "te",
    label: "Telugu",
  },
  {
    value: "te-IN",
    label: "Telugu, India",
  },
  {
    value: "ter",
    label: "Tereno",
  },
  {
    value: "tet",
    label: "Tetum",
  },
  {
    value: "th",
    label: "Thai",
  },
  {
    value: "th-TH",
    label: "Thai, Thailand",
  },
  {
    value: "bo",
    label: "Tibetan",
  },
  {
    value: "bo-CN",
    label: "Tibetan, China",
  },
  {
    value: "bo-IN",
    label: "Tibetan, India",
  },
  {
    value: "tig",
    label: "Tigre",
  },
  {
    value: "tig-ER",
    label: "Tigre, Eritrea",
  },
  {
    value: "ti",
    label: "Tigrinya",
  },
  {
    value: "ti-ER",
    label: "Tigrinya, Eritrea",
  },
  {
    value: "ti-ET",
    label: "Tigrinya, Ethiopia",
  },
  {
    value: "tem",
    label: "Timne",
  },
  {
    value: "tiv",
    label: "Tiv",
  },
  {
    value: "tli",
    label: "Tlingit",
  },
  {
    value: "tkl",
    label: "Tokelau",
  },
  {
    value: "tpi",
    label: "Tok Pisin",
  },
  {
    value: "tog",
    label: "Nyasa Tonga",
  },
  {
    value: "tog-TO",
    label: "Nyasa Tonga, Tonga",
  },
  {
    value: "to",
    label: "Tonga",
  },
  {
    value: "tsi",
    label: "Tsimshian",
  },
  {
    value: "tsi-ZA",
    label: "Tsimshian, South Africa",
  },
  {
    value: "ts",
    label: "Tsonga",
  },
  {
    value: "tn",
    label: "Tswana",
  },
  {
    value: "tn-ZA",
    label: "Tswana, South Africa",
  },
  {
    value: "tum",
    label: "Tumbuka",
  },
  {
    value: "tup",
    label: "Tupi Language",
  },
  {
    value: "tr",
    label: "Turkish",
  },
  {
    value: "tr-TR",
    label: "Turkish, Turkey",
  },
  {
    value: "tk",
    label: "Turkmen",
  },
  {
    value: "tk-Arab",
    label: "Turkmen(Perso-Arabic)",
  },
  {
    value: "tk-Cyrl",
    label: "Turkmen(Cyrillic)",
  },
  {
    value: "tk-Latn",
    label: "Turkmen(Latin)",
  },
  {
    value: "tvl",
    label: "Tuvalu",
  },
  {
    value: "tyv",
    label: "Tuvinian",
  },
  {
    value: "tw",
    label: "Twi",
  },
  {
    value: "kcg",
    label: "Tyap",
  },
  {
    value: "kcg-NG",
    label: "Tyap, Nigeria",
  },
  {
    value: "udm",
    label: "Udmurt",
  },
  {
    value: "udm-Cyrl",
    label: "Udmurt(Cyrillic)",
  },
  {
    value: "udm-Latn",
    label: "Udmurt(Latin)",
  },
  {
    value: "uga",
    label: "Ugaritic",
  },
  {
    value: "ug",
    label: "Uyghur",
  },
  {
    value: "ug-Arab",
    label: "Uyghur(Perso-Arabic)",
  },
  {
    value: "ug-CN",
    label: "Uyghur, China",
  },
  {
    value: "ug-CN-Arab",
    label: "Uyghur, China(Perso-Arabic)-Arab",
  },
  {
    value: "ug-CN-Cyrl",
    label: "Uyghur, China(Cyrillic)-Cyrl",
  },
  {
    value: "ug-CN-Latn",
    label: "Uyghur, China(Latin)-Latn",
  },
  {
    value: "ug-Cyrl",
    label: "Uyghur(Cyrillic)",
  },
  {
    value: "ug-Latn",
    label: "Uyghur(Latin)",
  },
  {
    value: "uk",
    label: "Ukrainian",
  },
  {
    value: "uk-UA",
    label: "Ukrainian, Ukraine",
  },
  {
    value: "umb",
    label: "Umbundu",
  },
  {
    value: "mis",
    label: "Miscellaneous Language",
  },
  {
    value: "hsb",
    label: "Upper Sorbian",
  },
  {
    value: "ur",
    label: "Urdu",
  },
  {
    value: "ur-Arab",
    label: "Urdu(Perso-Arabic)",
  },
  {
    value: "ur-IN",
    label: "Urdu, India",
  },
  {
    value: "ur-PK",
    label: "Urdu, Pakistan",
  },
  {
    value: "uz",
    label: "Uzbek",
    flag: "uz",
  },
  {
    value: "uz-AF",
    label: "Uzbek, Afghanistan",
  },
  {
    value: "uz-AF-Arab",
    label: "Uzbek, Afghanistan(Perso-Arabic)-Arab",
  },
  {
    value: "uz-Arab",
    label: "Uzbek(Perso-Arabic)",
  },
  {
    value: "uz-Cyrl",
    label: "Uzbek(Cyrillic)",
  },
  {
    value: "uz-Latn",
    label: "Uzbek(Latin)",
    flag: "uz",
  },
  {
    value: "uz-UZ",
    label: "Uzbek, Uzbekistan",
    flag: "uz",
  },
  {
    value: "uz-UZ-Cyrl",
    label: "Uzbek, Uzbekistan(Cyrillic)-Cyrl",
    flag: "uz",
  },
  {
    value: "uz-UZ-Latn",
    label: "Uzbek, Uzbekistan(Latin)-Latn",
    flag: "uz",
  },
  {
    value: "vai",
    label: "Vai",
  },
  {
    value: "ve",
    label: "Venda",
  },
  {
    value: "ve-ZA",
    label: "Venda, South Africa",
  },
  {
    value: "vi",
    label: "Vietnamese",
  },
  {
    value: "vi-VN",
    label: "Vietnamese, Vietnam",
  },
  {
    value: "vo",
    label: "Volapük",
  },
  {
    value: "vot",
    label: "Votic",
  },
  {
    value: "wak",
    label: "Wakashan Language",
  },
  {
    value: "wal",
    label: "Walamo",
  },
  {
    value: "wal-ET",
    label: "Walamo, Ethiopia",
  },
  {
    value: "wa",
    label: "Walloon",
  },
  {
    value: "war",
    label: "Waray",
  },
  {
    value: "was",
    label: "Washo",
  },
  {
    value: "cy",
    label: "Welsh",
  },
  {
    value: "cy-GB",
    label: "Welsh, United Kingdom",
  },
  {
    value: "fy",
    label: "Western Frisian",
  },
  {
    value: "wo",
    label: "Wolof",
  },
  {
    value: "wo-Arab",
    label: "Wolof(Perso-Arabic)",
  },
  {
    value: "wo-Latn",
    label: "Wolof(Latin)",
  },
  {
    value: "wo-SN",
    label: "Wolof, Senegal",
  },
  {
    value: "wo-SN-Arab",
    label: "Wolof, Senegal(Perso-Arabic)-Arab",
  },
  {
    value: "wo-SN-Latn",
    label: "Wolof, Senegal(Latin)-Latn",
  },
  {
    value: "xh",
    label: "Xhosa",
  },
  {
    value: "xh-ZA",
    label: "Xhosa, South Africa",
  },
  {
    value: "sah",
    label: "Yakut",
  },
  {
    value: "yao",
    label: "Yao",
  },
  {
    value: "yap",
    label: "Yapese",
  },
  {
    value: "yi",
    label: "Yiddish",
  },
  {
    value: "yi-Hebr",
    label: "Yiddish(Hebrew)",
  },
  {
    value: "yo",
    label: "Yoruba",
  },
  {
    value: "yo-NG",
    label: "Yoruba, Nigeria",
  },
  {
    value: "ypk",
    label: "Yupik Language",
  },
  {
    value: "znd",
    label: "Zande",
  },
  {
    value: "zap",
    label: "Zapotec",
  },
  {
    value: "zza",
    label: "Zaza",
  },
  {
    value: "zen",
    label: "Zenaga",
  },
  {
    value: "za",
    label: "Zhuang",
  },
  {
    value: "zu",
    label: "Zulu",
  },
  {
    value: "zu-ZA",
    label: "Zulu, South Africa",
  },
  {
    value: "zun",
    label: "Zuni",
  },
]
