import { createAction } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const switchOrganization = createAction(
  `${entityName.organization}/switchOrganization`
)
export const setSubscription = createAction(
  `${entityName.organization}/setSubscription`
)
export const unsetSubscription = createAction(
  `${entityName.organization}/unsetSubscription`
)
