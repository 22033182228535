import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import { IconButton, Popover } from "@material-ui/core"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import MenuItem from "@material-ui/core/MenuItem"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

import { useOpenable } from "view/hooks/application/useOpenable"
import { translationRemove } from "data/entities/translation/thunks"
import { useSnackbar } from "notistack"
import { useModal } from "view/modals/useModal"
import { ModalEnum } from "data/entities/modal"

export const TranslationSubMenu = ({ id }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const { onOpen } = useModal(ModalEnum.KeyEdit)

  const menu = useOpenable()

  const handlerEdit = useCallback(
    async (e) => {
      onOpen(e, { id })
      menu.onClose()
    },
    [id]
  )

  const handlerRemove = useCallback(async () => {
    const result = await dispatch(translationRemove(id))

    if (result.type === translationRemove.fulfilled().type) {
      enqueueSnackbar("Translation has been removed!", {
        variant: "success",
      })
    }
  }, [dispatch, id])

  return (
    <>
      <IconButton onClick={menu.onOpen} size="small">
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id="translation-submenu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={menu.open}
        onClose={menu.onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <MenuItem onClick={() => {}}> */}
        {/*  <ListItemIcon> */}
        {/*    <HistoryIcon fontSize="small" /> */}
        {/*  </ListItemIcon> */}
        {/*  History */}
        {/* </MenuItem> */}
        {/* <MenuItem onClick={() => {}}> */}
        {/*  <ListItemIcon> */}
        {/*    <FileCopyOutlinedIcon fontSize="small" /> */}
        {/*  </ListItemIcon> */}
        {/*  Duplicate ?? */}
        {/* </MenuItem> */}
        <MenuItem onClick={handlerEdit}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Edit key
        </MenuItem>

        <MenuItem onClick={handlerRemove}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Remove
        </MenuItem>
      </Popover>
    </>
  )
}
