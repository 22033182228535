import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"
import { entityName } from "data/entities/consts"
import { userCreateByUid, userGetByUid } from "data/entities/user/thunks"

import { organizationUpdate } from "data/entities/organization/thunks"
import { adapter } from "./selectors"
import {
  setSubscription,
  switchOrganization,
  unsetSubscription,
} from "./actions"

const initialState = adapter.getInitialState({
  init: false,
  current: null,
})

const setOrganizations = (state, action) => {
  const { organizations } = action.payload

  if (organizations && organizations.length) {
    adapter.addMany(state, organizations)
    state.current = organizations[0]._id
  }

  state.init = true
}

export const organizationSlice = createSlice({
  name: entityName.organization,
  initialState,
  reducers: {},
  extraReducers: {
    [userCreateByUid.fulfilled]: setOrganizations,
    [userGetByUid.fulfilled]: setOrganizations,
    [switchOrganization]: (state, action) => {
      state.current = action.payload
    },
    [setSubscription]: (state, action) => {
      adapter.updateOne(state, {
        id: state.current,
        changes: {
          subscription: {
            ...state.entities[state.current].subscription,
            ...action.payload,
          },
        },
      })
    },
    [organizationUpdate.fulfilled]: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload._id,
        changes: {
          ...action.payload,
        },
      })
    },
    [unsetSubscription]: (state) => {
      adapter.updateOne(state, {
        id: state.current,
        changes: {
          subscription: null,
        },
      })
    },
    [logout.fulfilled]: () => initialState,
  },
})
