import React, { memo } from "react"

import { Grid } from "@material-ui/core"

import { DownloadForm } from "view/forms/project/DownloadForm"
import { PageHeader } from "view/uikit/common/PageHeader"
import { Box } from "view/uikit/base/Box"
import { useSelector } from "react-redux"
import { identitySelectors } from "data/entities/identity/selectors"
import { usePermission } from "view/hooks/rbac/usePermission"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  if (!permission.download.canWrite) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader title="Download" />

      <Grid container>
        <Grid item xs={4}>
          <Box>
            <DownloadForm />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export const DownloadPage = memo(Page)
