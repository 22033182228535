import styled from "styled-components"

export const PropRow = styled.div`
  display: flex;
  align-items: center;
`

export const PropName = styled.b`
  margin-right: 0.25rem;
  font-weight: 500;
`

export const PropValue = styled.div`
  margin-right: 0.5rem;
`
