import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch } from "react-redux"
import { useSnackbar } from "notistack"

import { translationUpdate } from "data/entities/translation/thunks"
import { validate } from "view/forms/translation/TranslationEditForm/validate"

export const Container = ({
  component: Component,
  lang,
  translationKey,
  search,
}) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      const result = await dispatch(translationUpdate(values))

      if (result.type === translationUpdate.fulfilled().type) {
        enqueueSnackbar("Translation has been updated!", {
          variant: "success",
        })
      } else {
        setTimeout(reset, 0)
      }
    },
    [dispatch]
  )

  return (
    <Form
      initialValues={{
        id: translationKey.id,
        lang,
        translation: translationKey.translations[lang]?.text,
      }}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component search={search} {...props} />}
    />
  )
}
