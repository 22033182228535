import styled from "styled-components"
import MuiPopover from "@material-ui/core/Popover"
import { whiteTheme } from "view/themes/white"
import { MenuItem as MuiMenuItem } from "@material-ui/core"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  width: 25rem;
  cursor: pointer;
  border-right: 1px solid #e9ebf0;

  color: ${({ disabled }) => (disabled ? "#999" : "inherit")};
`

export const Title = styled.div`
  font-size: 1.2rem;
`

export const Popover = styled(MuiPopover)`
  z-index: ${whiteTheme.zIndex.drawer - 3} !important;

  & > div {
    width: 25rem;
  }
`

export const MenuItem = styled(MuiMenuItem)`
  background: ${({ selected }) => {
    return selected ? "#f9f9f9" : "none"
  }};
`

export const ButtonWrapper = styled.div`
  text-align: right;
  padding: 0.5rem 1rem;
`
