import { createAsyncThunk } from "@reduxjs/toolkit"
import queryString from "query-string"

import { appHistory } from "infrastructure/application/history"

import { langOptions } from "data/mocks/langOptions"
import { projectSelectors } from "data/entities/project/selectors"
import { apiService } from "infrastructure/services/ApiService"
import { entityName } from "data/entities/consts"

export const languageInit = createAsyncThunk(
  `${entityName.language}/init`,
  async (props, { rejectWithValue }) => {
    try {
      const { search, pathname } = appHistory.location
      const { lang } = queryString.parse(search)
      const langs = lang ? lang.split(",") : []

      // валидация на существующие языки ?lang=en,de,adf
      const options = langOptions.filter((l) => langs.includes(l.value))

      if (options) {
        const validLangs = options.map((o) => o.value)

        // appHistory.replace(`${pathname}?lang=${validLangs.join(",")}`)

        return validLangs
      }

      return []
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const languageCreate = createAsyncThunk(
  `${entityName.language}/create`,
  async (langCode, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id)
        return rejectWithValue(new Error("Project ID not found"))

      const result = await apiService.post(`/languages`, {
        projectId: project?.id,
        langCode,
      })

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const languageRemove = createAsyncThunk(
  `${entityName.language}/remove`,
  async (langCode, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id)
        return rejectWithValue(new Error("Project ID not found"))

      const result = await apiService.delete(
        `/languages/${langCode}?projectId=${project.id}`
      )

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
