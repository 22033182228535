import { appHistory } from "infrastructure/application/history"
import { useSnackbar } from "notistack"
import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-final-form"

import { apiService } from "infrastructure/services/ApiService"

import { projectSelectors } from "data/entities/project/selectors"
import { FileFormatEnum } from "view/forms/project/UploadForm/enums"
import { validate } from "view/forms/project/UploadForm/validate"

const initialValues = {
  format: null,
  lang: null,
  file: null,
  rewrite: false,
}

export const Container = ({ component: Component }) => {
  const project = useSelector(projectSelectors.current)
  const { enqueueSnackbar } = useSnackbar()

  const handlerSubmit = useCallback(
    async (values) => {
      const data = new FormData()

      data.append("projectId", project.id)
      data.append("lang", values.lang)
      data.append("locales", values.file)
      data.append("format", values.format)
      data.append("rewrite", values.rewrite)

      try {
        const result = await apiService.post("/import", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        if (result.data.inserted) {
          enqueueSnackbar(`Inserted ${result.data.inserted} items!`, {
            variant: "success",
          })
        }

        if (result.data.updated) {
          enqueueSnackbar(`Updated ${result.data.updated} items!`, {
            variant: "success",
          })
        }

        if (result.data.skipped) {
          enqueueSnackbar(`Skipped ${result.data.skipped} items!`, {
            variant: "success",
          })
        }
      } catch (e) {
        if (e.response?.status === 500) {
          enqueueSnackbar(`Server error!`, {
            variant: "error",
          })
        }
      }
    },
    [project]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => {
        const { form, values } = props

        if (values.file && !values.format) {
          const ext = values.file.name.split(".").pop()
          const key = Object.keys(FileFormatEnum).find(
            (k) => FileFormatEnum[k].toLowerCase() === ext.toLowerCase()
          )

          form.change("format", key ? FileFormatEnum[key] : FileFormatEnum.Yaml)
        }

        if (project?.defaultLang) {
          form.change("lang", project.defaultLang)
        }

        return <Component {...props} />
      }}
    />
  )
}
