import React, { memo, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, ButtonGroup, Typography } from "@material-ui/core"

import { appHistory } from "infrastructure/application/history"
import { apiService } from "infrastructure/services/ApiService"

import { organizationSelectors } from "data/entities/organization/selectors"
import { billingPlanSelectors } from "data/entities/billingPlans/selectors"
import { billingPlanFetchList } from "data/entities/billingPlans/thunks"
import { setSubscription } from "data/entities/organization/actions"
import { identitySelectors } from "data/entities/identity/selectors"

import { SubscribeButton } from "view/pages/billing/PlansPage/SubscribeButton"
import { usePermission } from "view/hooks/rbac/usePermission"
import { Link } from "view/uikit/base/Link"
import { paths } from "view/router/paths"

import * as S from "./styled"

const benefits = [
  {
    label: "Languages",
    value: "Unlimited",
  },
  {
    label: "Users",
    value: "Unlimited",
  },
]

const FilterEnum = {
  Month: "month",
  Annual: "annual",
}

const { Paddle } = window

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  const dispatch = useDispatch()

  const [filter, setFilter] = useState(FilterEnum.Month)

  const organization = useSelector(organizationSelectors.current)
  const identity = useSelector(identitySelectors.one)
  const currentPlan = useSelector(billingPlanSelectors.current)
  const plans = useSelector(billingPlanSelectors.selectAll)

  useEffect(() => {
    dispatch(billingPlanFetchList())
  }, [])

  useEffect(() => {
    if (!currentPlan) return
    setFilter(currentPlan.annual ? FilterEnum.Annual : FilterEnum.Month)
  }, [currentPlan])

  const openCheckout = useCallback(
    (plan) => {
      Paddle.Checkout.open({
        product: plan.subscriptionPlanId,
        passthrough: JSON.stringify({
          orgId: organization._id,
        }),
        email: identity.email,
        successCallback: async (data) => {
          dispatch(
            setSubscription({
              planId: plan.subscriptionPlanId,
              id: data.product.id,
            })
          )
          // const result = await apiService.post(
          //   `/payment/success?orgId=${organization._id}`,
          //   {
          //     planId: plan.id,
          //   }
          // )
          // console.log("result", result)
        },
      })
    },
    [identity, organization]
  )

  const upgradePlan = useCallback(
    async (plan) => {
      const response = await apiService.put(
        `/subscriptions?orgId=${organization._id}`,
        {
          subscriptionPlanId: plan.subscriptionPlanId,
        }
      )

      dispatch(
        setSubscription({
          ...response.data.subscription,
        })
      )
    },
    [identity, organization]
  )

  const redirectToBilling = useCallback(() => {
    appHistory.push(paths.billing())
  }, [])

  const filteredPlans = plans.filter((p) => p.type === filter || p.free)

  if (!permission.billing.canRead) {
    appHistory.push(paths.home())
    return null
  }

  if (!currentPlan) return null

  return (
    <>
      <S.Header>
        <Typography variant="h5">Upgrade your plan</Typography>
        <ButtonGroup size="small">
          <Button
            variant={filter === FilterEnum.Month ? "contained" : "outlined"}
            color={filter === FilterEnum.Month ? "primary" : "default"}
            onClick={() => setFilter(FilterEnum.Month)}
          >
            Month
          </Button>
          <Button
            variant={filter === FilterEnum.Annual ? "contained" : "outlined"}
            color={filter === FilterEnum.Annual ? "primary" : "default"}
            onClick={() => setFilter(FilterEnum.Annual)}
          >
            Annual
          </Button>
        </ButtonGroup>
      </S.Header>

      <S.PlanWrapper>
        {filteredPlans.map((p) => (
          <S.WrapperPlan key={p.id}>
            <S.Plan>
              <S.Name>{p.name}</S.Name>

              <S.Price>
                {p.free ? (
                  "Free forever"
                ) : (
                  <>
                    <span>${p.cost}</span> / month
                  </>
                )}
                {p.type === FilterEnum.Annual && (
                  <S.Annual>Billed annually ${p.annual}</S.Annual>
                )}
              </S.Price>

              <S.Benefits>
                <S.Benefit key="projects">
                  <S.BenefitLabel>Projects</S.BenefitLabel>
                  <S.BenefitValue>{p.limits.projects}</S.BenefitValue>
                </S.Benefit>
                <S.Benefit key="keys">
                  <S.BenefitLabel>Translation keys</S.BenefitLabel>
                  <S.BenefitValue>{p.limits.keys}</S.BenefitValue>
                </S.Benefit>
                {benefits.map((b) => (
                  <S.Benefit key={b.label}>
                    <S.BenefitLabel>{b.label}</S.BenefitLabel>
                    <S.BenefitValue>{b.value}</S.BenefitValue>
                  </S.Benefit>
                ))}
              </S.Benefits>
              <S.ButtonWrapper>
                <SubscribeButton
                  plan={p}
                  currentPlan={currentPlan}
                  openCheckout={openCheckout}
                  upgradePlan={upgradePlan}
                />
              </S.ButtonWrapper>
            </S.Plan>
          </S.WrapperPlan>
        ))}
      </S.PlanWrapper>

      <Link to={paths.billing()}>Back to billing</Link>
    </>
  )
}

export const PlansPage = memo(Page)
