import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Field } from "react-final-form"

import { FormControl } from "@material-ui/core"

import { languageSelectors } from "data/entities/language/selectors"

import { FormButtons } from "view/uikit/common/FormButtons"
import { langNameByCode } from "view/helpers/langNameByCode"
import { SelectField } from "view/uikit/formFields/SelectField"
import { Container } from "view/forms/project/DownloadForm/container"
import {
  ExportFormatEnum,
  ExportStatusEnum,
} from "view/forms/project/DownloadForm/enums"
import { FormError } from "view/uikit/common/FormError"

const Component = ({ handleSubmit, submitError, submitting }) => {
  const langs = useSelector(languageSelectors.selectAll)

  const languageOptions = useMemo(() => {
    return (
      langs.map((l) => ({
        label: langNameByCode(l.id),
        value: l.id,
      })) || []
    )
  }, [langs])

  const formatOptions = useMemo(
    () => [
      {
        label: "Plain JSON (.json)",
        value: ExportFormatEnum.JSON,
      },
      {
        label: "Nested JSON (.json)",
        value: ExportFormatEnum.JSON_NESTED,
      },
      {
        label: "CSV (.csv)",
        value: ExportFormatEnum.CSV,
      },
      {
        label: "Flutter ARB (.arb)",
        value: ExportFormatEnum.ARB,
      },
    ],
    []
  )

  const statusOptions = useMemo(
    () => [
      {
        label: "All",
        value: ExportStatusEnum.All,
      },
      {
        label: "Only translated",
        value: ExportStatusEnum.Translated,
      },
      {
        label: "Only untranslated",
        value: ExportStatusEnum.Untranslated,
      },
    ],
    []
  )

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <Field
          component={SelectField}
          name="format"
          label="Format"
          options={formatOptions}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={SelectField}
          name="lang"
          label="Language"
          options={languageOptions}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={SelectField}
          name="status"
          label="Translation status"
          options={statusOptions}
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        submitBtn={{
          disabled: submitting,
          title: "Download",
        }}
      />
    </form>
  )
}

export const DownloadForm = () => <Container component={Component} />
