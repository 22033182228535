import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"

import { ModalEnum } from "data/entities/modal"
import { inviteCreate } from "data/entities/invite/thunks"

import { useModal } from "view/modals/useModal"

import { organizationSelectors } from "data/entities/organization/selectors"
import { validate } from "view/forms/user/InviteSendForm/validate"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const { onClose } = useModal(ModalEnum.InviteSend)

  const organization = useSelector(organizationSelectors.current)

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      try {
        const result = await dispatch(inviteCreate(values))

        if (result.type === inviteCreate.fulfilled().type) {
          setTimeout(reset, 0)
          onClose()
          return {}
        }

        if (result.type === inviteCreate.rejected().type) {
          const { response } = result.payload

          if (response.status === 400) {
            if (response.data.code === "email-exist") {
              return {
                email: "Email already exist",
              }
            }
          }
          return {
            [FORM_ERROR]: "Server error",
          }
        }

        return {}
      } catch (e) {
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [organization, onClose]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
