import React from "react"

import { Button } from "@material-ui/core"

export const SubscribeButton = ({
  currentPlan,
  plan,
  openCheckout,
  upgradePlan,
}) => {
  if (currentPlan.id === plan.id)
    return (
      <Button size="large" color="default" disabled variant="contained">
        Current plan
      </Button>
    )

  if (currentPlan.id === "free")
    return (
      <Button
        size="large"
        color="primary"
        variant="contained"
        disabled={plan.id === "free"}
        onClick={() => openCheckout(plan)}
      >
        Subscribe
      </Button>
    )

  return (
    <Button
      size="large"
      color="primary"
      variant="contained"
      disabled={plan.id === "free"}
      onClick={() => upgradePlan(plan)}
    >
      Upgrade
    </Button>
  )
}
