const fontFamily = [
  "Poppins",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",")

export const base = {
  typography: {
    fontFamily,
  },
  overrides: {
    body: {
      fontFamily,
    },
    MuiButton: {
      root: {
        fontFamily,
        padding: "0.5rem 2.5rem",
        borderRadius: "0.25rem",
        textTransform: "none",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "2rem",
      },
    },
    MuiFilledInput: {
      underline: {
        "&:before": {
          borderBottom: "none !important",
        },
        "&:after": {
          borderBottom: "none !important",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        bottom: "-1rem",
        lineHeight: "1rem",
        marginLeft: "14px",
        marginRight: "14px",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: "1.5rem",
      },
    },
    MuiTabs: {
      root: {
        minHeight: "2rem",
      },
    },
    MuiTab: {
      root: {
        padding: 0,
        paddingBottom: "0.25rem",
        minWidth: "120px !important",
        minHeight: "2rem",
        textTransform: "normal",
      },
    },
    PrivateTabIndicator: {
      root: {
        width: "120px",
        height: "1px",
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: "1.5rem",
      },
    },
    MuiTypography: {
      body1: {
        fontFamily,
      },
      body2: {
        fontFamily,
      },
      h4: {
        fontSize: "1.5rem",
        marginBottom: "1rem",
      },
      h5: {
        fontFamily,
      },
    },
  },
}
