import { createAsyncThunk } from "@reduxjs/toolkit"
import firebase from "firebase/app"
import "firebase/auth"

import { entityName } from "data/entities/consts"
import { storageService } from "infrastructure/services/StorageService"

export const signUpWithGoogle = createAsyncThunk(
  `${entityName.identity}/signUpWithGoogle`,
  async (props, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.addScope("email")
      return await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const signUpWithGitHub = createAsyncThunk(
  `${entityName.identity}/signUpWithGitHub`,
  async (props, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.GithubAuthProvider()
      provider.addScope("email")
      return await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const signUpWithYahoo = createAsyncThunk(
  `${entityName.identity}/signUpWithYahoo`,
  async (props, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.OAuthProvider("yahoo.com")
      provider.addScope("email")
      return await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const signUpWithFacebook = createAsyncThunk(
  `${entityName.identity}/signUpWithFacebook`,
  async (props, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope("email")
      return await firebase.auth().signInWithPopup(provider)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const logout = createAsyncThunk(
  `${entityName.identity}/logout`,
  async (props, { rejectWithValue }) => {
    try {
      await firebase.auth().signOut()
      await storageService.removeItem("auth")
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
