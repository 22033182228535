import React from "react"
import { Field } from "react-final-form"

import { langOptions } from "data/mocks/langOptions"

import { FormControl } from "@material-ui/core"

import { AutocompleteField } from "view/uikit/formFields/AutocompleteField"
import { Container } from "view/forms/language/LanguageAddForm/container"
import { FormError } from "view/uikit/common/FormError"
import { FormButtons } from "view/uikit/common/FormButtons"

import * as S from "view/forms/language/LanguageAddForm/styled"

const Component = ({
  handleSubmit,
  submitError,
  submitting,
  form: { reset },
  cbCancel,
  excludeLangs,
}) => {
  return (
    <S.Form
      onSubmit={(e) => {
        handleSubmit(e).then(reset)
      }}
    >
      <FormControl fullWidth margin="normal">
        <Field
          component={AutocompleteField}
          name="lang"
          label="New language"
          autoFocus
          options={langOptions}
          excludeOptions={excludeLangs}
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={cbCancel}
        submitBtn={{
          disabled: submitting,
          title: "Add",
        }}
        reset={reset}
      />
    </S.Form>
  )
}

export const LanguageAddForm = ({ cbCancel }) => (
  <Container component={Component} componentProps={{ cbCancel }} />
)
