import React from "react"
import { useSelector } from "react-redux"
import { Loader } from "view/uikit/base/Loader"
import { applicationSelectors } from "data/entities/application/selectors"

export const InitLoader = ({ children }) => {
  const isLoaded = useSelector(applicationSelectors.isLoaded)

  return !isLoaded ? <Loader color="#523ddb" /> : children
}
