import styled from "styled-components"
import MuiDrawer from "@material-ui/core/Drawer"
import MuiListItemText from "@material-ui/core/ListItemText"
import MuiListItem from "@material-ui/core/ListItem"
import MuiList from "@material-ui/core/List"

import { transactionEntering, transactionLeaving } from "view/themes/utils"
import { LeftSidebar } from "view/components/application/__layouts/MainLayout/config"
import { Link as UiLink } from "view/uikit/base/Link"

export const Drawer = styled(MuiDrawer)`
  flex-shrink: 0;
  white-space: nowrap;

  width: ${({ open }) => (open ? LeftSidebar.WIDTH : LeftSidebar.SHORT_WIDTH)};
  transition: ${({ open }) =>
    open ? transactionLeaving(["width"]) : transactionEntering(["width"])};
  overflow-x: ${({ open }) => (open ? "inherit" : "hidden")};

  color: #d5d6d7;

  & > div {
    display: flex;
    justify-content: space-between;

    background: #20262b;

    width: ${({ open }) =>
      open ? LeftSidebar.WIDTH : LeftSidebar.SHORT_WIDTH};
    transition: ${({ open }) =>
      open ? transactionLeaving(["width"]) : transactionEntering(["width"])};
  }
`

export const EmptyProjects = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  .MuiIconButton-label > .MuiSvgIcon-root {
    fill: #7b68ee;
  }
`

export const Link = styled(UiLink)`
  display: flex;
  height: 4rem;
  color: #fff;
  border-bottom: 1px solid #434e52;

  &:hover {
    text-decoration: none;
    background: #2b343b;

    svg {
      fill: #fff;
    }
  }
`

export const Img = styled.div`
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  padding: 1.25rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: #d5d6d7;
  }
`

export const Text = styled.div`
  font-size: 1.6rem;
  font-weight: 100;
  padding: 1rem;
  padding-left: 0;
  line-height: 2rem;

  i {
    font-style: normal;
    color: #7b68ee;
  }
`

export const List = styled(MuiList)``

export const ListItem = styled(MuiListItem)`
  color: #d5d6d7;
  padding-left: 0;

  &:hover {
    color: #fff;
    background: #2b343b;

    svg {
      fill: #fff;
    }
  }

  &.active,
  &.active:hover {
    color: #fff;
    background: #7b68ee;

    svg {
      fill: #fff;
    }
  }
`

export const Icon = styled.div`
  flex-shrink: 0;
  width: 4rem;
  height: 1.25rem;
  text-align: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: #7c828d;
  }
`

export const ListItemText = styled(MuiListItemText)`
  font-size: 1rem;
`

export const Collapse = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7c828d;
  padding-left: 0.5rem;
  user-select: none;

  & > button {
    padding-right: 1.2rem;
  }

  svg {
    fill: #7c828d;
  }
`
