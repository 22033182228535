import { createEntityAdapter } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

export const inviteSelectors = {
  ...adapter.getSelectors((state) => state[entityName.invite]),
}
