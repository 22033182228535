import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

import { entityName } from "data/entities/consts"

export const billingPlanFetchList = createAsyncThunk(
  `${entityName.billingPlans}/fetchList`,
  async (langCode, { rejectWithValue }) => {
    try {
      const result = await apiService.get(`/plans`)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
