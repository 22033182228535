import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined"
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined"
import TranslateOutlinedIcon from "@material-ui/icons/TranslateOutlined"
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined"
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined"
import PublishIcon from "@material-ui/icons/Publish"
import GetAppIcon from "@material-ui/icons/GetApp"

import {
  closeLeftSidebar,
  openLeftSidebar,
} from "data/entities/application/thunks"
import { projectSelectors } from "data/entities/project/selectors"
import { identitySelectors } from "data/entities/identity/selectors"

import { UserInfo } from "view/components/application/UserInfo"
import { paths } from "view/router/paths"
import { ReactComponent as Logo } from "view/components/application/LeftSidebar/logo.svg"
import { usePermission } from "view/hooks/rbac/usePermission"

import * as S from "./styled"

export const LeftSidebar = ({ open }) => {
  const dispatch = useDispatch()

  const project = useSelector(projectSelectors.current)
  const role = useSelector(identitySelectors.role)

  const permission = usePermission(role)

  const mainMenu = useMemo(() => {
    const list = [
      {
        link: paths.projects(),
        label: "Projects",
        icon: <DashboardOutlinedIcon />,
      },
    ]

    if (permission.translation.canRead) {
      list.push({
        link: paths.projectItem(project?.slug),
        label: "Translations",
        icon: <TranslateOutlinedIcon />,
        skip: !project?.slug,
      })
    }

    if (permission.language.canRead) {
      list.push({
        link: paths.languages(project?.slug),
        label: "Languages",
        icon: <PublicOutlinedIcon />,
        skip: !project?.slug,
      })
    }

    if (permission.upload.canWrite) {
      list.push({
        link: paths.upload(project?.slug),
        label: "Upload",
        icon: <PublishIcon />,
        skip: !project?.slug,
      })
    }

    if (permission.download.canWrite)
      list.push({
        link: paths.download(project?.slug),
        label: "Download",
        icon: <GetAppIcon />,
        skip: !project?.slug,
      })

    if (permission.projectSettings.canRead) {
      list.push({
        link: paths.settings(project?.slug),
        label: "Settings",
        icon: <SettingsOutlinedIcon />,
        skip: !project?.slug,
      })
    }

    return list.filter((i) => !i.skip)
  }, [project, permission])

  const bottomMenu = useMemo(() => {
    return [
      // {
      //   link: paths.feedback(),
      //   label: "Feedback",
      //   icon: <HeadsetMicOutlinedIcon />,
      // },
    ]
  }, [])

  const handlerOpen = () => {
    dispatch(openLeftSidebar())
  }

  const handlerClose = () => {
    dispatch(closeLeftSidebar())
  }

  return (
    <S.Drawer variant="permanent" open={open}>
      <div>
        <S.Link to={paths.projects()}>
          <S.Img>
            <Logo />
          </S.Img>
          <S.Text>
            Locale<i>um</i>
          </S.Text>
        </S.Link>

        <S.List component="nav" aria-label="projects translates">
          {mainMenu.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
              exact
            >
              <S.Icon>{m.icon}</S.Icon>
              <S.ListItemText primary={m.label} />
            </S.ListItem>
          ))}
        </S.List>
      </div>

      <div>
        <S.List component="nav" aria-label="projects translates">
          {bottomMenu.map((m) => (
            <S.ListItem
              key={m.label}
              component={NavLink}
              to={m.link}
              activeClassName="active"
              exact
            >
              <S.Icon>{m.icon}</S.Icon>
              <S.ListItemText primary={m.label} />
            </S.ListItem>
          ))}
        </S.List>

        <UserInfo />

        <S.Collapse onClick={open ? handlerClose : handlerOpen}>
          <IconButton size="medium">
            {open ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
          </IconButton>
          Collapse
        </S.Collapse>
      </div>
    </S.Drawer>
  )
}
