import React from "react"

import { ProjectCreateModal } from "view/modals/projects/ProjectCreateModal"
import { TranslationAddModal } from "view/modals/translation/TranslationAddModal"
import { LanguageAddModal } from "view/modals/language/LanguageAddModal"
import { InviteSendModal } from "view/modals/user/InviteSendModal"
import { UpgradeModal } from "view/modals/application/UpgradeModal"
import { KeyEditModal } from "view/modals/translation/KeyEditModal"

export const RootModal = () => {
  return (
    <>
      <ProjectCreateModal />
      <TranslationAddModal />
      <LanguageAddModal />
      <InviteSendModal />
      <UpgradeModal />
      <KeyEditModal />
    </>
  )
}
