import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Route as ReactRoute, Redirect } from "react-router-dom"

import { paths } from "view/router/paths"

export const Route = ({
  component: Component,
  layout,
  layoutProps = {},
  path,
  exact = false,
  isPrivate = false,
  isIdentity = false,
}) => {
  const { isAuth } = useSelector((state) => state.identity)

  if (isIdentity && isAuth) return <Redirect to={paths.home()} />
  if (isPrivate && !isAuth) return <Redirect to={paths.login()} />

  const Layout = layout || Fragment

  return (
    <ReactRoute path={path} exact={exact}>
      <Layout {...layoutProps}>
        <Component />
      </Layout>
    </ReactRoute>
  )
}
