import React from "react"
import { withRouter } from "react-router"
import queryString from "query-string"
import { connect } from "react-redux"

import { appHistory } from "infrastructure/application/history"

import { FilterEnum, translationSetStatus } from "data/entities/translation"
import { languageSetFilter } from "data/entities/language/actions"
import { languageSelectors } from "data/entities/language/selectors"
import { translationSelectors } from "data/entities/translation/selectors"

const DEBUG = process.env.NODE_ENV === "development" && false

class Container extends React.Component {
  constructor(props) {
    super(props)
    const { dispatch, status, langFilter, location } = props

    this.searchChanges = {}

    const search = appHistory.getSearch()
    if (DEBUG) console.log("constructor", { props, search, location })

    // при загрузке страницы, приоритет у урла, если что-то есть ставим в стейт
    // кейс: переходим по ссылке с установленным фильтром или обновляем страницу с установленным фильтром
    if (search.status || search.lang) {
      if (search.status) dispatch(translationSetStatus(search.status))
      if (search.lang) dispatch(languageSetFilter(search.lang.split(",")))
    } else {
      // если урл пустой, а в стейте есть что-то ставим в урл
      // кейс: ставим фильтр переходим на другую страницу и возвращаемся, должны подтянуться выбранные фильтры
      if (status !== FilterEnum.All) {
        if (DEBUG) console.log("constructor STATE CHECK", status)
        this.searchChanges.status = status
      }

      if (langFilter.length) {
        if (DEBUG) console.log("constructor STATE CHECK", langFilter)
        this.searchChanges.lang = langFilter.join(",")
      }
    }

    // если есть изменения обновляем урл
    this.updateHistory(search)
  }

  componentDidUpdate(prevProps) {
    const { status, langFilter, location } = this.props
    const search = appHistory.getSearch()

    if (DEBUG) console.log("STATE UPDATED", { prevProps, props: this.props })

    if (location.key !== prevProps.location.key) {
      if (DEBUG) console.log(">>> TRIGGER, location", location.key)
    }

    if (status !== prevProps.status) {
      if (DEBUG) console.log(">>> TRIGGER, status")
      if (status !== search.status) {
        if (DEBUG) console.log(">>> CHANGED, status =", status)
        this.searchChanges.status =
          status !== FilterEnum.All ? status : undefined
      }
    }

    if (langFilter !== prevProps.langFilter) {
      if (DEBUG) console.log(">>> TRIGGER, langFilter = ", langFilter)

      const hashLangFilter = langFilter.slice().sort().join()
      const hashSearchLang = search.lang
        ? search.lang.split(",").sort().join(",")
        : ""

      if (hashLangFilter !== hashSearchLang) {
        if (DEBUG) console.log(">>> CHANGED, langFilter =", langFilter)
        this.searchChanges.lang = langFilter ? langFilter.join(",") : undefined
      }
    }

    // если есть изменения обновляем урл
    this.updateHistory(search)
  }

  setNewSearch(newSearch) {
    const { location } = this.props
    const filteredSearch = Object.keys(newSearch).reduce((acc, key) => {
      if (newSearch[key]) acc[key] = newSearch[key]
      return acc
    }, {})

    const stringSearch = queryString.stringify(filteredSearch, {
      encode: false,
    })

    if (DEBUG)
      console.log("HISTORY REPLACE, ", {
        newSearch,
        filteredSearch,
        stringSearch,
      })
    appHistory.replace(`${location.pathname}?${stringSearch}`)
  }

  updateHistory(search) {
    if (Object.keys(this.searchChanges).length) {
      if (DEBUG) console.log("SEARCH CHANGES", this.searchChanges)
      this.setNewSearch({
        ...search,
        ...this.searchChanges,
      })
      this.searchChanges = {}
    }
  }

  render() {
    return null
  }
}

export const SyncUrlFilterTranslationContainer = connect((state) => ({
  status: translationSelectors.status(state),
  langFilter: languageSelectors.filter(state),
}))(withRouter(Container))
