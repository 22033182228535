import { createSlice } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

const initialState = {
  initApp: {
    auth: false,
    language: false,
  },
  isLoaded: false,
  theme: "white",
  clientState: {
    leftSidebarOpen: true,
  },
}

export const applicationSlice = createSlice({
  name: entityName.application,
  initialState,
  reducers: {
    setApplicationLoaded: (state, action) => {
      state.isLoaded = true
    },
    setApplicationInit: (state, action) => {
      state.initApp = {
        ...state.initApp,
        ...action.payload,
      }
    },
    setClientState: (state, action) => {
      return {
        ...state,
        clientState: {
          ...state.clientState,
          ...action.payload,
        },
      }
    },
    leftSidebarOpened: (state) => {
      return {
        ...state,
        clientState: {
          ...state.clientState,
          leftSidebarOpen: true,
        },
      }
    },
    leftSidebarClosed: (state) => {
      return {
        ...state,
        clientState: {
          ...state.clientState,
          leftSidebarOpen: false,
        },
      }
    },
  },
  extraReducers: {},
})

export const {
  setApplicationLoaded,
  setApplicationInit,
  setClientState,
  leftSidebarOpened,
  leftSidebarClosed,
} = applicationSlice.actions
