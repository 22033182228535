import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
`
export const Drop = styled.div`
  padding: 10rem 15rem;
  border: 2px dashed #7b69ee;
  border-radius: 0.5rem;
  text-align: center;
`

export const Text = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`
