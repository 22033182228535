import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch } from "react-redux"
import { FORM_ERROR } from "final-form"
import { useSnackbar } from "notistack"

import { nameToKey } from "infrastructure/utils"

import { projectCreate } from "data/entities/project/thunks"
import { langOptions } from "data/mocks/langOptions"

import { validate } from "./validate"

const initialValues = {
  // при создании нового проекта по умолчанию подставляем EN
  defaultLang: langOptions.find((l) => l.value === "en"),
}

export const Container = ({ component: Component, componentProps }) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const handlerSubmit = useCallback(
    async (values, { reset }) => {
      try {
        const result = await dispatch(
          projectCreate({
            ...values,
            id: nameToKey(values.name),
          })
        )

        if (result.type === projectCreate.fulfilled().type) {
          setTimeout(reset, 0)
          componentProps.cbCancel()
          enqueueSnackbar("Project has been created!", {
            variant: "success",
          })
          return {}
        }

        if (result.type === projectCreate.rejected().type) {
          const { response } = result.payload

          if (response.status === 400) {
            if (
              response.data.code === "not-unique" &&
              response.data.param === "slug"
            ) {
              return {
                name: "Name already used",
              }
            }
          }
          return {
            [FORM_ERROR]: "Server error",
          }
        }

        return {}
      } catch (e) {
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [componentProps]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  )
}
