export const entityName = {
  application: "application",
  billingPlans: "billingPlans",
  identity: "identity",
  invite: "invite",
  language: "language",
  modal: "modal",
  organization: "organization",
  project: "project",
  transaction: "transaction",
  translation: "translation",
  user: "user",
}
