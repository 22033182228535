import React, { useCallback, useMemo } from "react"

import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"

import { emptyOption } from "data/mocks/emptyOption"

export const AutocompleteField = ({
  input: { onChange, ...input },
  options,
  meta,
  label,
  helperText,
  disabled,
  autoFocus,
  disableClearable,
  excludeOptions = [],
}) => {
  const errorMessage = meta.error || meta.submitError
  const validateError = errorMessage && meta.touched ? errorMessage : undefined

  const handleChange = useCallback(
    (e, newValue) => {
      if (newValue) onChange(newValue)
      else onChange(emptyOption)
    },
    [onChange]
  )

  const filterOptions = useCallback(
    (ops, { inputValue }) => {
      return ops.filter((o) => {
        if (o.value === emptyOption.value) return false
        if (excludeOptions.includes(o.value)) return false

        if (!inputValue) return true

        const search = inputValue.toLowerCase()

        if (o.value.toLowerCase().includes(search)) return true
        if (o.label.toLowerCase().includes(search)) return true

        return false
      })
    },
    [excludeOptions]
  )

  const mappedOption = useMemo(() => {
    return options.find((o) => o.value === input.value.value) || emptyOption
  }, [input.value, options])

  return (
    <Autocomplete
      id={`autocomplete-${input.name}`}
      options={options}
      value={mappedOption}
      disabled={disabled}
      onChange={handleChange}
      filterOptions={filterOptions}
      disableClearable={
        disableClearable || input.value.value === emptyOption.value
      }
      getOptionLabel={(option) =>
        !option.value ? "" : `[${option.value}] ${option.label}`
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={!!autoFocus}
          inputProps={{ ...params.inputProps }}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          name={input.name}
          label={label}
          variant="filled"
          error={!!validateError}
          helperText={validateError || helperText}
        />
      )}
    />
  )
}
