import { createEntityAdapter } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

export const projectSelectors = {
  ...adapter.getSelectors((state) => state[entityName.project]),
  loadingList: (state) => state[entityName.project].loadingList,
  current: (state) =>
    state[entityName.project].entities[state[entityName.project].current],
  countKeys: (state) => state[entityName.project].countKeys,
}
