import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-final-form"

import { useModal } from "view/modals/useModal"
import { ModalEnum } from "data/entities/modal"
import { organizationSelectors } from "data/entities/organization/selectors"
import { validate } from "view/forms/user/ChangePasswordForm/validate"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const { onClose } = useModal(ModalEnum.InviteSend)

  const organization = useSelector(organizationSelectors.current)

  const handlerSubmit = useCallback(
    async (values) => {
      try {
        // var user = firebase.auth().currentUser;
        // var newPassword = getASecureRandomPassword();
        //
        // user.updatePassword(newPassword).then(function() {
        //   // Update successful.
        // }).catch(function(error) {
        //   // An error happened.
        // });
      } catch (e) {}
    },
    [organization._id, onClose]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
