import styled from "styled-components"
import { LinearProgress as MuiLinearProgress } from "@material-ui/core"
import { palette } from "view/themes/white/palette"

export const Lang = styled.div`
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);

  &:last-child {
    margin-bottom: 0;
  }
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`

export const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Code = styled.span`
  color: ${palette.gray};
  margin-right: 0.5rem;
`

export const LinearProgress = styled(MuiLinearProgress)`
  margin-bottom: 0.5rem;
`
export const Name = styled.div`
  a {
    font-size: 1rem;
    color: inherit;
  }
`

export const Percent = styled.div``

export const Links = styled.div`
  a {
    font-size: 0.8rem;
    margin-right: 1.5rem;
  }
`
