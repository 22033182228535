import queryString from "query-string"

export const paths = {
  home: () => "/",

  login: () => "/login",
  signup: () => "/signup",
  signupComplete: () => "/signup-complete",
  forgot: () => "/forgot",

  profile: () => "/profile",
  billing: () => "/billing",
  plans: () => "/plans",
  users: () => "/users",
  feedback: () => "/feedback",
  inviteConfirm: () => "/invite-confirm",

  projects: () => "/projects",
  projectItem: (projectSlug, query) => {
    if (query)
      return `/projects/${projectSlug}?${queryString.stringify(query, {
        encode: false,
      })}`

    return `/projects/${projectSlug}`
  },

  orgSettings: (id) => `/organization/${id}/settings`,

  languages: (projectSlug) => `/projects/${projectSlug}/languages`,
  settings: (projectSlug) => `/projects/${projectSlug}/settings`,
  upload: (projectSlug) => `/projects/${projectSlug}/upload`,
  download: (projectSlug) => `/projects/${projectSlug}/download`,
}
