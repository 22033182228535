import { entityName } from "data/entities/consts"

const { createAction } = require("@reduxjs/toolkit")

export const languageSetFilter = createAction(
  `${entityName.language}/setFilter`
)

export const languageListCleared = createAction(
  `${entityName.language}/listClear`
)
