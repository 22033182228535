import React, { memo, useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import {
  Grid,
  Button,
  IconButton,
  Typography,
  LinearProgress,
} from "@material-ui/core"

import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined"

import { ModalEnum } from "data/entities/modal"
import { projectFetchList } from "data/entities/project/thunks"

import { useModal } from "view/modals/useModal"
import { PageHeader } from "view/uikit/common/PageHeader"
import { paths } from "view/router/paths"

import { projectSelectors } from "data/entities/project/selectors"
import * as S from "view/pages/project/ProjectListPage/styled"
import { organizationSelectors } from "data/entities/organization/selectors"
import { identitySelectors } from "data/entities/identity/selectors"
import { usePermission } from "view/hooks/rbac/usePermission"
import { projectCurrentChanged } from "data/entities/project/actions"

const Page = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()

  const projects = useSelector(projectSelectors.selectAll)
  const organization = useSelector(organizationSelectors.current)
  // const loadingList = useSelector(projectSelectors.loadingList)
  const role = useSelector(identitySelectors.role)

  const permission = usePermission(role)

  const { onOpen: openProjectCreate } = useModal(ModalEnum.ProjectCreate)
  const { onOpen: openUpgrade } = useModal(ModalEnum.Upgrade)

  useEffect(() => {
    dispatch(projectFetchList())
  }, [organization])

  const handlerSettings = useCallback(
    (e, id) => {
      e.stopPropagation()
      e.preventDefault()
      history.push(paths.settings(id))
    },
    [history]
  )

  const handlerOpenProject = useCallback(
    (e, project) => {
      dispatch(projectCurrentChanged(project.id))
      history.push(paths.projectItem(project.slug))
    },
    [history]
  )

  const handlerLanguageLink = useCallback(
    (e, slug) => {
      e.stopPropagation()
      history.push(paths.languages(slug))
    },
    [history]
  )

  const button = useMemo(() => {
    return (
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={openProjectCreate}
        startIcon={<AddOutlinedIcon />}
      >
        {t("project.button.create")}
      </Button>
    )
  }, [openProjectCreate])

  return (
    <>
      <PageHeader
        title={t("project.list.title")}
        button={permission.project.canWrite && button}
      />

      {projects.length ? (
        <S.ProjectList>
          {projects.map((p) => {
            const sumUntranslated = Object.values(p.stat.untranslated).reduce(
              (acc, v) => acc + v,
              0
            )

            const percent = !p.stat.total
              ? 0 // если вообще нет ключей в проекте, значит 0% готово
              : 100 - // иначе рассчитываем % не переведенных
                (
                  sumUntranslated /
                  ((p.stat.total * p.langs.length) / 100)
                ).toFixed(0)

            // TODO для дебага, потом покрыть тестами
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>", p.name)
            // console.log("p.stat.total", p.stat.total)
            // console.log("p.langs.length", p.langs.length)
            // console.log("sumUntranslated", sumUntranslated)
            // console.log("percent", percent)

            return (
              <S.ProjectWrapper key={p.id}>
                <S.Project onClick={(e) => handlerOpenProject(e, p)}>
                  <Grid container alignItems="center">
                    <Grid item xs={11}>
                      <Typography variant="h6">{p.name}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {permission.projectSettings.canRead && (
                        <S.ButtonSettingWrapper>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={(e) => handlerSettings(e, p.slug)}
                          >
                            <SettingsOutlinedIcon />
                          </IconButton>
                        </S.ButtonSettingWrapper>
                      )}
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                      <S.Progress>
                        <LinearProgress
                          variant="determinate"
                          value={+percent}
                        />
                      </S.Progress>
                      <S.Percent>{percent}%</S.Percent>
                    </Grid>
                    <Grid item xs={6}>
                      <S.Languages>
                        {t("project.item.languages")}: {p.langs?.length || 0}
                      </S.Languages>
                    </Grid>
                    <Grid item xs={6}>
                      <S.Keys>
                        {t("project.item.keys")}: {p.stat.total}
                      </S.Keys>
                    </Grid>
                  </Grid>

                  <S.ButtonWrapper>
                    <Button size="small" color="primary" variant="outlined">
                      {t("project.button.go-to-project")}
                    </Button>
                  </S.ButtonWrapper>
                </S.Project>
              </S.ProjectWrapper>
            )
          })}
        </S.ProjectList>
      ) : (
        <div />
      )}
    </>
  )
}

export const ProjectListPage = memo(Page)
