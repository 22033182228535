import styled from "styled-components"
import MuiAppBar from "@material-ui/core/AppBar"

import { whiteTheme } from "view/themes/white"
import { transactionEntering, transactionLeaving } from "view/themes/utils"
import { LeftSidebar } from "view/components/application/__layouts/MainLayout/config"

const theme = whiteTheme

export const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
`

export const AppBar = styled(MuiAppBar)`
  box-shadow: none;
  background-color: #fff;
  color: #212b36;

  border-bottom: 1px solid #e9ebf0;
  transition: ${({ open }) =>
    open
      ? transactionLeaving(["width", "margin-left"])
      : transactionEntering(["width", "margin-left"])};
  width: calc(
    100% - ${({ open }) => (open ? LeftSidebar.WIDTH : LeftSidebar.SHORT_WIDTH)}
  );
  margin-left: ${({ open }) => (open ? LeftSidebar.WIDTH : 0)};
  z-index: ${theme.zIndex.drawer + 1};

  & > div {
    padding: 0;
  }
`

export const Main = styled.div`
  flex-grow: 1;
  padding: 2rem;
  margin-top: ${({ withoutAppBar }) => (withoutAppBar ? 0 : "4rem")};
  // margin-left: ${({ open }) => (open ? `-${LeftSidebar.WIDTH}` : 0)};
  transition: ${({ open }) =>
    open ? transactionLeaving(["width"]) : transactionEntering(["width"])};
  margin-bottom: 4rem;
`
