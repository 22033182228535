import React from "react"
import { useSelector } from "react-redux"

import { appHistory } from "infrastructure/application/history"

import { identitySelectors } from "data/entities/identity/selectors"
import { organizationSelectors } from "data/entities/organization/selectors"

import { SignupCompleteForm } from "view/forms/identity/SignupCompleteForm"
import { paths } from "view/router/paths"

export const SignupCompletePage = () => {
  const identity = useSelector(identitySelectors.current)
  const org = useSelector(organizationSelectors.current)

  if (identity.name && !identity.email && org) {
    appHistory.push(paths.projects())
  }

  return <SignupCompleteForm />
}
