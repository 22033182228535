import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import { mark } from "infrastructure/utils"
import { appHistory } from "infrastructure/application/history"

import { SyncUrlFilterTranslationContainer } from "domain/application/SyncUrlContainer"

import { translationSelectors } from "data/entities/translation/selectors"
import { projectSelectors } from "data/entities/project/selectors"
import { languageSelectors } from "data/entities/language/selectors"
import { identitySelectors } from "data/entities/identity/selectors"
import { LoadingEnum } from "data/enums/LoadingEnum"

import { langNameByCode } from "view/helpers/langNameByCode"
import { TranslationEditForm } from "view/forms/translation/TranslationEditForm"
import { FileSelector } from "view/pages/project/ProjectItemPage/FileSelector"
import { Tooltip } from "view/uikit/base/Tooltip"
import { GridHeader } from "view/pages/project/ProjectItemPage/GridHeader"
import { GridFooter } from "view/pages/project/ProjectItemPage/GridFooter"
import { TranslationSubMenu } from "view/pages/project/ProjectItemPage/TranslationSubMenu"
import { paths } from "view/router/paths"
import { usePermission } from "view/hooks/rbac/usePermission"

import { Loader } from "view/uikit/base/Loader"
import * as S from "./styled"

export const ProjectItemPage = () => {
  const langList = useSelector(languageSelectors.selectAll)
  const translationList = useSelector(translationSelectors.selectAll)
  const loading = useSelector(translationSelectors.loading)
  const search = useSelector(translationSelectors.search)
  const project = useSelector(projectSelectors.current)
  const filter = useSelector(languageSelectors.filter)
  const role = useSelector(identitySelectors.role)

  const permission = usePermission(role)

  const sortedLangList = useMemo(() => {
    if (!project) return langList

    if (filter.length) {
      return langList.filter(
        (l) => filter.includes(l.id) || l.id === project?.defaultLang
      )
    }

    return langList
      .sort((a, b) => {
        if (a.id < b.id) return -1
        if (a.id === b.id) return 0
        return 1
      })
      .sort((a, b) => {
        if (a.id === project?.defaultLang) return -1
        return 1
      })
  }, [filter, langList, project])

  const filteredKeyList = useMemo(() => {
    return translationList.filter((t) => {
      return t
    })
  }, [translationList])

  const showLoader =
    (loading === LoadingEnum.PENDING && !translationList.length) || !project

  // if (!permission.project.canRead) {
  //   appHistory.push(paths.home())
  //   return null
  // }

  if (showLoader) {
    return (
      <S.Wrapper>
        <S.Center>
          <Loader color="#523ddb" />
        </S.Center>
      </S.Wrapper>
    )
  }

  if (!translationList.length && !search) {
    return <FileSelector />
  }

  return (
    <S.Wrapper>
      <SyncUrlFilterTranslationContainer />

      <GridHeader />

      {showLoader ? (
        <S.Center>
          <Loader color="#523ddb" />
        </S.Center>
      ) : !translationList.length ? (
        <S.Center>
          <S.Center>Not found</S.Center>
        </S.Center>
      ) : (
        <S.Content>
          <S.WrapperList>
            {filteredKeyList.map((k) => (
              <S.KeyItem key={k.id}>
                <S.Left>
                  <div>
                    <S.Key>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: search ? mark(k.key, search) : k.key,
                        }}
                      />
                      <div>
                        <TranslationSubMenu id={k.id} />
                      </div>
                    </S.Key>

                    {/* <Tooltip title="Add screenshot"> */}
                    {/*  <IconButton onClick={() => {}} size="small"> */}
                    {/*    <AddPhotoAlternateOutlinedIcon /> */}
                    {/*  </IconButton> */}
                    {/* </Tooltip> */}
                  </div>

                  {/* <S.Screens> */}
                  {/*  <img src="/images/images/screen.png" alt="screen" /> */}
                  {/* </S.Screens> */}

                  {/* <S.Comment> */}
                  {/*  <div>Comment</div> */}
                  {/*  Comment for this translate key. */}
                  {/* </S.Comment> */}
                </S.Left>

                <S.Right>
                  {sortedLangList.map((l) => {
                    const langName = langNameByCode(l.id)
                    return (
                      <S.Translation key={l.id}>
                        <S.Lang>
                          <Tooltip title={langName}>{langName}</Tooltip>
                        </S.Lang>

                        <TranslationEditForm
                          lang={l.id}
                          translationKey={k}
                          search={search}
                        />
                      </S.Translation>
                    )
                  })}
                </S.Right>
              </S.KeyItem>
            ))}
          </S.WrapperList>

          <GridFooter />
        </S.Content>
      )}
    </S.Wrapper>
  )
}
