import styled from "styled-components"
import { Typography as MuiTypography } from "@material-ui/core"

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

export const Typography = styled(MuiTypography)`
  margin-right: 2rem;
`
