import React, { useMemo } from "react"
import { Field } from "react-final-form"

import { FormControl } from "@material-ui/core"

import { enumToOptions } from "infrastructure/utils"

import { ModalEnum } from "data/entities/modal"
import { RoleEnum } from "data/enums/RoleEnum"

import { InputField } from "view/uikit/formFields/InputField"
import { FormError } from "view/uikit/common/FormError"
import { FormButtons } from "view/uikit/common/FormButtons"
import { useModal } from "view/modals/useModal"

import { Container } from "view/forms/user/InviteSendForm/container"
import * as S from "view/forms/user/InviteSendForm/styled"
import { SelectField } from "view/uikit/formFields/SelectField"

const Component = ({ handleSubmit, submitError, submitting, form }) => {
  const { onClose } = useModal(ModalEnum.InviteSend)

  const roleOptions = useMemo(() => {
    return enumToOptions(RoleEnum).filter((r) => r.value !== RoleEnum.Owner)
  }, [])

  return (
    <S.Form onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <Field component={InputField} name="name" label="Name" />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field component={InputField} name="email" label="Email" />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Field
          component={SelectField}
          options={roleOptions}
          name="role"
          label="Role"
        />
      </FormControl>

      {submitError && (
        <FormControl fullWidth margin="normal">
          <FormError>{submitError}</FormError>
        </FormControl>
      )}

      <FormButtons
        submitBtn={{
          disabled: submitting,
          title: "Send",
        }}
        cancelBtn={{
          disabled: submitting,
          title: "Cancel",
        }}
        cbCancel={() => {
          form.reset()
          onClose()
        }}
      />
    </S.Form>
  )
}

export const InviteSendForm = () => {
  return <Container component={Component} />
}
