import axios from "axios"
import { storageService } from "infrastructure/services/StorageService"
import { fbApp } from "infrastructure/firebase"

const { REACT_APP_API_URL } = process.env

export const apiService = axios.create({
  baseURL: REACT_APP_API_URL,
})

apiService.getSource = () => {
  return axios.CancelToken.source()
}

apiService.interceptors.request.use(
  async (config) => {
    const token = await storageService.getItem("auth")

    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      const { currentUser } = fbApp.auth()

      if (!currentUser) return Promise.reject(error)

      return currentUser
        .getIdToken(true)
        .then(async (token) => {
          await storageService.setItem("auth", token)

          return apiService(originalRequest)
        })
        .catch((e) => {
          return Promise.reject(e)
        })
    }

    return Promise.reject(error)
  }
)
