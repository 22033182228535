import { createEntityAdapter } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

export const billingPlanSelectors = {
  ...adapter.getSelectors((state) => state[entityName.billingPlans]),
  current: (state) => {
    if (!state[entityName.billingPlans].ids.length) return null

    const { entities } = state[entityName.billingPlans]
    const freePlan = entities.free

    const orgId = state[entityName.organization].current
    if (!orgId) return freePlan

    const { subscription } = state[entityName.organization].entities[orgId]
    if (!subscription) return freePlan

    const currentPlan = Object.values(entities).find(
      (p) => +p.subscriptionPlanId === +subscription.planId
    )

    return currentPlan || freePlan
  },
}
