import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { IconButton, InputAdornment } from "@material-ui/core"
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined"
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"

import { useDebounce } from "infrastructure/_hooks/useDebounce"

import { translationSetSearch } from "data/entities/translation"
import { usePrev } from "infrastructure/_hooks/usePrev"

import * as S from "view/pages/project/ProjectItemPage/Search/styled"

export const Search = () => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState("")
  const debouncedValue = useDebounce(search, 100)
  const prevDebouncedValue = usePrev(debouncedValue)

  const changeValue = useCallback((e) => {
    setSearch(e.target.value)
  }, [])

  useEffect(() => {
    if (!debouncedValue && prevDebouncedValue === undefined) return

    dispatch(translationSetSearch(debouncedValue))
  }, [debouncedValue, dispatch, prevDebouncedValue])

  const clearValue = useCallback(() => {
    setSearch("")
  }, [])

  return (
    <S.Input
      id="search"
      value={search}
      placeholder="Search"
      onChange={changeValue}
      startAdornment={
        <S.InputAdornment position="start">
          <SearchOutlinedIcon />
        </S.InputAdornment>
      }
      endAdornment={
        search ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={clearValue}>
              <CloseOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        )
      }
    />
  )
}
