import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

import { projectSelectors } from "data/entities/project/selectors"
import { languageSelectors } from "data/entities/language/selectors"
import { entityName } from "data/entities/consts"

export const translationFetchList = createAsyncThunk(
  `${entityName.translation}/fetchList`,
  async (
    { search, page, limit, status, langs, projectId },
    { rejectWithValue }
  ) => {
    try {
      // TODO url encode
      let url = `/translations?projectId=${projectId}`
      if (search) url += `&search=${encodeURI(search)}`
      if (page) url += `&page=${page}`
      if (limit) url += `&limit=${limit}`
      if (status) url += `&status=${status}`
      if (langs) url += `&langs=${langs}`

      const result = await apiService.get(url)

      const { items, ...pagging } = result.data

      return {
        items: items.map(({ _id, ...rest }) => ({
          id: _id,
          ...rest,
        })),
        ...pagging,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const keyUpdate = createAsyncThunk(
  `${entityName.translation}/keyUpdate`,
  async ({ id, key }, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id) return Error("Project ID not found")

      const result = await apiService.put(`/translations/${id}/key`, {
        projectId: project.id,
        key,
      })

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const translationCreate = createAsyncThunk(
  `${entityName.translation}/create`,
  async ({ key, translation, defaultLang }, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id)
        return rejectWithValue(new Error("Project ID not found"))

      const result = await apiService.post(`/translations`, {
        projectId: project?.id,
        key,
        defaultLang,
        translation,
      })

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const translationUpdate = createAsyncThunk(
  `${entityName.translation}/update`,
  async (values, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id) return Error("Project ID not found")

      const result = await apiService.put(`/translations`, {
        projectId: project.id,
        lang: values.lang,
        key: values.id,
        translation: values.translation,
      })

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const translationRemove = createAsyncThunk(
  `${entityName.translation}/remove`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)

      if (!project?.id) return Error("Project ID not found")

      const result = await apiService.delete(
        `/translations/${id}?projectId=${project?.id}`
      )

      const { _id } = result.data
      return {
        id: _id,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getCountKeys = createAsyncThunk(
  `${entityName.translation}/getCountKeys`,
  async (props, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const project = projectSelectors.current(state)
      const langs = languageSelectors.selectAll(state)

      if (!project?.id)
        return rejectWithValue(new Error("Project ID not found"))

      if (!langs) return rejectWithValue(new Error("Langs ID not found"))

      const result = await apiService.get(
        `/translations/count?projectId=${project.id}&langs=${langs
          .map((l) => l.id)
          .join(",")}`
      )

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
