import React, { useCallback, useMemo } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { FORM_ERROR } from "final-form"
import { useSnackbar } from "notistack"

import { nameToKey } from "infrastructure/utils"

import { projectSelectors } from "data/entities/project/selectors"
import { projectUpdate } from "data/entities/project/thunks"
import { langOptions } from "data/mocks/langOptions"
import { emptyOption } from "data/mocks/emptyOption"

import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"
import { validate } from "./validate"

export const Container = ({ component: Component }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const project = useSelector(projectSelectors.current)

  const langs = useMemo(() => {
    if (!project) return [emptyOption]

    return langOptions.filter((l) => project.langs.includes(l.value))
  }, [project])

  const initialValues = useMemo(
    () => ({
      name: project?.name || "",
      defaultLang: project?.defaultLang
        ? langs.find((l) => l.value === project.defaultLang)
        : emptyOption,
    }),
    [project]
  )

  const handlerSubmit = useCallback(
    async ({ name, defaultLang }, { reset }) => {
      try {
        const slug = nameToKey(name.trim())

        const result = await dispatch(
          projectUpdate({
            name: name.trim(),
            defaultLang,
            id: project.id,
            orgId: project.orgId,
            slug,
          })
        )

        if (result.type === projectUpdate.fulfilled().type) {
          setTimeout(reset, 0)
          enqueueSnackbar("Project has been updated!", {
            variant: "success",
          })
          appHistory.push(paths.settings(slug))
          return {}
        }

        if (result.type === projectUpdate.rejected().type) {
          const { response } = result.payload

          if (response.status === 400) {
            if (
              response.data.code === "not-unique" &&
              response.data.param === "slug"
            ) {
              return {
                name: "Name already used",
              }
            }
          }
          return {
            [FORM_ERROR]: "Server error",
          }
        }

        return {}
      } catch (e) {
        return {
          [FORM_ERROR]: "Server error",
        }
      }
    },
    [project]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} langOptions={langs} />}
    />
  )
}
