import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { Form } from "react-final-form"

import { apiService } from "infrastructure/services/ApiService"
import { projectSelectors } from "data/entities/project/selectors"
import queryString from "query-string"
import { validate } from "./validate"
import { ExportFormatEnum, ExportStatusEnum } from "./enums"

const initialValues = {
  format: ExportFormatEnum.JSON,
  status: ExportStatusEnum.All,
  lang: "",
}

const formatToExtensionMapper = (format) => {
  switch (format) {
    case ExportFormatEnum.JSON:
      return ".json"
    case ExportFormatEnum.JSON_NESTED:
      return ".json"
    case ExportFormatEnum.CSV:
      return ".csv"
    case ExportFormatEnum.ARB:
      return ".arb"
    default:
      return ""
  }
}

export const Container = ({ component: Component }) => {
  const project = useSelector(projectSelectors.current)

  const handlerSubmit = useCallback(
    async (values) => {
      const query = queryString.stringify(
        {
          ...values,
          projectId: project.id,
        },
        {
          encode: false,
        }
      )

      const result = await apiService.get(`/export?${query}`, {
        responseType: "blob",
      })

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(result.data)
      link.setAttribute(
        "download",
        `${values.lang}${formatToExtensionMapper(values.format)}`
      )
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    [project]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => {
        return <Component {...props} />
      }}
    />
  )
}
