import React, { memo, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IconButton, Button } from "@material-ui/core"

import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { ModalEnum } from "data/entities/modal"
import { userFetchList } from "data/entities/user/thunks"
import { inviteSelectors } from "data/entities/invite/selectors"
import { userSelectors } from "data/entities/user/selectors"
import {
  inviteFetchList,
  inviteRemove,
  inviteResend,
} from "data/entities/invite/thunks"

import { PageHeader } from "view/uikit/common/PageHeader"
import { useModal } from "view/modals/useModal"
import { Tooltip } from "view/uikit/base/Tooltip"
import * as S from "view/pages/user/UsersPage/styled"
import { useSnackbar } from "notistack"
import { Avatar } from "view/uikit/common/Avatar"
import { RoleEnum } from "data/enums/RoleEnum"
import { organizationSelectors } from "data/entities/organization/selectors"
import { identitySelectors } from "data/entities/identity/selectors"
import { useRole } from "view/hooks/rbac/useRole"
import { removeAllInvites } from "data/entities/invite/actions"

const RESENT_LOCK_SEC = 10

const Page = () => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const [disabledResend, setDisabledResend] = useState(false)
  const [counter, setCounter] = useState(RESENT_LOCK_SEC)

  const { onOpen } = useModal(ModalEnum.InviteSend)

  const invites = useSelector(inviteSelectors.selectAll)
  const role = useSelector(identitySelectors.role)
  const users = useSelector(userSelectors.selectAll)
  const organization = useSelector(organizationSelectors.current)

  const { isAdmin, isOwner } = useRole(role)

  useEffect(() => {
    if (!disabledResend) return

    let intervalId

    if (counter > 0) {
      intervalId = setInterval(() => setCounter(counter - 1), 1000)
    } else {
      setCounter(RESENT_LOCK_SEC)
      setDisabledResend(false)
    }

    return () => clearInterval(intervalId)
  }, [counter, disabledResend])

  const handlerResend = useCallback(async (id) => {
    setDisabledResend(true)

    const result = await dispatch(inviteResend(id))

    if (result.type === inviteResend.fulfilled().type) {
      setDisabledResend(true)

      enqueueSnackbar("The invite has been resent!", {
        variant: "success",
      })
    }
  }, [])

  const handlerRemove = useCallback(async (id) => {
    const result = await dispatch(inviteRemove(id))

    if (result.type === inviteRemove.fulfilled().type) {
      enqueueSnackbar("The invite has been removed!", {
        variant: "success",
      })
    }
  }, [])

  useEffect(() => {
    dispatch(userFetchList())
    if (isAdmin || isOwner) dispatch(inviteFetchList())
    else dispatch(removeAllInvites())
  }, [organization, isAdmin, isOwner])

  return (
    <>
      <PageHeader
        title="Users"
        button={
          isAdmin || isOwner ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={onOpen}
              startIcon={<AddOutlinedIcon />}
            >
              Add member
            </Button>
          ) : null
        }
      />

      <S.Users>
        {users.map((u) => (
          <S.InviteRow key={u.id}>
            <S.AvatarCol>
              <Avatar name={u.name} email={u.email} />
            </S.AvatarCol>
            <S.NameCol>
              <S.Name>{u.name}</S.Name>
              <S.Email>{u.email}</S.Email>
            </S.NameCol>
            <S.RoleCol>{u.role}</S.RoleCol>
            <S.RemoveCol>
              {/* нельзя удалять ownera */}
              {u.role !== RoleEnum.Owner && (isOwner || isAdmin) && (
                <Button variant="outlined" size="small" color="primary">
                  Remove
                </Button>
              )}
            </S.RemoveCol>
            <div />
          </S.InviteRow>
        ))}

        {!!invites.length && <S.Subtitle>Invites</S.Subtitle>}

        {invites.map((i) => (
          <S.InviteRow key={i.id}>
            <div>
              <Avatar name={i.name} email={i.email} />
            </div>
            <S.NameCol>
              <S.Name>
                {i.name} <span>[invited]</span>
              </S.Name>
              <S.Email>{i.email}</S.Email>
            </S.NameCol>
            <S.RoleCol>
              {disabledResend ? (
                <div>
                  00:{counter < 10 && "0"}
                  {counter}
                </div>
              ) : (
                <Button
                  onClick={() => handlerResend(i.id)}
                  size="small"
                  color="primary"
                  disabled={disabledResend}
                >
                  Resend link
                </Button>
              )}
            </S.RoleCol>
            <S.RemoveCol>
              <Tooltip title="Delete invite">
                <IconButton onClick={() => handlerRemove(i.id)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </S.RemoveCol>
          </S.InviteRow>
        ))}
      </S.Users>
    </>
  )
}

export const UsersPage = memo(Page)
