import React, { memo } from "react"

import { PageHeader } from "view/uikit/common/PageHeader"

const Page = () => {
  return (
    <>
      <div>
        <PageHeader title="Feedback" />
        Feedback about application
      </div>
    </>
  )
}

export const FeedbackPage = memo(Page)
