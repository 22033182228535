import { createEntityAdapter } from "@reduxjs/toolkit"

import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity._id,
})

export const transactionSelectors = {
  ...adapter.getSelectors((state) => state[entityName.transaction]),
}
