import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { Field } from "react-final-form"

import { FormControl, Grid } from "@material-ui/core"

import { languageSelectors } from "data/entities/language/selectors"

import { FileFormatEnum } from "view/forms/project/UploadForm/enums"
import { FormButtons } from "view/uikit/common/FormButtons"
import { langNameByCode } from "view/helpers/langNameByCode"
import { SelectField } from "view/uikit/formFields/SelectField"
import { Container } from "view/forms/project/UploadForm/container"
import { DragDropUploadField } from "view/uikit/formFields/DragDropUploadField"
import { CheckboxField } from "view/uikit/formFields/CheckboxField"
import { Box } from "view/uikit/base/Box"

import * as S from "./styled"

const Component = ({ handleSubmit, submitting, values, form }) => {
  const langs = useSelector(languageSelectors.selectAll)

  const languageOptions = useMemo(() => {
    return (
      langs.map((l) => ({
        label: langNameByCode(l.id),
        value: l.id,
      })) || []
    )
  }, [langs])

  const formatOptions = useMemo(
    () => [
      {
        label: "Plain JSON (.json)",
        value: FileFormatEnum.JsonSimple,
      },
      {
        label: "Nested JSON (.json)",
        value: FileFormatEnum.JsonNested,
      },
      {
        label: "Required JS (.js)",
        value: FileFormatEnum.RequiredJs,
      },
      {
        label: "CSV (.csv)",
        value: FileFormatEnum.Csv,
      },
      {
        label: "YML (.yaml)",
        value: FileFormatEnum.Yaml,
      },
      {
        label: "ARB (.arb)",
        value: FileFormatEnum.Arb,
      },
      {
        label: "XLSX (.xlsx)",
        value: FileFormatEnum.Xlsx,
      },
      {
        label: "PHP (.php)",
        value: FileFormatEnum.Php,
      },
      {
        label: "Po (.po)",
        value: FileFormatEnum.Po,
      },
      {
        label: "Pot (.pot)",
        value: FileFormatEnum.Pot,
      },
      {
        label: "STRINGS (.strings)",
        value: FileFormatEnum.Strings,
      },
      {
        label: "XLIFF 1.2 (.xliff)",
        value: FileFormatEnum.Xliff_1_2,
      },
      {
        label: "XLIFF 2.0 (.xliff)",
        value: FileFormatEnum.Xliff_2_0,
      },
      {
        label: "RESX (.resx)",
        value: FileFormatEnum.Resx,
      },
      {
        label: "TS (.ts)",
        value: FileFormatEnum.Ts,
      },
      {
        label: "INI (.ini)",
        value: FileFormatEnum.Ini,
      },
    ],
    []
  )

  const fileSelected = !!values.file

  return (
    <form onSubmit={handleSubmit}>
      {!fileSelected ? (
        <FormControl fullWidth margin="dense">
          <Field component={DragDropUploadField} name="file" />
        </FormControl>
      ) : (
        <Grid item xs={4}>
          <Box>
            <S.SelectedFile>Selected file: {values.file.name}</S.SelectedFile>

            <FormControl fullWidth margin="normal">
              <Field
                component={SelectField}
                name="format"
                label="Format"
                options={formatOptions}
                disabled={submitting}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <Field
                component={SelectField}
                name="lang"
                label="Language"
                options={languageOptions}
                disabled={submitting}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <Field
                component={CheckboxField}
                type="checkbox"
                name="rewrite"
                color="primary"
                disabled={submitting}
              >
                Overwrite translations if keys exists
              </Field>
            </FormControl>

            <FormButtons
              submitBtn={{
                disabled: submitting,
                title: submitting ? "Uploading..." : "Upload",
              }}
              cancelBtn={{
                disabled: submitting,
                title: "Back",
              }}
              cbCancel={() => {
                form.reset()
              }}
            />
          </Box>
        </Grid>
      )}
    </form>
  )
}

export const UploadForm = () => <Container component={Component} />
