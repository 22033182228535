import styled from "styled-components"
import { truncate } from "view/themes/mixins"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
`

export const Center = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  flex-grow: 1;
`

export const WrapperList = styled.div``

export const KeyItem = styled.div`
  display: flex;
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 3px;
`
export const Left = styled.div`
  width: 20rem;
  flex-shrink: 0;
`

export const Right = styled.div`
  flex-grow: 1;
`

export const Translation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Lang = styled.div`
  width: 10rem;
  text-align: right;
  padding-right: 1rem;
  flex-shrink: 0;
  cursor: default;
  ${truncate}
`

export const Key = styled.div`
  font-size: 0.8rem;

  & > span {
    padding-right: 0.5rem;

    & > i {
      background-color: #7b69ee;
      color: white;
      font-style: normal;
    }
  }
`

export const Screens = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

export const Comment = styled.div`
  font-size: 0.7rem;
`
