import React, { useCallback } from "react"
import { validate } from "view/forms/identity/SignupForm/validate"
import { Form } from "react-final-form"
import { FORM_ERROR } from "final-form"
import { fbApp } from "infrastructure/firebase"

const initialValues = {}

export const Container = ({ component: Component }) => {
  const handlerSubmit = useCallback(async (values) => {
    try {
      await fbApp
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
    } catch (e) {
      const errors = {}

      switch (e.code) {
        case "auth/email-already-in-use":
          errors.email =
            "The email address is already in use by another account"
          break
        case "auth/argument-error":
          errors[FORM_ERROR] = "Email or password invalid"
          break
        default:
          errors[FORM_ERROR] = "Internal error"
      }

      return errors
    }
    return {}
  }, [])

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => <Component {...props} />}
    />
  )
}
