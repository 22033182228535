import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"
import { entityName } from "data/entities/consts"

export const fetchOrganization = createAsyncThunk(
  `${entityName.organization}/fetch`,
  async (id, { rejectWithValue }) => {
    try {
      const result = await apiService.get(`/organizations/${id}`)

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const organizationUpdate = createAsyncThunk(
  `${entityName.project}/update`,
  async ({ name, orgId }, { rejectWithValue }) => {
    try {
      const result = await apiService.put(`/organizations/${orgId}`, {
        name,
      })

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
