import { createEntityAdapter } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity.id,
})

export const translationSelectors = {
  ...adapter.getSelectors((state) => state[entityName.translation]),
  search: (state) => state[entityName.translation].filter.search,
  status: (state) => state[entityName.translation].filter.status,
  loading: (state) => state[entityName.translation].loading,
  pagging: (state) => state[entityName.translation].pagging,
}
