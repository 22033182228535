import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { Transition } from "view/modals/Transition"
import { LanguageAddForm } from "view/forms/language/LanguageAddForm"

export const LanguageAddModal = () => {
  const { open, onClose } = useModal(ModalEnum.LanguageAdd)

  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Add language</DialogTitle>
      <DialogContent>
        <LanguageAddForm cbCancel={onClose} />
      </DialogContent>
    </Dialog>
  )
}
