import React from "react"
import { Redirect, Switch } from "react-router-dom"

import { MainLayout } from "view/components/application/__layouts/MainLayout"
import { AuthLayout } from "view/components/application/__layouts/AuthLayout"
import { PlansPagePerUser } from "view/pages/billing/PlansPagePerUser"

import { paths } from "view/router/paths"
import { Route } from "view/router/Route"

import { LoginPage } from "view/pages/identity/LoginPage"
import { SignupPage } from "view/pages/identity/SignupPage"
import { ProjectListPage } from "view/pages/project/ProjectListPage"
import { ProjectRoute } from "view/router/ProjectRoute"
import { NotFoundPage } from "view/pages/application/NotFoundPage"
import { ProfilePage } from "view/pages/user/ProfilePage"
import { BillingPage } from "view/pages/billing/BillingPage"
import { UsersPage } from "view/pages/user/UsersPage"
import { FeedbackPage } from "view/pages/support/FeedbackPage"
import { ForgotPage } from "view/pages/identity/ForgotPage"
import { PlansPage } from "view/pages/billing/PlansPage"
import { InviteConfirmPage } from "view/pages/user/InviteConfirmPage"
import { SignupCompletePage } from "view/pages/identity/SignupCompletePage"
import { OrgSettingsPage } from "view/pages/organization/OrgSettingsPage"

export const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={paths.home()}
        component={() => <Redirect to={paths.projects()} />}
        layout={MainLayout}
      />

      <Route
        exact
        path={paths.inviteConfirm()}
        component={InviteConfirmPage}
        layout={AuthLayout}
      />

      <Route
        isIdentity
        path={paths.login()}
        component={LoginPage}
        layout={AuthLayout}
      />

      <Route
        isIdentity
        path={paths.signup()}
        component={SignupPage}
        layout={AuthLayout}
      />

      <Route
        isIdentity
        path={paths.forgot()}
        component={ForgotPage}
        layout={AuthLayout}
      />

      <Route
        isPrivate
        path={paths.signupComplete()}
        component={SignupCompletePage}
        layout={AuthLayout}
      />

      <Route
        isPrivate
        path={paths.profile()}
        component={ProfilePage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.billing()}
        component={BillingPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.plans()}
        component={PlansPagePerUser}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.feedback()}
        component={FeedbackPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.users()}
        component={UsersPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.orgSettings(":id")}
        component={OrgSettingsPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        exact
        path={paths.projects()}
        component={ProjectListPage}
        layout={MainLayout}
        layoutProps={{
          withoutAppBar: true,
        }}
      />

      <Route
        isPrivate
        path={paths.projectItem(":slug")}
        component={ProjectRoute}
        layout={MainLayout}
      />

      <Route component={NotFoundPage} />
    </Switch>
  )
}
