import { projectSelectors } from "data/entities/project/selectors"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

import { Typography } from "@material-ui/core"

import { appHistory } from "infrastructure/application/history"
import { uniq } from "infrastructure/utils"
import { useSelector } from "react-redux"
import {
  commonFiles,
  fileFormat,
  files,
  PlatformEnum,
} from "view/pages/project/ProjectItemPage/FileSelector/config"
import { paths } from "view/router/paths"

import * as S from "./styled"

export const FileSelector = memo(() => {
  const [platform, setPlatform] = useState(null)
  const project = useSelector(projectSelectors.current)

  const filteredFiles = useMemo(() => {
    if (!platform) return files

    return files.filter((f) => f.platform.includes(platform))
  }, [platform])

  const filteredExtensions = useMemo(() => {
    const commonExts = commonFiles.reduce((acc, f) => [...acc, ...f.ext], [])
    const filteredExts = filteredFiles.reduce(
      (acc, f) => [...acc, ...f.format],
      []
    )

    return uniq([...filteredExts, ...commonExts]).map((key) => {
      return fileFormat[key]
    })
  }, [filteredFiles])

  return (
    <S.Wrapper>
      <Typography variant="h4" component="h3" align="center">
        Choose a file to upload translations
      </Typography>
      <S.ButtonFilterGroup>
        <S.ButtonFilter
          active={platform === null}
          onClick={() => setPlatform(null)}
        >
          All
        </S.ButtonFilter>

        {Object.keys(PlatformEnum).map((key) => (
          <S.ButtonFilter
            key={key}
            active={platform === PlatformEnum[key]}
            onClick={() => setPlatform(PlatformEnum[key])}
          >
            {key}
          </S.ButtonFilter>
        ))}
      </S.ButtonFilterGroup>
      <S.Cards>
        {filteredFiles.map((f) => (
          <S.FileWrapper key={f.name}>
            <S.Card
              onClick={() => appHistory.push(paths.upload(project?.slug))}
            >
              <S.Left>
                <S.Title>{f.name}</S.Title>
                <div>
                  {f.subtitle}{" "}
                  <S.Extesions>
                    ({f.ext.map((ext) => `.${ext}`).join(", ")})
                  </S.Extesions>
                </div>
              </S.Left>

              <S.Img src={f.img} alt={f.name} />
            </S.Card>
          </S.FileWrapper>
        ))}
      </S.Cards>
      <br />
      <Typography variant="h5" component="h3" align="center">
        File formats
      </Typography>
      <br />
      <S.Cards>
        {filteredExtensions.map((f) => (
          <S.ExtesionWrapper key={f.name}>
            <S.Card>
              <S.ExtTitle>{f.name}</S.ExtTitle>
            </S.Card>
          </S.ExtesionWrapper>
        ))}
      </S.Cards>
    </S.Wrapper>
  )
})
