import { createEntityAdapter } from "@reduxjs/toolkit"
import { entityName } from "data/entities/consts"

export const adapter = createEntityAdapter({
  selectId: (entity) => entity._id,
})

export const organizationSelectors = {
  ...adapter.getSelectors((state) => state[entityName.organization]),
  current: (state) =>
    state[entityName.organization].entities[
      state[entityName.organization].current
    ],
  id: (state) => state[entityName.organization].id,
  init: (state) => state[entityName.organization].init,
}
