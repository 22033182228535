import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button, Grid, Link as MuiLink } from "@material-ui/core"

import { FilterEnum, translationSetStatus } from "data/entities/translation"
import { translationSelectors } from "data/entities/translation/selectors"

import { Search } from "view/pages/project/ProjectItemPage/Search"

import * as S from "./styled"

export const GridHeader = () => {
  const dispatch = useDispatch()

  const currentStatus = useSelector(translationSelectors.status)

  const setFilter = (status) => {
    dispatch(translationSetStatus(status))
  }

  // ## вычисление кол-ва непереведенных ключей, в зависимости от выбранного языка
  // const untranslatedCount = useMemo(() => {
  //   if (!project) return 0
  //
  //   const langs = langFilter.length ? langFilter : project.langs
  //
  //   if (langs) {
  //     const counts = langs.map((l) => project.stat.untranslated[l] || 0)
  //
  //     return Math.max(...counts)
  //   }
  //
  //   return 0
  // }, [project, langFilter])

  return (
    <S.Wrapper>
      <Grid container>
        <Grid item>
          <Search />
        </Grid>
        <Grid item>
          <Button
            onClick={() => setFilter(FilterEnum.All)}
            size="small"
            component={MuiLink}
            color={currentStatus === FilterEnum.All ? "primary" : "default"}
          >
            All
          </Button>
          <Button
            onClick={() => setFilter(FilterEnum.Untranslated)}
            size="small"
            component={MuiLink}
            color={
              currentStatus === FilterEnum.Untranslated ? "primary" : "default"
            }
          >
            Untranslated
          </Button>
          {/* <Button */}
          {/*  onClick={() => setFilter(FilterEnum.Unverified)} */}
          {/*  size="small" */}
          {/*  component={MuiLink} */}
          {/*  color={ */}
          {/*    currentStatus === FilterEnum.Unverified ? "primary" : "default" */}
          {/*  } */}
          {/* > */}
          {/*  Unverified (12) */}
          {/* </Button> */}
        </Grid>
      </Grid>
    </S.Wrapper>
  )
}
