import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import "firebase/auth"

import { LsEnum, storageService } from "infrastructure/services/StorageService"

export const SetCurrentProjectContainer = () => {
  const dispatch = useDispatch()

  const getLsCurrentProject = useCallback(async () => {
    return await storageService.getItem(LsEnum.CurrentProject)
  }, [])

  useEffect(() => {
    getLsCurrentProject().then((res) => {})
  }, [dispatch, getLsCurrentProject])

  return null
}
