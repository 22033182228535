import React from "react"

import { InitContainer } from "domain/application/InitContainer"
import { AuthContainer } from "domain/identity/AuthContainer"
import { LocalizationContainer } from "domain/application/LocalizationContainer"

export const Containers = () => (
  <>
    <InitContainer />
    <LocalizationContainer />
    <AuthContainer />
  </>
)
