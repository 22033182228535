import styled from "styled-components"
import {
  Input as MuiInput,
  InputAdornment as MuiInputAdornment,
} from "@material-ui/core"
import { whiteTheme } from "view/themes/white"
import { palette } from "view/themes/white/palette"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonFilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const FilterTitle = styled.div`
  font-weight: bold;
  margin-right: 1rem;
`

export const ButtonFilter = styled.div`
  border-radius: 1rem;
  padding: 0.3rem 1rem;
  margin-right: 1rem;
  background-color: ${({ active }) =>
    active ? whiteTheme.palette.primary.main : whiteTheme.palette.common.white};
  color: ${({ active }) =>
    active ? whiteTheme.palette.common.white : whiteTheme.palette.primary.main};
  cursor: pointer;
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const FileWrapper = styled.div`
  width: 33.33%;
`

export const ExtesionWrapper = styled.div`
  width: 25%;
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`

export const Title = styled.div`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`

export const Extesions = styled.span`
  color: ${whiteTheme.palette.grey.A200};
`

export const Img = styled.img`
  width: 2.3rem;
  height: 2.3rem;
`

export const ExtTitle = styled.div`
  font-size: 1.1rem;
`
