import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"

import { keyUpdate } from "data/entities/translation/thunks"
import { projectSelectors } from "data/entities/project/selectors"
import { translationSelectors } from "data/entities/translation/selectors"

import { validate } from "./validate"

export const Container = ({ component: Component, id, componentProps }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const project = useSelector(projectSelectors.current)
  const translation = useSelector((state) =>
    translationSelectors.selectById(state, id)
  )

  const initialValues = { key: translation.key }

  const handlerSubmit = useCallback(
    async (values) => {
      const result = await dispatch(keyUpdate({ id, key: values.key }))

      if (result.type === keyUpdate.fulfilled().type) {
        enqueueSnackbar("Key has been updated!", {
          variant: "success",
        })
      }

      if (result.type === keyUpdate.rejected().type) {
        const { response } = result.payload

        if (response.status === 400) {
          if (response.data.code === "not-unique") {
            return {
              key: "Key already exist",
            }
          }
        }
      }

      return {}
    },
    [id]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => (
        <Component
          {...props}
          {...componentProps}
          defaultLang={project?.defaultLang}
        />
      )}
    />
  )
}
