import React from "react"
import { Field } from "react-final-form"

import { Button, FormControl, Grid, Typography } from "@material-ui/core"

import { Container } from "view/forms/identity/LoginForm/container"
import { InputField } from "view/uikit/formFields/InputField"
import { paths } from "view/router/paths"
import { SocialButtons } from "view/components/identity/SocialButtons"
import { Link } from "view/uikit/base/Link"

import { FormError } from "view/uikit/common/FormError"
import * as S from "view/forms/identity/LoginForm/styled"

const Component = ({ handleSubmit, submitError, submitting }) => {
  return (
    <S.Wrapper>
      <Typography variant="h4" component="h3" align="center">
        Login
      </Typography>

      <Grid container>
        <Grid item>
          <SocialButtons />
        </Grid>
        <Grid item>
          <S.SubTitle>You can also sign in with email</S.SubTitle>

          <S.Form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <Field component={InputField} name="email" label="Email" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Field
                component={InputField}
                name="password"
                label="Password"
                type="password"
              />
            </FormControl>

            {submitError && (
              <FormControl fullWidth margin="normal">
                <FormError>{submitError}</FormError>
              </FormControl>
            )}

            <Button
              color="primary"
              variant="contained"
              disabled={submitting}
              type="submit"
            >
              Login
            </Button>
          </S.Form>

          <S.Text>
            Don`t have an account? <Link to={paths.signup()}>Sign up.</Link>
          </S.Text>
          <S.Text>
            Forgot password? <Link to={paths.forgot()}>Request a new one.</Link>
          </S.Text>
        </Grid>
      </Grid>
    </S.Wrapper>
  )
}

export const LoginForm = () => {
  return <Container component={Component} />
}
