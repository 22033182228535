import React from "react"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Checkbox from "@material-ui/core/Checkbox"

export const CheckboxField = ({
  input: { name, checked, onChange },
  meta,
  children,
  ...rest
}) => {
  const errorMessage = meta.error || meta.submitError
  const validateError = errorMessage && meta.touched ? errorMessage : undefined

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            onChange={onChange}
            type="checkbox"
            checked={checked}
            name={name}
            {...rest}
          />
        }
        label={children}
      />

      <FormHelperText>{validateError}</FormHelperText>
    </>
  )
}
