import { whiteTheme } from "view/themes/white"

const theme = whiteTheme

export const transactionLeaving = (props) =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })

export const transactionEntering = (props) =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  })
