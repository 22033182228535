import styled from "styled-components"

import { Typography as MuiTypography } from "@material-ui/core"

export const Wrapper = styled.div`
  width: 500px;
  background: #fff;
  padding: 1rem;
  border-radius: 1rem;
`

export const Typography = styled(MuiTypography)`
  margin-bottom: 1rem;
`

export const Form = styled.form``

export const Text = styled.div`
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: #adadad;
`
