import { useRole } from "./useRole"

export const usePermission = (role) => {
  const { isOwner, isAdmin, isDeveloper, isManager } = useRole(role)

  const all = true

  return {
    projectList: {
      canRead: all,
    },

    project: {
      canRead: all,
      canWrite: isOwner || isAdmin || isDeveloper || isManager,
    },

    language: {
      canRead: all,
      canWrite: isOwner || isAdmin || isDeveloper || isManager,
    },

    translation: {
      canRead: all,
      canWrite: isOwner || isAdmin || isDeveloper,
    },

    upload: {
      canWrite: isOwner || isAdmin || isDeveloper || isManager,
    },

    download: {
      canWrite: isOwner || isAdmin || isDeveloper || isManager,
    },

    projectSettings: {
      canRead: isOwner || isAdmin || isDeveloper,
      canWrite: isOwner || isAdmin || isDeveloper,
    },

    organizationSettings: {
      canRead: isOwner || isAdmin || isDeveloper,
      canWrite: isOwner || isAdmin || isDeveloper,
    },

    billing: {
      canRead: isOwner || isAdmin,
      canWrite: isOwner || isAdmin,
    },
  }
}
