import React from "react"
import { useDispatch, useSelector } from "react-redux"

import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"
import { ListItemIcon } from "@material-ui/core"
import Slide from "@material-ui/core/Slide"
import Button from "@material-ui/core/Button"
import CheckIcon from "@material-ui/icons/Check"

import { uniq } from "infrastructure/utils"

import { languageSetFilter } from "data/entities/language/actions"
import { ModalEnum } from "data/entities/modal"
import { projectSelectors } from "data/entities/project/selectors"
import { languageSelectors } from "data/entities/language/selectors"

import { langNameByCode } from "view/helpers/langNameByCode"
import { useModal } from "view/modals/useModal"
import * as S from "view/components/application/Toolbar/LangSelector/styled"

export const LangSelector = ({ openable, disabled }) => {
  const dispatch = useDispatch()

  const { open, anchorEl, onOpen, onClose } = openable

  const langList = useSelector(languageSelectors.selectAll)
  const filter = useSelector(languageSelectors.filter)
  const project = useSelector(projectSelectors.current)

  const isDisabled = disabled || !project

  const { onOpen: openLanguageAdd } = useModal(ModalEnum.LanguageAdd)

  const getNewFilter = (key) => {
    if (key === "all") return []
    if (filter.includes(key)) return filter.filter((f) => f !== key)
    return uniq([...filter, key])
  }

  const handlerLangSelect = (key) => {
    const newFilter = getNewFilter(key)

    dispatch(languageSetFilter(newFilter))
  }

  const openAddForm = (e) => {
    openLanguageAdd(e)
    onClose()
  }

  const handlerToggle = (e) => {
    if (isDisabled) return

    if (open) onClose(e)
    else onOpen(e)
  }

  const title = !filter.length
    ? "All locales"
    : filter.map((f) => langNameByCode(f)).join(", ")

  return (
    <>
      <S.Wrapper disabled={isDisabled} onClick={handlerToggle}>
        <S.Title noWrap>{title}</S.Title>
        {open ? (
          <KeyboardArrowUpOutlinedIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )}
      </S.Wrapper>

      <S.Popover
        id="lang-selector"
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        TransitionComponent={Slide}
      >
        <S.ButtonWrapper>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={openAddForm}
            startIcon={<AddOutlinedIcon />}
          >
            Add language
          </Button>
        </S.ButtonWrapper>
        <S.ListItem onClick={() => handlerLangSelect("all")}>
          <ListItemIcon>
            {!filter.length && <CheckIcon fontSize="small" />}
          </ListItemIcon>
          All locales
        </S.ListItem>
        {langList.map((l) => (
          <S.ListItem key={l.id} onClick={() => handlerLangSelect(l.id)}>
            <ListItemIcon>
              {filter.includes(l.id) && <CheckIcon fontSize="small" />}
            </ListItemIcon>
            {langNameByCode(l.id)}
          </S.ListItem>
        ))}
      </S.Popover>
    </>
  )
}
