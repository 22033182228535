export const validate = (values) => {
  const errors = {}

  if (!values.format) errors.format = "Required"
  if (!values.lang) errors.lang = "Required"
  if (!values.file) errors.file = "Required"
  // if (!values.format) errors.format = "Required"

  return errors
}
