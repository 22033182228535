import React from "react"
import * as S from "view/uikit/common/PageHeader/styled"

export const PageHeader = ({ title, button }) => {
  return (
    <S.Wrapper>
      <S.Typography variant="h5">{title}</S.Typography>
      {button}
    </S.Wrapper>
  )
}
