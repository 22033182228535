import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { ModalEnum } from "data/entities/modal"

import { ProjectForm } from "view/forms/project/ProjectForm"
import { useModal } from "view/modals/useModal"
import { Transition } from "view/modals/Transition"

export const ProjectCreateModal = () => {
  const { open, onClose } = useModal(ModalEnum.ProjectCreate)

  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Create project</DialogTitle>
      <DialogContent>
        <ProjectForm cbCancel={onClose} />
      </DialogContent>
    </Dialog>
  )
}
