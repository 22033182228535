import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation } from "react-router"

import Button from "@material-ui/core/Button"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { ModalEnum } from "data/entities/modal"
import { projectSelectors } from "data/entities/project/selectors"

import { ProjectSelector } from "view/components/application/Toolbar/ProjectSelector"
import { LangSelector } from "view/components/application/Toolbar/LangSelector"
import { useOpenable } from "view/hooks/application/useOpenable"
import { useModal } from "view/modals/useModal"
import { paths } from "view/router/paths"

import { identitySelectors } from "data/entities/identity/selectors"
import { usePermission } from "view/hooks/rbac/usePermission"
import * as S from "./styled"

export const Toolbar = () => {
  const openablePS = useOpenable()
  const openableLS = useOpenable()

  const location = useLocation()

  const { onOpen: openTranslationAdd } = useModal(ModalEnum.TranslationAdd)

  const project = useSelector(projectSelectors.current)
  const role = useSelector(identitySelectors.role)

  const permission = usePermission(role)

  const handlerOpenPS = useCallback(
    (e) => {
      openableLS.onClose()
      openablePS.onOpen(e)
    },
    [openableLS, openablePS]
  )

  const handlerOpenLS = useCallback(
    (e) => {
      openableLS.onOpen(e)
      openablePS.onClose()
    },
    [openableLS, openablePS]
  )

  const projectItemPage = matchPath(location.pathname, {
    path: paths.projectItem(":id"),
    exact: true,
    strict: false,
  })

  return (
    <S.MuiToolbar>
      <ProjectSelector openable={{ ...openablePS, onOpen: handlerOpenPS }} />
      <LangSelector
        openable={{ ...openableLS, onOpen: handlerOpenLS }}
        disabled={!projectItemPage}
      />

      {projectItemPage && permission.translation.canWrite && (
        <S.ButtonsWrapper>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={!project}
            onClick={openTranslationAdd}
            startIcon={<AddOutlinedIcon />}
          >
            Add key
          </Button>

          {/* <ProjectSubMenu /> */}
        </S.ButtonsWrapper>
      )}
    </S.MuiToolbar>
  )
}
