import { identitySelectors } from "data/entities/identity/selectors"
import { setSubscription } from "data/entities/organization/actions"
import { organizationSelectors } from "data/entities/organization/selectors"
import { apiService } from "infrastructure/services/ApiService"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "@material-ui/core"

const { Paddle } = window

const getSubscriptionPlanId = (countUsers) => {
  const dev = {
    1: 12347,
    2: 12347,
  }

  const prod = {
    1: 657420,
    2: 657421,
    3: 657422,
    4: 657423,
    5: 657424,
  }

  return process.env.NODE_ENV === "development"
    ? dev[countUsers] || 12347
    : prod[countUsers] || 657424
}

export const SubscribeButton = () => {
  const dispatch = useDispatch()

  const organization = useSelector(organizationSelectors.current)
  const identity = useSelector(identitySelectors.one)

  const openCheckout = useCallback(
    (subscriptionPlanId) => {
      Paddle.Checkout.open({
        product: subscriptionPlanId,
        passthrough: JSON.stringify({
          orgId: organization._id,
        }),
        email: identity.email,
        successCallback: async (data) => {
          dispatch(
            setSubscription({
              planId: subscriptionPlanId,
              id: data.product.id,
            })
          )
          // const result = await apiService.post(
          //   `/payment/success?orgId=${organization._id}`,
          //   {
          //     planId: plan.id,
          //   }
          // )
          // console.log("result", result)
        },
      })
    },
    [identity, organization]
  )

  const upgradePlan = useCallback(
    async (plan) => {
      const response = await apiService.put(
        `/subscriptions?orgId=${organization._id}`,
        {
          subscriptionPlanId: plan.subscriptionPlanId,
        }
      )

      dispatch(
        setSubscription({
          ...response.data.subscription,
        })
      )
    },
    [identity, organization]
  )

  if (organization.subscription) return null

  return (
    <Button
      size="small"
      color="primary"
      variant="contained"
      onClick={() =>
        openCheckout(getSubscriptionPlanId(organization.users.length))
      }
    >
      Upgrade
    </Button>
  )

  // return (
  //   <Button
  //     size="large"
  //     color="primary"
  //     variant="contained"
  //     disabled={plan.id === "free"}
  //     onClick={() => upgradePlan(plan)}
  //   >
  //     Upgrade
  //   </Button>
  // )
}
