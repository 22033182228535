import { combineReducers } from "@reduxjs/toolkit"

import { organizationSlice } from "data/entities/organization"
import { projectSlice } from "data/entities/project"
import { applicationSlice } from "data/entities/application"
import { languageSlice } from "data/entities/language"
import { translationSlice } from "data/entities/translation"
import { modalSlice } from "data/entities/modal"
import { identitySlice } from "data/entities/identity"
import { billingPlansSlice } from "data/entities/billingPlans"
import { inviteSlice } from "data/entities/invite"
import { userSlice } from "data/entities/user"
import { transactionSlice } from "data/entities/transaction"

export const rootReducer = combineReducers({
  organization: organizationSlice.reducer,
  application: applicationSlice.reducer,
  identity: identitySlice.reducer,
  project: projectSlice.reducer,
  translation: translationSlice.reducer,
  language: languageSlice.reducer,
  modal: modalSlice.reducer,
  billingPlans: billingPlansSlice.reducer,
  invite: inviteSlice.reducer,
  user: userSlice.reducer,
  transaction: transactionSlice.reducer,
})
