import React from "react"
import { useSelector } from "react-redux"
import { Router } from "react-router"
import { SnackbarProvider } from "notistack"

import { ThemeProvider } from "@material-ui/core/styles"
import { IconButton, CssBaseline } from "@material-ui/core"

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined"

import { appHistory } from "infrastructure/application/history"

import { ContainersAfterInit } from "domain/ContainersAfterInit"
import { Containers } from "domain/Containers"

import { applicationSelectors } from "data/entities/application/selectors"

import { ErrorBoundary } from "view/ErrorBoundary"
import { InitLoader } from "view/components/application/InitLoader"
import { whiteTheme } from "view/themes/white"
import { blackTheme } from "view/themes/black"
import { Routes } from "view/router/routes"
import { RootModal } from "view/modals/RootModal"

export const App = () => {
  const theme = useSelector(applicationSelectors.theme)

  const notistackRef = React.createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <ErrorBoundary>
      <CssBaseline />
      <ThemeProvider theme={theme === "white" ? whiteTheme : blackTheme}>
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)} size="small">
              <CloseOutlinedIcon style={{ fill: "#fff" }} size="small" />
            </IconButton>
          )}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Containers />
          <RootModal />

          <InitLoader>
            <Router history={appHistory}>
              <ContainersAfterInit />
              <Routes />
            </Router>
          </InitLoader>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
