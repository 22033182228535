import React, { memo, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import queryString from "query-string"

import { Typography } from "@material-ui/core"

import { appHistory } from "infrastructure/application/history"

import { identitySelectors } from "data/entities/identity/selectors"
import { inviteUse } from "data/entities/invite/thunks"

import { paths } from "view/router/paths"
import * as S from "./styled"

const Page = () => {
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const { search } = useLocation()
  const { code } = queryString.parse(search)

  const identity = useSelector(identitySelectors.current)

  const handlerInviteUse = useCallback(
    async (inviteCode) => {
      const result = await dispatch(inviteUse(inviteCode))

      if (result.type === inviteUse.rejected().type) {
        if (
          result.payload.response.status === 400 &&
          result.payload.response.data.msg === "Code is wrong"
        ) {
          setError({
            title: "This link is incorrect!",
            text: "Please try to request a new invitation to continue",
          })
        } else {
          setError({
            title: "Server error!",
            text:
              "Try to activate your invite later. We are already solving this problem.",
          })
        }
      }

      if (result.type === inviteUse.fulfilled().type) {
        appHistory.push(paths.projects())
      }
    },
    [identity]
  )

  useEffect(() => {
    if (!code) return

    handlerInviteUse(code)
  }, [])

  if (!error) return null

  return (
    <S.Wrapper>
      <Typography variant="h4" component="h3" align="center">
        {error.title}
      </Typography>
      {error.text}
    </S.Wrapper>
  )
}

export const InviteConfirmPage = memo(Page)
