import React, { useCallback } from "react"
import { Form } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"

import { translationCreate } from "data/entities/translation/thunks"
import { projectSelectors } from "data/entities/project/selectors"

import { validate } from "view/forms/translation/TranslationAddForm/validate"

const initialValues = {}

export const Container = ({ component: Component, componentProps }) => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const project = useSelector(projectSelectors.current)

  const handlerSubmit = useCallback(
    async (values) => {
      const result = await dispatch(
        translationCreate({
          ...values,
          defaultLang: project?.defaultLang,
        })
      )

      if (result.type === translationCreate.fulfilled().type) {
        enqueueSnackbar("Translation has been added!", {
          variant: "success",
        })
      }

      return {}
    },
    [project]
  )

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={handlerSubmit}
      render={(props) => (
        <Component
          {...props}
          {...componentProps}
          defaultLang={project?.defaultLang}
        />
      )}
    />
  )
}
