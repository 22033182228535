export const FormatEnum = {
  YML: "yml",
  JSON: "json",
  JSON_NESTED: "jsonNested",
  CSV: "csv",
}

export const FileFormatEnum = {
  Csv: "csv",
  Xlsx: "xlsx",
  Arb: "arb",
  Php: "php",
  Po: "po",
  Pot: "pot",
  Yaml: "yml",
  Ts: "ts",
  Ini: "ini",
  JsonSimple: "json_simple",
  JsonNested: "json_nested",
  RequiredJs: "required_js",
  Xliff_1_2: "xliff_1_2",
  Xliff_2_0: "xliff_2_0",
  Resx: "resx",
  Strings: "strings",
  Properties: "properties",
}
