import React, { memo, useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSnackbar } from "notistack"

import { Button, Grid, IconButton } from "@material-ui/core"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import AddOutlinedIcon from "@material-ui/icons/AddOutlined"

import { appHistory } from "infrastructure/application/history"

import { ModalEnum } from "data/entities/modal"
import { FilterEnum } from "data/entities/translation"
import { languageRemove } from "data/entities/language/thunks"
import { projectSelectors } from "data/entities/project/selectors"
import { languageSelectors } from "data/entities/language/selectors"
import { identitySelectors } from "data/entities/identity/selectors"

import { langNameByCode } from "view/helpers/langNameByCode"
import { useModal } from "view/modals/useModal"
import { PageHeader } from "view/uikit/common/PageHeader"
import { Link } from "view/uikit/base/Link"
import { paths } from "view/router/paths"
import { ConfirmModal } from "view/modals/application/ConfirmModal"
import { Tooltip } from "view/uikit/base/Tooltip"
import { usePermission } from "view/hooks/rbac/usePermission"

import * as S from "./styled"

const Page = () => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const [langForRemove, setLangForRemove] = useState("")

  const list = useSelector(languageSelectors.selectAll)
  const project = useSelector(projectSelectors.current)
  const role = useSelector(identitySelectors.role)

  const permission = usePermission(role)

  const { onOpen: openLanguageAdd } = useModal(ModalEnum.LanguageAdd)
  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  const handlerRemove = useCallback(
    (e, id) => {
      setLangForRemove(id)
      openConfirm(e)
    },
    [openConfirm]
  )

  const removeAccepted = useCallback(async () => {
    const result = await dispatch(languageRemove(langForRemove))

    if (result.type === languageRemove.fulfilled().type) {
      enqueueSnackbar("Language has been removed!", {
        variant: "success",
      })
    }

    return {}
  }, [dispatch, langForRemove])

  if (!permission.language.canRead) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader
        title="Languages"
        button={
          permission.language.canWrite && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={openLanguageAdd}
              startIcon={<AddOutlinedIcon />}
            >
              Add
            </Button>
          )
        }
      />
      <Grid container>
        <Grid item xs={8}>
          {list.map((l) => {
            const untranslated = project?.stat.untranslated[l.id] || 0

            const percent =
              100 - (untranslated / (project?.stat.total / 100)).toFixed(0) || 0

            return (
              <S.Lang key={l.id}>
                <S.TopRow>
                  <S.Name>
                    <S.Code>{l.id}</S.Code>
                    <Link to={paths.projectItem(project?.slug, { lang: l.id })}>
                      {langNameByCode(l.id)}
                    </Link>
                  </S.Name>

                  {project?.defaultLang !== l.id &&
                    permission.language.canWrite && (
                      <Tooltip title="Delete language">
                        <IconButton
                          size="small"
                          onClick={(e) => handlerRemove(e, l.id)}
                        >
                          <DeleteOutlineOutlinedIcon size="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                </S.TopRow>

                <S.LinearProgress variant="determinate" value={+percent} />

                <S.BottomRow>
                  <S.Links>
                    <Link
                      to={paths.projectItem(project?.slug, {
                        lang: l.id,
                        status: FilterEnum.Untranslated,
                      })}
                    >
                      Untranslated keys: {untranslated}
                    </Link>

                    {/* <Link */}
                    {/*  to={paths.projectItem(project?.slug, { */}
                    {/*    lang: l.id, */}
                    {/*    filter: FilterEnum.Untranslated, */}
                    {/*  })} */}
                    {/* > */}
                    {/*  Unreviewed keys: {untranslated} */}
                    {/* </Link> */}
                  </S.Links>
                  <S.Percent>{percent}%</S.Percent>
                </S.BottomRow>
              </S.Lang>
            )
          })}
        </Grid>
      </Grid>

      <ConfirmModal
        title="Delete language"
        text={
          <>
            Are you sure you want to delete{" "}
            <b>{langNameByCode(langForRemove)}</b> with all translations?
          </>
        }
        onAccept={removeAccepted}
        labelAccept="Delete language with translations"
      />
    </>
  )
}

export const LanguagesPage = memo(Page)
