import styled from "styled-components"
import { palette } from "view/themes/white/palette"

export const Users = styled.div`
  width: 100%;
`

export const InviteRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 0.25rem;

  & > div {
    padding: 1rem;
  }

  &:hover {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }
`

export const Subtitle = styled.h3`
  font-weight: 500;
`

export const AvatarCol = styled.div`
  padding-right: 0 !important;
`

export const NameCol = styled.div`
  flex-grow: 1;
`

export const RoleCol = styled.div`
  width: 8rem;
`

export const RemoveCol = styled.div`
  width: 8rem;
  text-align: right;
`

export const Name = styled.div`
  font-size: 1rem;

  & > span {
    font-size: 0.8rem;
    color: ${palette.gray};
  }
`

export const Email = styled.div`
  color: ${palette.gray};
`
