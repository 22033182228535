import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"
import { entityName } from "data/entities/consts"

import { inviteCreate, inviteFetchList, inviteRemove } from "./thunks"
import { adapter } from "./selectors"
import { removeAllInvites } from "./actions"

const initialState = adapter.getInitialState()

export const inviteSlice = createSlice({
  name: entityName.invite,
  initialState,
  reducers: {},
  extraReducers: {
    [removeAllInvites]: (state) => {
      adapter.removeAll(state)
    },
    [inviteCreate.fulfilled]: (state, action) => {
      adapter.addOne(state, action)
    },
    [inviteFetchList.fulfilled]: (state, action) => {
      adapter.setAll(state, action)
    },
    [inviteRemove.fulfilled]: (state, action) => {
      adapter.removeOne(state, action.payload.id)
    },
    [logout.fulfilled]: () => initialState,
  },
})
