import React, { memo } from "react"

import { Grid } from "@material-ui/core"

import { PageHeader } from "view/uikit/common/PageHeader"
import { Box } from "view/uikit/base/Box"
import { OrgSettingForm } from "view/forms/organization/OrgSettingForm"
import { useSelector } from "react-redux"
import { identitySelectors } from "data/entities/identity/selectors"
import { usePermission } from "view/hooks/rbac/usePermission"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  if (!permission.organizationSettings.canRead) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader title="Organization settings" />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box title="General">
            <OrgSettingForm />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export const OrgSettingsPage = memo(Page)
