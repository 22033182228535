import styled from "styled-components"
import { whiteTheme } from "view/themes/white"

export const Wrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 10px 250px rgba(0, 0, 0, 0.1);
  border: ${({ color }) =>
    color ? `1px solid ${whiteTheme.palette[color].main}` : "1px sold #fff"};
`

export const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  margin-bottom: 1rem;
  color: ${({ color }) => (color ? whiteTheme.palette[color].main : "inherit")};
`
