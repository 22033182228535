export const ExportFormatEnum = {
  JSON: "json",
  JSON_NESTED: "jsonNested",
  CSV: "csv",
  ARB: "arb",
}

export const ExportStatusEnum = {
  All: "all",
  Translated: "translated",
  Untranslated: "untranslated",
}
