import { useEffect } from "react"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

const en = require("locales/en.json")
const ru = require("locales/ru.json")

export const LocalizationContainer = () => {
  useEffect(() => {
    i18next.use(initReactI18next).init({
      lng: "en",
      debug: false,
      resources: {
        en: {
          translation: en,
        },
        ru: {
          translation: ru,
          spareLocation: en,
        },
      },
      keySeparator: false,
      fallbackNS: "spareLocation",
      returnEmptyString: false,
      returnNull: false,
      react: {
        transSupportBasicHtmlNodes: true,
      },
    })
  }, [])

  return null
}
