import styled from "styled-components"

export const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  h5 {
    font-size: 2.3rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 3rem;
  }
`

export const PlanWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`

export const WrapperPlan = styled.div`
  width: 20%;

  &:first-child {
    & > div {
      margin-left: 0;
    }
  }

  &:last-child {
    & > div {
      margin-right: 0;
    }
  }
`

export const Plan = styled.div`
  background: #fff;
  padding: 2rem 1rem;
  margin: 0 0.5rem;
  text-align: center;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: scale(1.05);
    z-index: 100;
  }
`
export const Price = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  color: #aaa;
  margin-bottom: 2rem;

  span {
    color: #000000de;
    font-size: 1.3rem;
    margin-right: 0.25rem;
  }
`

export const Annual = styled.div`
  position: absolute;
  bottom: -1.5rem;
`

export const Name = styled.div`
  font-size: 2rem;
  margin-bottom: 0.5rem;
`

export const Benefits = styled.div`
  margin-bottom: 2rem;
`

export const Benefit = styled.div`
  margin-bottom: 1rem;
`

export const BenefitLabel = styled.span`
  margin-right: 1rem;
`

export const BenefitValue = styled.span`
  font-weight: bold;
  color: #000000de;
`

export const ButtonWrapper = styled.div``
