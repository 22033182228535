import React from "react"
import { TablePagination } from "@material-ui/core"

import { useDispatch, useSelector } from "react-redux"
import {
  translationSetLimit,
  translationSetPage,
} from "data/entities/translation"
import { translationSelectors } from "data/entities/translation/selectors"
import * as S from "view/pages/project/ProjectItemPage/GridFooter/styled"

export const GridFooter = () => {
  const pagging = useSelector(translationSelectors.pagging)

  const dispatch = useDispatch()

  const handleChangePage = (e, newPage) => {
    dispatch(translationSetPage(newPage + 1))
  }

  const handleChangeRowsPerPage = (e) => {
    dispatch(translationSetLimit(parseInt(e.target.value, 10)))
  }

  return (
    <S.Wrapper>
      <TablePagination
        component="div"
        count={pagging.total}
        page={pagging.page - 1}
        onChangePage={handleChangePage}
        rowsPerPage={pagging.limit}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </S.Wrapper>
  )
}
