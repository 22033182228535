import React from "react"
import { Field } from "react-final-form"

import { FormControl } from "@material-ui/core"

import { InputField } from "view/uikit/formFields/InputField"
import { FormError } from "view/uikit/common/FormError"
import { FormButtons } from "view/uikit/common/FormButtons"

import { Container } from "view/forms/user/ChangePasswordForm/container"

import * as S from "view/forms/user/ChangePasswordForm/styled"

const Component = ({ handleSubmit, submitError, submitting }) => {
  return (
    <S.Wrapper>
      <S.Typography variant="h6">Change password</S.Typography>

      <S.Form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <Field
            component={InputField}
            name="current"
            label="Current password"
            type="password"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Field
            component={InputField}
            name="newPassword"
            label="New password"
            type="password"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Field
            component={InputField}
            name="repeatPassword"
            label="Repeat password"
            type="password"
          />
        </FormControl>

        {submitError && (
          <FormControl fullWidth margin="normal">
            <FormError>{submitError}</FormError>
          </FormControl>
        )}

        <FormButtons
          submitBtn={{
            disabled: submitting,
            title: "Update",
          }}
        />
      </S.Form>
    </S.Wrapper>
  )
}

export const ChangePasswordForm = () => {
  return <Container component={Component} />
}
