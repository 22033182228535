export const gaService = {
  sendEvent: (...args) => {
    try {
      if (!window.dataLayer) return

      if (process.env.NODE_ENV !== "production") {
        console.log("Google Analytics: ", args)
      } else {
        window.gtag(args)
      }
    } catch (e) {
      console.error(e)
    }
  },
}
