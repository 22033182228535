import { createAsyncThunk } from "@reduxjs/toolkit"

import { appHistory } from "infrastructure/application/history"
import { apiService } from "infrastructure/services/ApiService"

import { organizationSelectors } from "data/entities/organization/selectors"
import { entityName } from "data/entities/consts"

import { paths } from "view/router/paths"

export const projectFetchList = createAsyncThunk(
  `${entityName.project}/fetchList`,
  async (props, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.get(`/projects?orgId=${organization._id}`)

      return result.data.map(({ _id, ...rest }) => ({
        id: _id,
        stat: {
          total: 0,
          untranslated: {},
        },
        ...rest,
      }))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const projectFetchOne = createAsyncThunk(
  `${entityName.project}/fetchOne`,
  async (slug, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.get(
        `/projects/slug/${slug}?orgId=${organization._id}`
      )

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      if (e.response?.status === 404) {
        appHistory.push(paths.projects())
      }
      return rejectWithValue(e)
    }
  }
)

export const projectCreate = createAsyncThunk(
  `${entityName.project}/create`,
  async ({ id, name, defaultLang }, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.post(
        `/projects?orgId=${organization._id}`,
        {
          name,
          slug: id,
          defaultLang: defaultLang.value,
          langs: [defaultLang.value],
        }
      )

      appHistory.push(paths.projectItem(id))

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const projectUpdate = createAsyncThunk(
  `${entityName.project}/update`,
  async ({ id, name, slug, defaultLang, orgId }, { rejectWithValue }) => {
    try {
      const result = await apiService.put(`/projects/${id}?orgId=${orgId}`, {
        name,
        slug,
        projectId: id,
        defaultLang: defaultLang.value,
      })

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const projectRemove = createAsyncThunk(
  `${entityName.project}/remove`,
  async (projectId, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.delete(
        `/projects/${projectId}?orgId=${organization._id}`
      )

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
