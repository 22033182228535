import { createSlice } from "@reduxjs/toolkit"

import { logout } from "data/entities/identity/thunks"
import {
  keyUpdate,
  translationCreate,
  translationFetchList,
  translationRemove,
  translationUpdate,
} from "data/entities/translation/thunks"
import { LoadingEnum } from "data/enums/LoadingEnum"
import { adapter } from "data/entities/translation/selectors"
import { entityName } from "data/entities/consts"
import { switchOrganization } from "data/entities/organization/actions"
import { projectCurrentChanged } from "data/entities/project/actions"

export const FilterEnum = {
  All: "All",
  Untranslated: "untranslated",
  Unverified: "unverified",
}

const initialState = adapter.getInitialState({
  loading: LoadingEnum.IDLE,
  filter: {
    search: "",
    status: FilterEnum.All,
  },
  pagging: {
    total: 0,
    page: 1,
    limit: 10,
  },
})

export const translationSlice = createSlice({
  name: entityName.translation,
  initialState,
  reducers: {
    translationSetLimit: (state, action) => {
      state.pagging = {
        ...state.pagging,
        limit: action.payload,
        page: 1,
      }
    },
    translationSetPage: (state, action) => {
      state.pagging = {
        ...state.pagging,
        page: action.payload,
      }
    },
    translationSetSearch: (state, action) => {
      state.filter.search = action.payload
      state.pagging.page = 1
    },
    translationSetStatus: (state, action) => {
      state.filter.status = action.payload
    },
  },
  extraReducers: {
    [translationFetchList.pending]: (state) => {
      state.loading = LoadingEnum.PENDING
    },
    [translationFetchList.fulfilled]: (state, action) => {
      const { items, ...pagging } = action.payload

      state.loading = LoadingEnum.FINISH
      state.pagging = pagging
      adapter.setAll(state, items)
    },
    [translationCreate.fulfilled]: (state, action) => {
      adapter.addOne(state, action)
    },
    [translationRemove.fulfilled]: (state, action) => {
      adapter.removeOne(state, action.payload.id)
    },
    [keyUpdate.fulfilled]: (state, action) => {
      const { id, ...rest } = action.payload
      adapter.updateOne(state, { id, changes: rest })
    },
    [translationUpdate.fulfilled]: (state, action) => {
      const { id, ...rest } = action.payload
      adapter.updateOne(state, { id, changes: rest })
    },
    [projectCurrentChanged]: (state) => {
      state.filter = initialState.filter
      state.pagging = initialState.pagging
      adapter.removeAll(state)
    },
    [switchOrganization]: () => initialState,
    [logout.fulfilled]: () => initialState,
  },
})

export const {
  translationSetSearch,
  translationSetLimit,
  translationSetPage,
  translationSetStatus,
} = translationSlice.actions
