import React from "react"
import { useDispatch } from "react-redux"
import { Field } from "react-final-form"

import { FormControl } from "@material-ui/core"

import { logout } from "data/entities/identity/thunks"

import { InputField } from "view/uikit/formFields/InputField"
import { FormError } from "view/uikit/common/FormError"
import { Box } from "view/uikit/base/Box"
import { FormButtons } from "view/uikit/common/FormButtons"

import { Container } from "./container"

const Component = ({ handleSubmit, submitError, submitting }) => {
  const dispatch = useDispatch()

  return (
    <Box title="Complete sign up">
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
          <Field component={InputField} name="name" label="Your name" />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Field component={InputField} name="email" label="Your email" />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Field
            component={InputField}
            name="organization"
            label="Organization name"
          />
        </FormControl>

        {submitError && (
          <FormControl fullWidth margin="normal">
            <FormError>{submitError}</FormError>
          </FormControl>
        )}

        <FormButtons
          cancelBtn={{
            disabled: submitting,
            title: "Logout",
          }}
          cbCancel={() => dispatch(logout())}
          submitBtn={{
            disabled: submitting,
            title: "Finish sign up",
          }}
        />
      </form>
    </Box>
  )
}

export const SignupCompleteForm = () => {
  return <Container component={Component} />
}
