import React, { memo } from "react"

import { UploadForm } from "view/forms/project/UploadForm"
import { PageHeader } from "view/uikit/common/PageHeader"
import { useSelector } from "react-redux"
import { identitySelectors } from "data/entities/identity/selectors"
import { usePermission } from "view/hooks/rbac/usePermission"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"

const Page = () => {
  const role = useSelector(identitySelectors.role)
  const permission = usePermission(role)

  if (!permission.upload.canWrite) {
    appHistory.push(paths.home())
    return null
  }

  return (
    <>
      <PageHeader title="Upload" />

      <UploadForm />
    </>
  )
}

export const UploadPage = memo(Page)
