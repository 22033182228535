import { entityName } from "data/entities/consts"
import { projectSelectors } from "data/entities/project/selectors"

export const languageSelectors = {
  selectAll: (state) => {
    const project = projectSelectors.current(state)

    if (!project) return []

    return project.langs.map((l) => ({
      id: l,
    }))
  },
  init: (state) => state[entityName.language].init,
  filter: (state) => state[entityName.language].filter,
}
