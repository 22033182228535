import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setApplicationLoaded } from "data/entities/application"
import { languageInit } from "data/entities/language/thunks"
import { languageSelectors } from "data/entities/language/selectors"
import { identitySelectors } from "data/entities/identity/selectors"
import { organizationSelectors } from "data/entities/organization/selectors"

export const InitContainer = () => {
  const dispatch = useDispatch()

  const identityInit = useSelector(identitySelectors.init)
  const identity = useSelector(identitySelectors.one)
  const langInit = useSelector(languageSelectors.init)
  const orgInit = useSelector(organizationSelectors.init)

  useEffect(() => {
    dispatch(languageInit())
  }, [dispatch])

  useEffect(() => {
    if (!identityInit) return

    if (identity.id) {
      if (!langInit) return
      // if (!orgInit) return

      dispatch(setApplicationLoaded())
    } else {
      dispatch(setApplicationLoaded())
    }
  }, [identityInit, dispatch, langInit, orgInit])

  return null
}
