import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { storageService } from "infrastructure/services/StorageService"
import { appHistory } from "infrastructure/application/history"
import { fbApp } from "infrastructure/firebase"
import { gaService } from "infrastructure/services/GAService"

import { setApplicationLoaded, setClientState } from "data/entities/application"

import { paths } from "view/router/paths"
import { userCreateByUid, userGetByUid } from "data/entities/user/thunks"

export const AuthContainer = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    fbApp.auth().onAuthStateChanged(async (fbUser) => {
      try {
        if (!fbUser) {
          dispatch(setApplicationLoaded())
          return
        }

        // setToken
        const token = await fbApp.auth().currentUser.getIdToken(true)
        await storageService.setItem("auth", token)

        const result = await dispatch(userGetByUid(fbUser.uid))

        let user

        if (result.type === userGetByUid.fulfilled().type) {
          user = result.payload
        } else {
          if (result.payload.response.status === 500) {
            console.log("jopa")
          }

          if (result.payload.response.status === 404) {
            user = await dispatch(
              userCreateByUid({
                fbUid: fbUser.uid,
                name: fbUser.displayName,
                email: fbUser.email,
              })
            )
          }
        }

        if (!user) {
          console.log("??")
          dispatch(setApplicationLoaded())
          enqueueSnackbar("Server is not available! Try later.", {
            variant: "error",
          })
          return
        }

        if (!user.name || !user.email || !user.organizations?.length) {
          gaService.sendEvent("event", "conversion", {
            send_to: "AW-389992691/iWblCK_395ECEPOh-7kB",
          })
          window.ym(75137779, "reachGoal", "signup_1")

          appHistory.push(paths.signupComplete())
        }

        if (user?.clientState) dispatch(setClientState(user.clientState))
      } catch (e) {
        // ????
        console.log("e", e)
        dispatch(setApplicationLoaded())
        enqueueSnackbar("Server is not available! Try later.", {
          variant: "error",
        })
      }
    })
  }, [])

  return null
}
