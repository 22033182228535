import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Button } from "@material-ui/core"

import { organizationSelectors } from "data/entities/organization/selectors"
import { fetchOrganization } from "data/entities/organization/thunks"
import { unsetSubscription } from "data/entities/organization/actions"
import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { ConfirmModal } from "view/modals/application/ConfirmModal"

import * as S from "./styled"

const { Paddle } = window

export const CurrentSubscription = React.memo(() => {
  const dispatch = useDispatch()

  const organization = useSelector(organizationSelectors.current)
  // const currentPlan = useSelector(billingPlanSelectors.current)

  const { onOpen: openConfirm } = useModal(ModalEnum.Confirm)

  // useEffect(() => {
  //   dispatch(billingPlanFetchList())
  // }, [])

  useEffect(() => {
    dispatch(fetchOrganization(organization._id))
  }, [organization])

  const cancel = (url) => {
    Paddle.Checkout.open({
      override: url,
      successCallback: async () => {
        dispatch(unsetSubscription())
      },
    })
  }

  const changePaymentMethod = useCallback(
    (url) => {
      Paddle.Checkout.open({
        override: url,
      })
    },
    [organization]
  )

  // if (!currentPlan) return null
  if (!organization) return null

  const { used, subscription } = organization

  return (
    <>
      <S.Wrapper>
        <S.Card>
          <S.Title>Plan</S.Title>
          <S.Value>{subscription ? "Team" : "Free"}</S.Value>
        </S.Card>

        <S.Card>
          <S.Title>Users</S.Title>
          <S.Value>{organization.users.length || 1}</S.Value>
        </S.Card>

        {/* <S.Card> */}
        {/*  <S.Title>Project</S.Title> */}
        {/*  <S.Value> */}
        {/*    {used?.projects || 0}/{currentPlan.limits.projects} */}
        {/*  </S.Value> */}
        {/* </S.Card> */}

        {/* <S.Card> */}
        {/*  <S.Title>Keys</S.Title> */}
        {/*  <S.Value> */}
        {/*    {used?.keys || 0}/{currentPlan.limits.keys} */}
        {/*  </S.Value> */}
        {/* </S.Card> */}
      </S.Wrapper>

      {subscription?.id && (
        <>
          <S.NextBill>
            The next {subscription.nextPaymentAmount} {subscription.currency}{" "}
            will be charged on {subscription.nextBillDate}
          </S.NextBill>

          <S.Buttons>
            <Button
              variant="outlined"
              size="small"
              onClick={() => changePaymentMethod(subscription.updateUrl)}
            >
              Change payment method
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={openConfirm}
            >
              Cancel subscription
            </Button>
          </S.Buttons>

          <ConfirmModal
            title="We're sorry to see you go"
            text={<>Are you sure you want to cancel your subscription?</>}
            onAccept={() => cancel(subscription.cancelUrl)}
            labelAccept="Cancel subscription"
            labelCancel="Keep subscription"
          />
        </>
      )}
    </>
  )
})
