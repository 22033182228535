import React, { useEffect, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import { useSnackbar } from "notistack"

import { Button } from "@material-ui/core"
import { ExtensionEnum } from "view/pages/project/ProjectItemPage/FileSelector/config"

import * as S from "./styled"

export const DragDropUploadField = ({
  input: { onChange, ...input },
  meta,
  children,
  name,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const errorMessage = meta.error || meta.submitError
  const validateError = errorMessage && meta.touched ? errorMessage : undefined

  const acceptExts = useMemo(() => {
    const qwe = Object.values(ExtensionEnum)
      .map((ext) => `.${ext}`)
      .join(", ")
    return qwe
  }, [ExtensionEnum])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: acceptExts,
    maxFiles: 1,
    onDropRejected: (rejected) => {
      rejected.forEach((f) => {
        enqueueSnackbar(f.errors[0].message, {
          variant: "error",
        })
      })
    },
  })

  useEffect(() => {
    onChange(acceptedFiles[0])
  }, [acceptedFiles])

  const files = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    )
  })

  return (
    <S.Wrapper className="container">
      {!files.length ? (
        <S.Drop {...getRootProps({ className: "dropzone" })}>
          <input name={name} {...getInputProps()} />
          <S.Text>Drop file to upload</S.Text>
          <p>or select a file from your computer</p>
          {/* <p>supported formats JSON, YAML, CSV</p> */}
          <Button color="primary" variant="outlined">
            Browse
          </Button>
        </S.Drop>
      ) : (
        <aside>
          <ul>{files}</ul>
        </aside>
      )}
    </S.Wrapper>
  )
}
