import styled from "styled-components"
import MuiPopover from "@material-ui/core/Popover"
import MuiListItem from "@material-ui/core/ListItem"
import { whiteTheme } from "view/themes/white"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  width: 25rem;
  color: ${({ disabled }) => (disabled ? "#999" : "inherit")};
  background: ${({ disabled }) => (disabled ? "#f3f3f3" : "inherit")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-right: 1px solid #e9ebf0;
`

export const Title = styled.div`
  font-size: 1.2rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Popover = styled(MuiPopover)`
  z-index: ${whiteTheme.zIndex.drawer - 3} !important;
  //
  // & > div {
  //   width: 25rem;
  // }
`

export const ButtonWrapper = styled.div`
  width: 25rem;
  text-align: right;
  padding: 0.5rem 1rem;
`

export const ListItem = styled(MuiListItem)`
  cursor: pointer;
`
