import isEmail from "validator/es/lib/isEmail"

export const validate = ({ email, name, role }) => {
  const errors = {}

  if (!name) {
    errors.name = "Name required!"
  }

  if (!email) {
    errors.email = "Email required!"
  } else if (!isEmail(email)) {
    errors.email = "This email is invalid!"
  }

  if (!role) {
    errors.role = "Role required!"
  }

  return errors
}
