import React from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

import { ModalEnum } from "data/entities/modal"

import { useModal } from "view/modals/useModal"
import { Transition } from "view/modals/Transition"
import { InviteSendForm } from "view/forms/user/InviteSendForm"

export const InviteSendModal = () => {
  const { open, onClose } = useModal(ModalEnum.InviteSend)

  if (!open) return null

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>Send invite</DialogTitle>
      <DialogContent>
        <InviteSendForm cbCancel={onClose} />
      </DialogContent>
    </Dialog>
  )
}
