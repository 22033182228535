import { createAsyncThunk } from "@reduxjs/toolkit"

import { apiService } from "infrastructure/services/ApiService"

import { organizationSelectors } from "data/entities/organization/selectors"
import { entityName } from "data/entities/consts"
import { fbApp } from "infrastructure/firebase"
import { userGetByUid } from "data/entities/user/thunks"
import { appHistory } from "infrastructure/application/history"
import { paths } from "view/router/paths"

export const inviteFetchList = createAsyncThunk(
  `${entityName.invite}/fetchList`,
  async (props, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.get(`/invites?orgId=${organization._id}`)

      return result.data.map(({ _id, ...rest }) => ({
        id: _id,
        ...rest,
      }))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const inviteCreate = createAsyncThunk(
  `${entityName.invite}/create`,
  async (values, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.post(`/invites`, {
        orgId: organization._id,
        ...values,
      })

      const { _id, ...rest } = result.data
      return {
        id: _id,
        ...rest,
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const inviteResend = createAsyncThunk(
  `${entityName.invite}/remove`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.post(`/invite-resend/${id}`, {
        orgId: organization._id,
      })

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const inviteRemove = createAsyncThunk(
  `${entityName.invite}/remove`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState()

      const organization = organizationSelectors.current(state)

      if (!organization?._id)
        return rejectWithValue(new Error("OrgID not found"))

      const result = await apiService.delete(
        `/invites/${id}?orgId=${organization._id}`
      )

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const inviteUse = createAsyncThunk(
  `${entityName.invite}/use`,
  async (code, { dispatch, rejectWithValue }) => {
    try {
      const result = await apiService.post(`/use-invite`, {
        code,
      })

      if (result.data.token) {
        const res = await fbApp.auth().signInWithCustomToken(result.data.token)
        dispatch(userGetByUid(res.user.uid))
        appHistory.push(paths.projects())
      }

      return result.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
