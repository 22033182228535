import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"

import "view/themes/index.css"
import { store } from "data/store"
import { App } from "view/App"
import { ErrorBoundary } from "view/ErrorBoundary"
import * as serviceWorker from "serviceWorker"

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
